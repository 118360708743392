import * as React from 'react';
import { observer } from 'mobx-react';
import * as styles from './styles.scss';
import { useOnKeyPress } from '@lms/views/utils/hooks/on.key.press.hook';
import { useOnClickOutside } from '@lms/views/utils/hooks/on.click.outside.hook';

type ISize = 'small' | 'medium' | 'large';
type IPosition = 'right'; // TODO: add more if needed

interface IProps {
	children: React.ReactElement;
	onClose: () => void;
	headerText?: string;
	subheaderText?: string;
	size?: ISize;
	position?: IPosition;
	canBeClosed?: boolean;
	scrollable?: boolean;
	fullHeight?: boolean;
	fullWidth?: boolean;
}

const Modal: React.FC<IProps> = observer((props: IProps) => {
	const selectors = styles as any;
	const modalRef = React.useRef();
	const canBeClosed = props.canBeClosed === true
			|| typeof props.canBeClosed === 'undefined';

	const handleClose = () => {
		if (canBeClosed) {
			props.onClose();
		}
	};

	useOnClickOutside(modalRef, handleClose);
	useOnKeyPress('Escape', handleClose);

	return (
		<div className={selectors.modalContainer} data-test="modal">
			<div className={selectors.modalBackground}>
				<div
					className={`${selectors.modal} ${props.scrollable ? selectors.modalScrollable : ''}  ${props.fullHeight ? selectors.fullHeight : ''} ${props.fullWidth ? selectors.fullWidth : ''}`}
					ref={modalRef}
					data-size={props.size}
					data-test="modal-body"
					data-position={props.position}
				>
					{
						props.headerText && (
							<div className={selectors.modalHeader}>
								<h2>
									{props.headerText}
									{
										props.subheaderText && <span>{props.subheaderText}</span>
									}
								</h2>
								{
									canBeClosed && (
										<a className={selectors.modalClose} onClick={handleClose}>
											<i className={'fal fa-times'}></i>
										</a>
									)
								}
							</div>
						)
					}
					<div className={selectors.modalBody}>
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Modal;
