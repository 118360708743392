import * as apiClient from '@lms/services/clients/lms.api.client';
import { IUserRecord } from '@lms/typings/records/user.record';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { IUserAuditEntryRecord } from '@lms/typings/records/user-audit-entry.record';
import * as queryBuilder from 'objection-find-query-builder';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

/**
 * Asynchronously requests a specific user by their id from the API
 *
 * @function
 * @param {string} id
 * @param {*} query
 * @returns {Promise<IUserRecord>}
 */
export function getUserById(id: number, query?: any): Promise<IUserRecord> {
	return apiClient.get(`/users/${id}`, query);
}

export function getUserAudit(id: number, query?: any): Promise<{ results: IUserAuditEntryRecord[]; total: number }> {
	return apiClient.get(`/users/${id}/audit`, query);
}

/**
 * Asynchronously requests a list of all users from the API
 *
 * @async
 * @function
 * @param {object} queryParams
 * @returns {Promise<IUserRecord[]>}
 */
export function getUsers(queryParams?: object): Promise<{ results: IUserRecord[] }> {
	return apiClient.get('/users', queryParams);
}

export function searchUsers(query: string, limit?: number): Promise<{ results: IUserRecord[] }> {
	const builder = queryBuilder
		.builder()
		.rangeStart(0)
		.rangeEnd(limit ? limit - 1 : 19)
		.anyLikeLower([
			'fullName',
			'loginEmail'
		], `%${query}%`);

	return getSubordinateUsers(getStoreProxy().state.user.metadata.id, builder.build());
}

export function searchEnterpriseAssigneesUsers(query: string, limit?: number): Promise<{ results: IUserRecord[] }> {
	const builder = queryBuilder
		.builder()
		.rangeStart(0)
		.rangeEnd(limit ? limit - 1 : 19)
		.equal('isEnterpriseExecutive', true)
		.anyLikeLower([
			'fullName',
			'loginEmail'
		], `%${query}%`);

	return getSubordinateUsers(getStoreProxy().state.user.metadata.id, builder.build());
}

/**
 * Asynchronously requests a list of subordinate users for the specified
 * manager from the API
 *
 * @param {number} userId
 * @param {object} queryParams
 * @returns {Promise<{results: IUserRecord[]}>}
 */
export function getSubordinateUsers(
	userId: number,
	queryParams?: object
): Promise<{ total: number; results: IUserRecord[] }> {
	return apiClient.get(`/users/${userId}/subordinates`, queryParams);
}

/**
 * Asynchronously request leads. This is used for getting creators of leads.
 *
 * @export
 * @param {number} userId
 * @param {object} queryParams
 * @returns {Promise<{ results: ILeadRecord[] }>}
 */
export function getCreators(
	userId: number,
	queryParams: object
): Promise<{ results: ILeadRecord[] }> {
	return apiClient.get(`/users/${userId}/subordinates/leads`, queryParams);
}

/**
 * Asynchronously requests to post a user to the API
 *
 * @function
 * @param {IUserRecord} user
 * @returns {Promise<{users: IUserRecord[]}>}
 */
export function postUser(user: IUserRecord): Promise<IUserRecord> {
	return apiClient.postJson('/users', user);
}

/**
 * Asynchronously patches the specified user in the LMS API with
 * the specified updates
 *
 * @function
 * @param {number} userId
 * @param {Partial<IUserRecord>} patch
 * @param {object} queryParams
 * @returns {Promise<any>}
 */
export function patchUser(userId: number, patch: Partial<IUserRecord>, queryParams?: any): Promise<IUserRecord> {
	if (queryParams && queryParams.eager) {
		delete queryParams.eager;
	}
	return apiClient.patchJson(`/users/${userId}`, patch, queryParams);
}

/**
 * Asynchronously requests a password token validity check
 * operation from the API
 *
 * @function
 * @param {string} resetToken
 * @returns {Promise<any>}
 */
export function postPasswordResetTokenForValidation(resetToken: string): Promise<{ isValid: boolean }> {
	return apiClient.postJson('/users/password/validation', { resetToken }, true);
}

/**
 * Asynchronously requests a token-based password
 * change operation from the API
 *
 * @function
 * @param {string} newPassword
 * @param {string} resetToken
 * @returns {Promise<any>}
 */
export function postPasswordChange(newPassword: string, resetToken: string): Promise<any> {
	return apiClient.postJson('/users/password/change', { newPassword, resetToken }, true);
}

/**
 * Asynchronously requests a user-based password change
 * operation from the API
 *
 * @function
 * @param {number} userId
 * @param {string} currentPassword
 * @param {string} newPassword
 * @returns {Promise<any>}
 */
export function postUserPasswordChange(userId: number, currentPassword: string, newPassword: string): Promise<any> {
	return apiClient.postJson(`/users/${userId}/password/change`, { currentPassword, newPassword });
}

/**
 * Asynchronously forcefully requests a user-based password change
 * operation from the API. Used by Global Managers
 *
 * @function
 * @export
 * @param {number} userId
 * @param {string} newPassword
 * @returns {Promise<any>}
 */
export function postOverrideUserPassword(
	userId: number,
	newPassword: string
): Promise<any> {
	return apiClient.postJson(`/users/${userId}/password/override`, { newPassword });
}

/**
 * Asynchronously requests a password reset init
 * operation from the API
 *
 * @function
 * @param {string} loginEmail
 * @returns {Promise<any>}
 */
export function postPasswordResetInit(loginEmail: string): Promise<any> {
	return apiClient.postJson('/users/password/init-reset', { loginEmail }, true);
}

/**
 * @function
 * @param {number} userId
 * @returns {Promise<any>}
 */
export function deleteUser(userId: number): Promise<any> {
	return apiClient.deleteResource(`/users/${userId}`);
}
