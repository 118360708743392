declare let module: any;
declare let window: any;

import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';

import {
	Store as IStore,
	createStore,
	applyMiddleware,
	compose
} from 'redux';

import { config as metadataConfig } from '@lms/config/metadata.config';
import authMiddleware from '@lms/redux/middleware/auth.middleware';
import metadataMiddleware from '@lms/redux/middleware/metadata.middleware';
import five9Middleware from '@lms/redux/middleware/five9.middleware';
import systemNotificationMiddleware from '@lms/redux/middleware/system.notification.middleware';
import rootReducer from '@lms/redux/reducers/root.reducer';

import { synchronizeStoreProxy } from '@lms/redux/redux.store.proxy';

const shouldEnableDevtools = [
	'workstation',
	'production',
	'development',
	'staging'
].includes(metadataConfig.environment);

/**
 * Returns either the Redux devtool enhancer composer,
 * or the regular Redux enhancer composer
 *
 * @function
 * @returns {function}
 */
const getEnhancerComposingFunction = () => {
	if (shouldEnableDevtools && window.__REDUX_DEVTOOLS_EXTENSION__) {
		return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	} else {
		return compose;
	}
};

/**
 * Enables hot module replacement for reducers and general
 * Redux code that is used by this store
 *
 * @function
 * @param {Store<any>} store - Redux store of the application
 */
const enableHotModuleReplacement = (store: IStore<any>) => {
	if (module.hot) {
		module.hot.accept('../redux/reducers/root.reducer', () => {
			const nextReducer = require('../redux/reducers/root.reducer').default;
			store.replaceReducer(nextReducer);
		});
	}
};

/**
 * Configures and creates the Redux store for the application
 *
 * @function
 * @param {*} initialState - Initial state of the application
 * @param {History} history - Browser history for connected-react-router
 * @returns {Store<any>}
 */
export const configureStore = <State>(initialState: any, history: History): IStore<State> => {
	const middleware = [
		routerMiddleware(history),
		thunkMiddleware,

		authMiddleware,
		metadataMiddleware,
		five9Middleware,
		systemNotificationMiddleware
	];

	const composeEnhancers = getEnhancerComposingFunction();
	const store = createStore(
		rootReducer(history),
		initialState,
		composeEnhancers(applyMiddleware(...middleware as any))
	);

	synchronizeStoreProxy(store);
	enableHotModuleReplacement(store);
	return store;
};
