import * as queryBuilder from 'objection-find-query-builder';
import * as _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { buildDefaultLeadRecordQuery } from '@lms/models/lead.model/utils';
import { deleteLead, getLeadById, patchLeads } from '@lms/services/lead.service';
import { ERROR } from '@lms/services/utils/network.error.codes';
import { Event, eventBus, subscribe } from 'mobx-event-bus2';
import { findMatchForLead } from '@lms/services/lead.duplicate.service';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { ILeadMatchStatus } from '@lms/enums/lead.match-status.enum';
import { ILeadModel } from '@lms/models/lead.model';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { ILeadRecordProjection, LeadRecordProjection } from '@lms/features/lead.bookkeeping/models/lead.record.projection';
import { ILeadStatus } from '@lms/enums/lead.status.enum';
import { IUserPermission } from '@lms/utils/state/user.state';
import { IUserRecord } from '@lms/typings/records/user.record';
import { IUserRole } from '@lms/enums/user.role.enum';
import { LeadInfoNotificationService } from '../services/lead.notification.service';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { userHasRequiredPermissions } from '@lms/utils/selectors/user.selectors';

interface INewLeadPayload {
	lead: ILeadRecord;
}

interface ILeadViewedPayload {
	user: IUserRecord;
}

interface IDuplicateLeadCheckPayload {
	attrName: string;
	value: any;
}

interface ILeadClickedPayload {
	lead: ILeadModel;
}

export class LeadInfoStore {
	public notificationService: LeadInfoNotificationService;

	@observable public lead: ILeadRecordProjection;
	@observable public isFetchingLead: boolean;
	@observable public firstLeadView: boolean;
	@observable public errorOccured: boolean;

	private commonFields = [
		'brandId',
		'businessTitle',
		'address',
		'businessLegalName',
		'legalAddress',
		'businessPhoneNumber',
		'businessFederalTaxId',
		'yearsInBusiness',
		'ccMonthlyVolume',
		'ccAverageTicket',
		'ccHighTicket',
		'merchantId',
		'officeId',
		'assigneeId',
		'assigneeSecondaryId',
		'status',
		'isEnterprise',
		'enterpriseExecAssigneeId'
	];

	constructor() {
		eventBus.register(this);
		this.notificationService = new LeadInfoNotificationService();
		this.errorOccured = false;

		this.lead = null;
		this.isFetchingLead = true;

		this.firstLeadView = null;

		this.handleLeadClicked = this.handleLeadClicked.bind(this);
		this.handleNewLead = this.handleNewLead.bind(this);
		this.handleLeadAttributeUpdated = this.handleLeadAttributeUpdated.bind(this);
		this.handleLeadViewed = this.handleLeadViewed.bind(this);
	}

	public isFieldLocked(name: string) {
		const isApplicationInProgress = this.lead && this.lead.isSubmittedToSalesCenter;
		return isApplicationInProgress && this.commonFields.includes(name);
	}

	/**
	 * @function
	 * @param {ILeadRecordProjection} lead
	 */
	@action public setActiveLead = (lead: ILeadRecordProjection) => {
		this.lead = lead;
		this.isFetchingLead = false;
	};

	public handleLabelAssignment = async (labelAction: any) => {
		const { action: actionType, option } = labelAction;
		const query = queryBuilder
			.builder()
			.inSet('id', [this.lead.id.toString()])
			.build();

		const OPERATIONS = {
			'select-option': 'add_label',
			'deselect-option': 'remove_label'
		} as any;

		const patch = {
			operation: OPERATIONS[actionType],
			data: { labelId: option.value }
		};

		try {
			await patchLeads(patch, query);
			runInAction(() => {
				if (actionType === 'deselect-option') {
					_.remove(this.lead.labels, (label) => {
						return label.id === option.value;
					});
				} else {
					this.lead.labels.push(
						{
							id: option.value,
							name: option.label,
							color: option.labelColor
						}
					);
				}

			});
		} catch (error) {
			throw error;
		}
	};

	/**
	 * @function
	 * @param {number} leadId
	 * @returns {Promise<void>}
	 */
	@action public fetchLead = async (leadId: number) => {
		this.isFetchingLead = true;
		this.errorOccured = false;

		try {
			const leadRecord = await getLeadById(leadId, buildDefaultLeadRecordQuery());

			runInAction(() => {
				this.lead = new LeadRecordProjection(leadRecord);
				this.isFetchingLead = false;
			});
		} catch (error) {
			runInAction(() => {
				this.isFetchingLead = true;
			});

			if (Object.values(ERROR).includes(error.response.statusCode)) {
				runInAction(() => {
					this.errorOccured = true;
				});
				throw new Error(error);
			}
		}
	};

	@action public handleLeadNotFoundCleanup = () => {
		getStoreProxy().dispatch(navigate(`/leads`));
		this.errorOccured = false;
		this.isFetchingLead = false;
	};

	/**
	 * @function
	 */
	@action public resetFirstTimeView = () => {
		this.firstLeadView = false;
	};

	public deleteLead = () => {
		return deleteLead(this.lead.id);
	};

	/**
	 * @function
	 *
	 * @param {Event<ILeadClickedPayload>} event
	 */
	@subscribe('leadList.leadClicked')
	private handleLeadClicked(event: Event<ILeadClickedPayload>) {
		const { lead } = event.payload;

		// TODO this doesn't work, revise later
		// this.setActiveLead(new LeadRecordProjection(lead));
		getStoreProxy().dispatch(navigate(`/leads/${lead.id}`));
	}

	/**
	 * @function
	 *
	 * @private
	 * @param {Event<INewLeadPayload>} event
	 * @memberof LeadInfoStore
	 */
	@subscribe('leadEntry.newLead')
	@action private handleNewLead(event: Event<INewLeadPayload>) {
		const { lead } = event.payload;
		this.firstLeadView = true;
		getStoreProxy().dispatch(navigate(`/leads/${lead.id}`));
	}

	/**
	 * @function
	 *
	 * @private
	 * @param {Event<IDuplicateLeadCheckPayload>} event
	 * @memberof LeadInfoStore
	 */
	@subscribe('leadInfo.leadAttributeUpdated')
	private async handleLeadAttributeUpdated(event: Event<IDuplicateLeadCheckPayload>) {
		const { attrName, value } = event.payload;

		try {
			const matchType = await findMatchForLead({
				...this.lead
			} as ILeadRecord);

			const userCanOverrideDuplicateLeads = userHasRequiredPermissions(
				getStoreProxy().state,
				[IUserPermission.CREATE_DUPLICATE_LEADS]
			);

			eventBus.post('leadInfo.duplicateFound', { matchType, overriden: userCanOverrideDuplicateLeads });

			if (
				matchType !== ILeadMatchStatus.FULL_MATCH ||
				userCanOverrideDuplicateLeads
			) {
				this.lead.patchRecordAttribute(attrName as keyof ILeadRecord, value);
			}
		} catch (error) {
			/* TODO error handling*/
			throw new Error(error);
		}
	}

	/**
	 * @function
	 * @private
	 * @param {Event<ILeadViewedPayload>} event
	 * @returns
	 * @memberof LeadInfoStore
	 */
	@subscribe('leadInfo.leadViewed')
	@action private handleLeadViewed(event: Event<ILeadViewedPayload>) {
		const { user } = event.payload;

		if (this.firstLeadView) {
			this.resetFirstTimeView();
			return;
		}

		if (this.lead.brandId === user.brandId) {
			if (
				(user.role === IUserRole.CSM as any)
				|| (user.role === IUserRole.OFFICE_MANAGER as any)
				|| (user.id === this.lead.assigneeId)
			) {
				if (this.lead.status === ILeadStatus.NEW_LEAD as any) {
					this.lead.patchRecordAttribute('status', ILeadStatus.VIEWED);
				}
			}
		}
	}
}

const leadInfoStore = new LeadInfoStore();
export default leadInfoStore;
