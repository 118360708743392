import { IAuthTokenRecord } from '@lms/typings/records/auth-token.record';
import { makeActionCreator } from '@harbortouch/react-modules';

import * as apiClient from '@lms/services/clients/lms.api.client';
import * as authStorage from '@lms/utils/storage/auth.storage';
import * as tokenService from '@lms/services/token.service';

import { postAuthorizationCode, redirectToLoginPage } from '@lms/services/auth.service';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { getFive9Lead } from '@lms/utils/storage/five9.storage';

export enum IAuthenticationPhase {
	INIT_LOGIN = 'INIT_LOGIN',
	ACCEPT_CODE = 'ACCEPT_CODE',
	REQUEST_TOKEN = 'REQUEST_TOKEN',
	ACCEPT_TOKEN = 'ACCEPT_TOKEN',
	FINISH = 'FINISH',
	FAIL = 'FAIL'
}

export interface IAuthenticatePayload {
	phase: IAuthenticationPhase;
	data?: {
		code?: string;
		token?: IAuthTokenRecord;
	};
}

export enum AUTH_ACTION_NAMES {
	AUTHENTICATE = '@lms/auth/AUTHENTICATE',
	DEAUTHENTICATE = '@lms/auth/DEAUTHENTICATE'
}

export const authenticate = makeActionCreator<IAuthenticatePayload>(AUTH_ACTION_NAMES.AUTHENTICATE);
export const deauthenticate = makeActionCreator(AUTH_ACTION_NAMES.DEAUTHENTICATE);

/**
 * Drops the current auth token from the `Authorization` header
 * that's being sent by LMS API client and clears it from `localStorage`
 *
 * @function
 */
function _dropAuthToken() {
	authStorage.persistAuthToken(null);
	apiClient.setAccessToken(null);
	tokenService.setTokenExpirationTime(null);
}

/**
 * Resets the code challenge and code verifier combination used
 * for retrieving the OAuth authentication code for a single user
 *
 * @function
 */
function _dropCodeChallenge() {
	authStorage.resetCodeVerifier();
	authStorage.resetChallenge();
}

/**
 * @function
 * @param {IAuthTokenModel} token
 * @private
 */
function _setAuthToken(token: IAuthTokenRecord) {
	authStorage.persistAuthToken(token);
	apiClient.setAccessToken(token.access_token);
	tokenService.setTokenExpirationTime(token.expiration);
}

/**
 * Clears session storage
 *
 * @function
 */
function _clearSessionStorage() {
	sessionStorage.clear();
}

/**
 * @thunk
 * @function
 * @returns {ThunkAction}
 */
export function initLogin() {
	return (dispatch: any) => {
		dispatch(authenticate({ phase: IAuthenticationPhase.INIT_LOGIN }));
		dispatch(logOutUser());
	};
}

/**
 * @thunk
 * @function
 * @param {string} code - OAuth authentication code
 * @returns {ThunkAction}
 */
export function requestAuthToken(code: string) {
	return (dispatch: any) => {
		dispatch(authenticate({ phase: IAuthenticationPhase.REQUEST_TOKEN }));
		return postAuthorizationCode(code)
			.then((token) => {
				dispatch(acceptAuthToken(token));
			})
			.catch((error) => {
				dispatch(authenticate({ phase: IAuthenticationPhase.FAIL }));
				dispatch(logOutUser());

				throw new Error(error);
			});
	};
}

/**
 * @thunk
 * @function
 * @param {IAuthTokenModel} token
 * @returns {ThunkAction}
 */
export function acceptAuthToken(token: IAuthTokenRecord) {
	return (dispatch: any) => {
		dispatch(authenticate({
			phase: IAuthenticationPhase.ACCEPT_TOKEN,
			data: { token }
		}));

		_setAuthToken(token);
		_dropCodeChallenge();

		dispatch(finishAuthentication());
	};
}

/**
 * @function
 * @returns {ThunkAction}
 */
export function finishAuthentication() {
	return (dispatch: any) => {
		dispatch(authenticate({
			phase: IAuthenticationPhase.FINISH
		}));

		if (getFive9Lead() !== null) {
			/* Check whether the Five9 lead upload is still in progress */
			authStorage.persistRedirectRoute(null);
			return;
		}

		const redirectRoute = authStorage.getRedirectRoute();
		if (!redirectRoute) {
			return;
		}

		dispatch(navigate(redirectRoute));
		authStorage.persistRedirectRoute(null);
	};
}

/**
 * @function
 * @returns {ThunkAction}
 */
export function logOutUser() {
	return (dispatch: any) => {
		dispatch(deauthenticate());

		_dropAuthToken();
		_dropCodeChallenge();
		_clearSessionStorage();
		redirectToLoginPage();
	};
}

/**
 * @thunk
 * @function
 * @param {string} code
 * @returns {ThunkAction}
 */
export function acceptAuthenticationCode(code: string) {
	return (dispatch: any) => {
		dispatch(authenticate({
			phase: IAuthenticationPhase.ACCEPT_CODE,
			data: { code }
		}));

		if (!authStorage.getRedirectRoute()) {
			authStorage.persistRedirectRoute('/leads');
		}

		return dispatch(requestAuthToken(code));
	};
}
