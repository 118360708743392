import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';

interface IProps {
	name: string;
	className?: string;
}

const semanticToFontAwesomeMap = {
	'chevron left': 'chevron-left',
	'chevron right': 'chevron-right',
	'warning sign': 'exclamation-triangle',
	'check circle': 'check-circle',
	'list': 'list-ul',
	'delete': 'times',
	'remove': 'times',
	'dropdown': 'angle-right',
	'sort numeric up': 'sort-numeric-up-alt',
	'sort numeric down': 'sort-numeric-down',
	'sort content descending': 'sort-amount-down',
	'sort content ascending': 'sort-amount-up',
	'pause': 'pause',
	'play': 'play',
	'download': 'cloud-download'
};

// Replace semantic icons with Font Awesome.
const Icon: React.FC<IProps> = observer((props: IProps) => {
	const icon = _.get(semanticToFontAwesomeMap, props.name, '');

	return (
		<i className={`fal fa-fw fa-${icon} ${props.className || ''}`}></i>
	);
});

export default Icon;
