import { makeActionCreator } from '@harbortouch/react-modules';
import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';

export enum ACTION_NAMES {
	SHOW_NOTIFICATION = '@lms/system.notification/SHOW_NOTIFICATION',
	HIDE_NOTIFICATION = '@lms/system.notification/HIDE_NOTIFICATION'
}

export interface IShowNotificationPayload {
	skin: ISystemNotificationSkin;
	message: string;
}

/**
 * @deprecated
 * @type {IActionCreator<IShowNotificationPayload>}
 */
export const showSystemNotification = makeActionCreator<IShowNotificationPayload>(ACTION_NAMES.SHOW_NOTIFICATION);

/**
 * @deprecated
 * @type {IActionCreator<any>}
 */
export const hideSystemNotification = makeActionCreator(ACTION_NAMES.HIDE_NOTIFICATION);
