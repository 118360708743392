import * as React from 'react';
import {getStoreProxy} from '@lms/redux/redux.store.proxy';
import {getTranslation} from '@lms/utils/i18n';
import {IUserPermission} from '@lms/utils/state/user.state';
import Authorized from '@lms/views/components/authorized';
import {useOnKeyPress} from '@lms/views/utils/hooks/on.key.press.hook';
import UserMenu from './user.menu';
import CreateMenu from './create.menu';
import Dropdown from '@lms/views/modules/filters/dropdown';
import {NotificationsTray} from '@lms/views/modules/notifications';
import EventsTray from '@lms/views/modules/events/tray';
import notificationsStore, {NotificationsStore} from '@lms/views/modules/notifications/notifications.store';
import eventStore from '@lms/stores/event.store';
import {executeNotificationClickAction} from '@lms/views/modules/notifications/utils';
import applicationStore from '@lms/stores/application.store';
import {INotificationRecord} from '@lms/typings/records/notification.record.d';
import QuickSearch from './quick.search';

const MainHeader: React.FC = () => {
	const reduxStore = getStoreProxy();
	const store: NotificationsStore = React.useContext(notificationsStore);
	const user = reduxStore.state.user.metadata;
	const [showSearch, setShowSearch] = React.useState(false);

	const handleNotificationClick = (item: INotificationRecord) => {
		store.markRead(item);
		executeNotificationClickAction(item);
	};

	const toggleSidebar = () => {
		applicationStore.toggleSidebar();
	};

	useOnKeyPress('Escape', () => {
		store.removeLoadedNotifications();
	});

	React.useEffect(() => {
		if (!store.notifications) {
			store.fetchNotifications();
		}
		if (!eventStore.items) {
			eventStore.fetch();
		}
		const refreshInterval = setInterval(() => {
			store.handleRefreshNotifications();
			eventStore.fetch();
		}, 60000);
		return () => {
			clearInterval(refreshInterval);
		};
	});


	return (
		<header className="main-header">
			<ul className="header-nav">
				<li>
					<a className="nav-item sidebar-toggle" onClick={toggleSidebar}>
						<i className="fal fa-bars"></i>
					</a>
				</li>
				<li>
					<a className="nav-item" onClick={() => setShowSearch(true)}>
						<i className="fal fa-search circle-icon"></i>
					</a>
				</li>
			</ul>
			<div className={`quick-search`}>
				<QuickSearch
					active={showSearch}
					onClose={() => setShowSearch(false)}
				/>
			</div>
			<ul className="header-nav">
				<Authorized requirePermissions={[IUserPermission.CREATE_LEADS]}>
					<li>
						<Dropdown
							label="Create"
							handleRender={(handleProps) => {
								return (
									<a
										className="nav-item with-text with-border"
										onClick={() => handleProps.store.setIsOpen(!handleProps.store.isOpen)}
									>
										<span>Create</span>
										<i className="fal fa-chevron-down dropdown-icon"></i>
									</a>
								);
							}}
							menuRender={() => {
								return (
									<div className="dropdown-menu">
										<CreateMenu/>
									</div>
								);
							}}
						/>
					</li>
				</Authorized>
				<li>
					<Dropdown
						label="Notifications"
						handleRender={(handleProps) => {
							return (
								<a
									className="nav-item"
									onClick={() => handleProps.store.setIsOpen(!handleProps.store.isOpen)}
								>
									<i className="fal fa-bell circle-icon">
										{
											store.unread > 0 && <span>{store.unread < 100 ? store.unread : `99+`}</span>
										}
									</i>
								</a>
							);
						}}
						menuRender={(menuProps) => {
							return (
								<div className="dropdown-menu">
									<NotificationsTray
										onNotificationClick={(item) => handleNotificationClick(item)}
										onClose={() => menuProps.store.setIsOpen(!menuProps.store.isOpen)}
									/>
								</div>
							);
						}}
					/>
				</li>
				<li>
					<Dropdown
						label="events"
						handleRender={(handleProps) => {
							return (
								<a
									className="nav-item"
									onClick={() => handleProps.store.setIsOpen(!handleProps.store.isOpen)}
								>
									<i className="fal fa-calendar-alt circle-icon">
										{
											eventStore.total > 0 &&
                                            <span>{eventStore.total < 100 ? eventStore.total : `99+`}</span>
										}
									</i>
								</a>
							);
						}}
						menuRender={(menuProps) => {
							return (
								<div className="dropdown-menu">
									<EventsTray
										onClose={() => menuProps.store.setIsOpen(!menuProps.store.isOpen)}
									/>
								</div>
							);
						}}
					/>
				</li>
				<li className="my-account" data-test="my-account-dropdown">
					<Dropdown
						label="My Account"
						handleRender={(handleProps) => {
							return (
								<a
									className="nav-item"
									onClick={() => handleProps.store.setIsOpen(!handleProps.store.isOpen)}
								>
									<i className="fal fa-user circle-icon dark"></i>
									<div className="user-info">
										<span>{getTranslation(`common.labels.brands.${user.brandId}`)}</span>
										{user.fullName}
									</div>
									<i className="fal fa-chevron-down dropdown-icon"></i>
								</a>
							);
						}}
						menuRender={(menuProps) => {
							return (
								<div className="dropdown-menu">
									<div className="dropdown-header mobile-only">
										<i
											className="fal fa-long-arrow-left mobile-close"
											onClick={() => menuProps.store.setIsOpen(!menuProps.store.isOpen)}
										></i>
										<span>My Account</span>
									</div>
									<UserMenu/>
								</div>
							);
						}}
					/>
				</li>
			</ul>
		</header>
	);
};

export default MainHeader;
