import * as React from 'react';
import * as _ from 'lodash';
import { useOnKeyPress } from '@lms/views/utils/hooks/on.key.press.hook';
import Spinner from '@lms/views/elements/loaders/spinner';
import { searchOfficesOfUser } from '@lms/services/offices.service';
import { searchUsers } from '@lms/services/user.service';
import { searchLeads } from '@lms/services/lead.service';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { IOfficeRecord } from '@lms/typings/records/office.record';
import { IUserRecord } from '@lms/typings/records/user.record';
import { getLeadStatusColor } from '@lms/utils/ui.utils';
import LastUpdate from '@lms/views/elements/last.update.badge/index';
import { getTranslation } from '@lms/utils/i18n';
import { userHasRequiredPermissions } from '@lms/utils/selectors/user.selectors';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { IUserPermission } from '@lms/utils/state/user.state';
import Authorized from '@lms/views/components/authorized';
import { navigateToSelectedLead } from '@lms/models/lead.model/utils';
import { navigate } from '@lms/redux/actions/navigation.actions';

interface IProps {
	active: boolean;
	onClose: () => void;
}

interface ILeadItemsProps {
	items: ILeadRecord[];
}

interface IOfficeItemsProps {
	items: IOfficeRecord[];
}

interface IUserItemsProps {
	items: IUserRecord[];
}

const LeadItems: React.FC<ILeadItemsProps & IProps> = (props: ILeadItemsProps & IProps) => {
	if (!props.items.length) {
		return null;
	}

	const items = props.items.map((item) => {
		return (
			<a
				href={`/leads/${item.id}`}
				className="search-item"
				key={item.id}
				onClick={(event: React.SyntheticEvent<HTMLElement>) => {
					navigateToSelectedLead(item.id);
					props.onClose();
					event.preventDefault();
				}}
			>
				<div className="data-col item-title">
					{item.businessTitle}
					<small>#{item.id}</small>
				</div>
				<div className="data-col">
					<div className="item-status" data-color={getLeadStatusColor(item)}>
						{ getTranslation(`lead.status.${item.status}`) }
					</div>
				</div>
				<div className="data-col item-ago">
					<LastUpdate from={item.statusUpdatedAt as string} verbose />
				</div>
			</a>
		);
	});

	return (
		<>
			<div className="quick-search-header">Leads</div>
			{items}
		</>
	);
};


const OfficeItems: React.FC<IOfficeItemsProps & IProps> = (props: IOfficeItemsProps & IProps) => {
	if (!props.items.length) {
		return null;
	}

	const items = props.items.map((item) => {
		return (
			<a
				href={`/offices/${item.code}`}
				className="search-item"
				key={item.code}
				onClick={(event: React.SyntheticEvent<HTMLElement>) => {
					getStoreProxy().dispatch(navigate(`/offices/${item.code}`));
					props.onClose();
					event.preventDefault();
				}}
			>
				<div className="data-col item-title">
					{item.name}
					<small>{item.code}</small>
				</div>
				<Authorized requirePermissions={[IUserPermission.SEE_LEADS_FOR_ALL_BRANDS]}>
					<div className="data-col">
						{ getTranslation(`common.labels.brands.${item.brandId}`) }
					</div>
				</Authorized>
				<div className="data-col">
					<div className="item-status">
						{ item.channel && getTranslation(`labels.channels.${item.channel}`) }
					</div>
				</div>
			</a>
		);
	});

	return (
		<>
			<div className="quick-search-header">Offices</div>
			{items}
		</>
	);
};

const UserItems: React.FC<IUserItemsProps & IProps> = (props: IUserItemsProps & IProps) => {
	if (!props.items.length) {
		return null;
	}

	const items = props.items.map((item) => {
		return (
			<a
				href={`/users/${item.id}`}
				className="search-item"
				key={item.id}
				onClick={(event: React.SyntheticEvent<HTMLElement>) => {
					getStoreProxy().dispatch(navigate(`/users/${item.id}`));
					props.onClose();
					event.preventDefault();
				}}
			>
				<div className="data-col item-title">
					{item.fullName}
				</div>
				<div className="data-col">
					{item.loginEmail}
				</div>
				<div className="data-col item-ago">
					<LastUpdate from={item.lastLoginAt as string} verbose />
				</div>
			</a>
		);
	});

	return (
		<>
			<div className="quick-search-header">Users</div>
			{items}
		</>
	);
};

const QuickSearch: React.FC<IProps> = (props: IProps) => {
	const inputRef = React.useRef(null);
	const [suggestions, setSuggestions] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [height, setHeight] = React.useState(null);
	useOnKeyPress('Escape', props.onClose);

	const updateHeight = () => {
		setHeight(window.innerHeight - 65);
	};

	React.useEffect(() => {
		if (props.active) {
			inputRef.current.focus();
			setSuggestions(null);
			document.body.classList.add('no-scroll');
			updateHeight();
			window.addEventListener('resize', updateHeight);
		}

		return () => {
			document.body.classList.remove('no-scroll');
			setSuggestions(null);
			window.removeEventListener('resize', updateHeight);
		};
	}, [props.active]);

	if (!props.active) {
		return null;
	}

	const canSearchOffices = userHasRequiredPermissions(getStoreProxy().state, [IUserPermission.SEE_OFFICE_LIST]);
	const canSearchUsers = userHasRequiredPermissions(getStoreProxy().state, [IUserPermission.SEE_USER_LIST]);

	const suggestLeads = async (query: string) => {
		const { results: items } = await searchLeads(query, 5);
		return <LeadItems {...props} items={items} />;
	};

	const suggestOffices = async (query: string) => {
		if (!canSearchOffices) {
			return null;
		}
		const { results: items } = await searchOfficesOfUser(query, 5);
		return <OfficeItems {...props} items={items} />;
	};

	const suggestUsers = async (query: string) => {
		if (!canSearchUsers) {
			return null;
		}
		const { results: items } = await searchUsers(query, 5);
		return <UserItems {...props} items={items} />;
	};

	const getSuggestions = async (searchQuery: string) => {
		const query = searchQuery.trim();
		if (query === '') {
			setSuggestions(null);
			return;
		}

		setLoading(true);

		const results = await Promise.all([
			suggestLeads(query),
			suggestOffices(query),
			suggestUsers(query)
		]);

		setSuggestions(results);
		setLoading(false);
	};

	const getDebouncedSuggestions = _.debounce(getSuggestions, 300);

	const handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		getDebouncedSuggestions(value);
	};

	const placeholder = `Search for leads${canSearchOffices ? ', offices' : ''}${canSearchUsers ? ', users' : ''}`;

	return (
		<div className={`quick-search-wrapper`}>
			<i
				className="fal fa-long-arrow-left mobile-close"
				onClick={props.onClose}
			></i>
			<input
				type="text"
				placeholder={placeholder}
				ref={inputRef}
				onChange={handleInputChange}
			/>
			{
				loading && (
					<Spinner className="loading" noText />
				)
			}
			{
				!!suggestions && (
					<>
						<div className="quick-search-items" style={{'maxHeight': `${height}px`}}>
							{suggestions}
						</div>
					</>
				)
			}
			<i className="fal fa-times" onClick={props.onClose}></i>
			<div className="quick-search-overlay" onClick={props.onClose}></div>
		</div>
	);
};

export default QuickSearch;
