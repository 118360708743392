import { IActionHandler, handleActions } from '@harbortouch/react-modules';

import { ISystemNotificationState, initialState } from '@lms/utils/state/system.notification.state';
import {
	ACTION_NAMES,
	IShowNotificationPayload
} from '@lms/redux/actions/system.notification.actions';

/**
 * @function
 * @param {ISystemNotificationState} state
 * @param {IAction<IShowNotificationPayload>} action
 * @returns {ISystemNotificationState}
 */
const handleShowNotification: IActionHandler<ISystemNotificationState, IShowNotificationPayload> =
	(state, action) => {
		const { message, skin } = action.payload;

		return {
			...state,
			isVisible: true,

			message,
			skin
		};
	};

/**
 * @function
 * @param {ISystemNotificationState} state
 * @returns {ISystemNotificationState}
 */
const handleHideNotification: IActionHandler<ISystemNotificationState, null> = () => {
	return {
		...initialState,
		isVisible: false
	};
};

export default handleActions<ISystemNotificationState>({
	[ACTION_NAMES.SHOW_NOTIFICATION]: handleShowNotification,
	[ACTION_NAMES.HIDE_NOTIFICATION]: handleHideNotification
}, initialState);
