import * as _ from 'lodash';

import { IApplicationState } from '@lms/utils/state/application.state';
import { IUserPermission } from '@lms/utils/state/user.state';

/**
 * Returns whether the current user's permissions as stored in the
 * application state match the specified required permissions
 *
 * @function
 * @usage <caption>Example usage</caption>
 *     const isAuthorized = userHasRequiredPermissions(state, [IUserPermission.ASSIGN_LEADS])
 * @param {IApplicationState} state
 * @param {IUserPermission[]} requiredPermissions
 * @returns {boolean}
 */
export function userHasRequiredPermissions(
	state: IApplicationState,
	requiredPermissions: IUserPermission[]
): boolean {
	const { permissions } = state.user;
	return _.difference(requiredPermissions, permissions).length === 0;
}
