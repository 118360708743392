import * as _ from 'lodash';
import { makeActionCreator } from '@harbortouch/react-modules';

import { IFive9LeadRecord } from '@lms/typings/records/five9.lead.record';

import { postLead } from '@lms/services/lead.service';
import { persistFive9Lead } from '@lms/utils/storage/five9.storage';
import { postFive9LeadForParsing } from '@lms/services/five9.service';
import { redirectToLoginPage } from '@lms/services/auth.service';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { getAuthToken } from '@lms/utils/storage/auth.storage';

export enum ACTION_NAMES {
	ACCEPT_LEAD = '@lms/five9/ACCEPT_LEAD',
	UPLOAD_LEAD = '@lms/five9/UPLOAD_LEAD',
}

export enum IUploadPhase {
	PARSE = 'PARSE',
	POST = 'POST',
	FINISH = 'FINISH'
}

export interface IAcceptFive9LeadPayload {
	lead: IFive9LeadRecord;
}

export interface IUploadFive9LeadPayload {
	phase: IUploadPhase;
	data?: {
		postedLead: ILeadRecord;
	};
}

/**
 * Accepts lead data from AcceptLead route query parameters
 * and makes a request to the LMS API to attempt to parse the
 * lead with the given data. The LMS API will then return a
 * generated lead from the parsed data.
 *
 * @thunk
 * @function
 * @param {IFive9LeadModel} lead
 * @returns {ThunkAction}
 */
export function acceptFive9Lead(lead: IFive9LeadRecord) {
	const acceptAction = makeActionCreator<IAcceptFive9LeadPayload>(ACTION_NAMES.ACCEPT_LEAD);

	return (dispatch: any) => {
		if (!lead) {
			return;
		}

		dispatch(acceptAction({ lead }));

		const authToken = getAuthToken();
		if (_.isNil(authToken)) {
			persistFive9Lead(lead);
			redirectToLoginPage();

			return;
		}

		dispatch(uploadFive9Lead(lead));
	};
}

/**
 * Attempts to upload a Five9 lead to the LMS API by
 * requesting to parse and persist it
 *
 * @thunk
 * @function
 * @param {IFive9LeadModel} lead
 * @returns {ThunkAction}
 */
export function uploadFive9Lead(lead: IFive9LeadRecord) {
	const uploadAction = makeActionCreator<IUploadFive9LeadPayload>(ACTION_NAMES.UPLOAD_LEAD);

	return (dispatch: any) => {
		dispatch(uploadAction({ phase: IUploadPhase.PARSE }));
		postFive9LeadForParsing(lead)
			.then((parseResponse) => {
				const [ parsedLead ] = parseResponse.leads;
				dispatch(uploadAction({ phase: IUploadPhase.POST }));

				postLead(parsedLead)
					.then((postResponse) => {
						const [ postedLead ] = postResponse.leads;
						dispatch(uploadAction({
							phase: IUploadPhase.FINISH,
							data: { postedLead }
						}));
					});
			});
	};
}
