import * as _ from 'lodash';
import timezoneStore from '@lms/features/application.metadata/timezones/store';

/**
 * @function
 *
 * @export
 * @param {string} key
 * @returns {string}
 */
export function getViewReadyTimezoneLabel(key: string): string {
	return _.find(timezoneStore.timezones, { key }).label;
}
