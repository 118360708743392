import * as React from 'react';
import * as styles from './styles.scss';

interface IProps {
	noText?: boolean;
	text?: string;
	noPadding?: boolean;
	className?: string;
}

const Spinner: React.FC<IProps> = (props: IProps) => {
	const selectors = styles as any;

	return (
		<div
			className={`${selectors.spinner} ${props.className || ''} ${props.noPadding ? selectors.noPadding : ''}`}
		>
			<i className={`fal fa-spinner-third fa-spin`} />
			{
				!props.noText && <span>{props.text || 'Loading...'}</span>
			}
		</div>
	);
};

export default Spinner;
