declare let APP_DATA: any;

export interface IMetadataConfiguration {
	environment: string;
	domain: string;
	fullDateFormat: string;
	brand?: string;
}

export const config: IMetadataConfiguration = {
	...APP_DATA.metadata,
	fullDateFormat: 'MM/DD/YY'
} as IMetadataConfiguration;
