import * as apiClient from '@lms/services/clients/lms.api.client';
import { INotificationRecord } from '@lms/typings/records/notification.record';

export function getUserNotifications(query?: object): Promise<{
	total: number; read: number; unread: number; results: INotificationRecord[];
}> {
	return apiClient.get('/notifications', query);
}

export function markNotificationRead(query: object[]): Promise<any> {
	return apiClient.postJson(`/notifications/mark-read`, query);
}

export function markAllNotificationsRead(params?: object) {
	return apiClient.patchJson(`/notifications/mark-read-all`, params);
}
