import * as apiClient from '@lms/services/clients/lms.api.client';
import { IPromoTypeRecord } from '@lms/typings/records/promo-type.record';

interface IPromoTypesResponse {
	total: number;
	results: IPromoTypeRecord[];
}

/**
 * @function
 * @async
 * @returns {Promise<IPromoTypesResponse>}
 */
export function getPromoTypes(queryParams?: object): Promise<IPromoTypesResponse> {
	return apiClient.get('/promo-types', queryParams);
}

/**
 * @function
 * @param {number} id
 * @param {object} payload
 * @param {object} params
 * @returns {Promise<IPromoTypeRecord>}
 */
export function updatePromoType(id: number, payload: object, params?: object): Promise<IPromoTypeRecord> {
	return apiClient.patchJson(`/promo-types/${id}`, payload, params);
}

/**
 * @function
 * @param {object} payload
 * @returns {Promise<IPromoTypeRecord>}
 */
export function postPromoTypes(payload: { name: string }[]): Promise<IPromoTypeRecord[]> {
	return apiClient.postJson(`/promo-types/`, payload);
}

/**
 * @function
 * @param {number} id
 * @returns {Promise<IPromoTypeRecord>}
 */
export function deletePromoType(id: number): Promise<IPromoTypeRecord> {
	return apiClient.deleteResource(`/promo-types/${id}`);
}
