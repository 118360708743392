import { ITimezoneModel, TimezoneModel } from '@lms/models/timezone.model';
import { observable, action, runInAction } from 'mobx';
import { getTimezones } from '@lms/services/timezones.service';
import * as _ from 'lodash';

export class TimezoneStore {
	@observable public timezones: TimezoneModel[];
	@observable public isFetchingTimezones: boolean;

	constructor() {
		this.isFetchingTimezones = false;
	}

	@action public fetchTimezones = async () => {
		this.isFetchingTimezones = true;

		try {
			const results = await getTimezones();

			runInAction(() => {
				this.isFetchingTimezones = false;

				const timezoneModels = _.map(results, (timezone: ITimezoneModel) => new TimezoneModel(timezone));
				this.timezones = _.sortBy(timezoneModels, ['offsetLabel']);
			});
		} catch (e) {
			runInAction(() => {
				this.isFetchingTimezones = true;
			});

			throw e;
		}
	};
}

const timezoneStore = new TimezoneStore();
export default timezoneStore;
