import * as _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { IViewReadyEntity } from '@lms/utils/selectors/metadata.selectors';
import { PromoTypeModel } from '@lms/models/promo.type.model';
import { DepartmentModel } from '@lms/models/department.model';
import { getPromoTypes } from '@lms/services/promo.types.service';
import { getDepartments } from '@lms/services/departments.service';

interface IMetaItems {
	leadSources?: PromoTypeModel[];
	departments?: DepartmentModel[];
}

interface IMetaItemType {
	name: string;
	model: any;
	fetchMethod: (queryParams?: object) => Promise<any>;
}

export const metaItemTypes: IMetaItemType[] = [
	{ name: 'leadSource', model: PromoTypeModel, fetchMethod: getPromoTypes },
	{ name: 'department', model: DepartmentModel, fetchMethod: getDepartments }
];

export class MetadataStore {
	@observable public metaItems: IMetaItems = {};

	public fetchAll = () => {
		return Promise.all(_.map(metaItemTypes, (metaItemType) => {
			return this.fetchItems(metaItemType);
		}));
	};

	@action public fetchItems = async (metaItemType: IMetaItemType) => {
		const { name, fetchMethod, model } = metaItemType;

		try {
			const { results } = await fetchMethod({
				'isArchived:eq': false,
				'orderBy': 'name'
			});

			runInAction(() => {
				this.setMetaItems(name, results.map((item: any) => new model(item)));
			});
		} catch (e) {
			throw new Error(e);
		}
	};

	@action public setMetaItems = (metaItemName: string, items: any) => {
		// @ts-ignore
		this.metaItems[metaItemName] = _.filter(items, (item: any) => !item.isArchived);
	};

	public getMetaItems = (itemType?: string) => {
		return _.get(this.metaItems, itemType, []);
	};

	public reloadMetaItems = (itemType?: string) => {
		const metaItemType = _.find(metaItemTypes, {name: itemType});

		if (metaItemType) {
			this.fetchItems(metaItemType);
		}
	};

	public getViewReadyItems(itemType: string, noneOption?: boolean, selectedOption?: any): IViewReadyEntity[] {
		const metaItems = this.getMetaItems(itemType);
		const resultItems = metaItems.map((item: any) => {
			return { value: item.id, label: item.name };
		});

		if (noneOption) {
			resultItems.unshift({ label: 'None', value: null });
		}

		// Add currently selected option if it's archived
		if (selectedOption) {
			const existingOption = _.find(metaItems, {id: selectedOption.id});
			if (!existingOption) {
				resultItems.push({
					value: selectedOption.id,
					label: selectedOption.name
				});
			}
		}

		return resultItems;
	};
}

export default new MetadataStore();
