import * as _ from 'lodash';
import * as React from 'react';
import * as styles from './notifications.item.scss';
import LastUpdate from '@lms/views/elements/last.update.badge/index';
import notificationsStore, { NotificationsStore } from '../notifications.store';
import { Button, IButtonSkin } from '@lms/views/elements/button';
import { getNotificationText } from './notification.text';
import { INotificationRecord } from '@lms/typings/records/notification.record';
import { InView } from 'react-intersection-observer';
import { observer } from 'mobx-react';
import { getDayDifference } from '@lms/utils/date.utils';

interface IProps {
	onNotificationClick?: (item: INotificationRecord) => void;
	item?: INotificationRecord;
	onClose?: () => void;
}

const timeModifiers = {
	recent: { label: 'recent', value: 1 },
	week: { label: 'this week', value: 7 },
	older: { label: 'older', value: 7 }
};

// The lowest building block component. It is being rendered in a list
const NotificationItem: React.FC<IProps> = (props: IProps) => {
	const store: NotificationsStore = React.useContext(notificationsStore);

	const handleMarkRead = (event: React.SyntheticEvent) => {
		store.markRead(props.item);
		event.stopPropagation();
		return;
	};

	const itemConfig = getNotificationText(props.item);

	return (
		<div
			data-read={props.item.read}
			onClick={() => props.onNotificationClick(props.item)}
			className="tray-item"
		>
			<div className="tray-item-icon">
				<i className={`fal ${itemConfig.icon}`} />
			</div>
			<div className="tray-item-body">
				<div className="tray-item-text">
					{itemConfig.prefix || ''}
					{itemConfig.param1 ? <strong>{itemConfig.param1}</strong> : ''}

					{itemConfig.action || ''}

					{itemConfig.fileName ? <strong>{itemConfig.fileName}</strong> : ''}
					{itemConfig.param2 ? <strong>{itemConfig.param2}</strong> : ''}
					{itemConfig.oldValue ? <strong>{itemConfig.oldValue}</strong> : ''}

					{itemConfig.joint || ''}

					{itemConfig.param3 ? <strong>{itemConfig.param3}</strong> : ''}
					{itemConfig.newValue ? <strong>{itemConfig.newValue}</strong> : ''}

					{itemConfig.sufix || ''}
				</div>
				<div className="tray-item-meta">
					<span className="ago">
						<LastUpdate from={props.item.createdAt as any} verbose />
					</span>
					{
						!props.item.read && (
							<span>
								<a onClick={(e: React.SyntheticEvent) => handleMarkRead(e)}>
									Mark as read
								</a>
							</span>
						)
					}
				</div>
			</div>
		</div>
	);
};

export const NotificationItems: React.FC<IProps> = observer((props: IProps) => {
	const store: NotificationsStore = React.useContext(notificationsStore);

	const selectors = styles as any;

	const notificatonsList: React.ReactElement[] = [];
	let notificationItems: React.ReactElement[] = [];
	let category = '';

	const pushNotificationItems = (notificationItem: INotificationRecord, label: string) => {
		if (category !== label) {
			notificationItems.push((
				<div className={selectors.sectionLabel} key={label}>
					<span>{label.toUpperCase()}</span>
				</div>
			));
			category = label;
		}
		notificationItems.push((
			<NotificationItem
				key={notificationItem.id}
				item={notificationItem}
				onNotificationClick={props.onNotificationClick}
			/>
		));
	};

	const getTimeLabel = (notification: INotificationRecord) => {
		const dayDifference = getDayDifference(notification.createdAt as any);

		if (dayDifference <= timeModifiers.recent.value) {
			return pushNotificationItems(notification, timeModifiers.recent.label);
		}

		if ((dayDifference > timeModifiers.recent.value) && (dayDifference <= timeModifiers.week.value)) {
			return pushNotificationItems(notification, timeModifiers.week.label);
		}

		if (dayDifference > timeModifiers.week.value) {
			return pushNotificationItems(notification, timeModifiers.older.label);
		}
	};

	_.each(store.notifications, (notification: INotificationRecord) => {
		getTimeLabel(notification);
	});

	notificatonsList.push(...notificationItems);
	notificationItems = [];
	category = '';

	const handleIntersecting = (inView: boolean) => {
		if (inView) {
			store.fetchNotificationsOnIntersection();
		}
	};

	const handleMarkAllRead = () => {
		store.markAllRead();
	};

	const shouldLoadMoreNotifications = (): boolean => {
		return (store.notifications.length >= store.itemsPerLoad) && (store.notifications.length < store.total);
	};

	return (
		<>
			<div className={`${selectors.markAllContainer} dropdown-header`}>
				<i
					className="fal fa-long-arrow-left mobile-close"
					onClick={props.onClose}
				></i>
				<span>Notifications</span>
				{
					store.notifications.length > 0 && (
						<Button
							skin={IButtonSkin.TEXT}
							size="sm"
							onClick={() => handleMarkAllRead()}
						>
							Mark all read
						</Button>
					)
				}
			</div>

			{/** Main Notification List */}
			<>
				{notificatonsList}
			</>

			{/* Intersection observer This component handles lazy loading upon appearing in view port */}
			{
				shouldLoadMoreNotifications() && (
					<InView as="div" onChange={(inView) => handleIntersecting(inView)} threshold={0.9}>
						<div className={selectors.intersectionLoader}>
							{
								store.isLoading ?
									<span>Loading more notifications...<i className="fal fa-spinner-third"></i></span>
									: store.isLoading === false ?
										<a onClick={() => store.fetchNotificationsOnIntersection()}>Load more notifications</a>
										: null
							}
						</div>
					</InView>
				)
			}
		</>
	);
});
