import * as _ from 'lodash';

import { create as createFormatter } from 'string-format';
import Dictionary from '@lms/utils/i18n/dictionary-en';

/**
 * Instance for `string-format`'s formatter
 *
 * @type {void}
 */
export const stringFormatter: void = createFormatter({
	escape: (original) => {
		return original.replace(/[&<>"'`]/g, (c) => {
			return `&#${c.charCodeAt(0)};`;
		});
	}
});

/**
 * Returns the value of a string dictionary element.
 * To be used with react-redux' `connect()` method
 * when passing formatted strings to the component.
 *
 * Also supports nested string keys.
 * **Note:** The dictionary must not contain names with periods
 * **Note:** State properties that use this selector are expected to have a *Message postfix
 *
 * @usage <caption>Example usage</caption>
 *     const value = getDictionaryValue('messages.meta.name', { name: 'Paul" })
 * @function
 * @param {string} key
 * @param {Map<string, string>} params - Replacement map object
 * @returns {string}
 */
export function getDictionaryValue(key: string, params?: object): string {
	const values = Dictionary;
	const result = _.get(values, key, key) as string;

	if (params) {
		return (stringFormatter as any).apply(null, [result].concat(params as any));
	}

	return result;
}
