import * as LogRocket from 'logrocket';
import { action, observable } from 'mobx';
import { subscribe, Event, eventBus } from 'mobx-event-bus2';

import { ITransport } from '@lms/utils/analytics/transport/interfaces/transport';
import { IAnalyticsEvent } from '@lms/utils/analytics/events/analytics.event';
import { config as analyticsConfig } from '@lms/config/analytics.config';
import { IUserRecord } from '@lms/typings/records/user.record';

export class LogRocketTransport implements ITransport {
	public readonly name: string;
	@observable public initialized: boolean;

	constructor() {
		eventBus.register(this);
		this.name = 'logRocket';
		this.initialized = false;
	}

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public initialize = () => {
		LogRocket.init(analyticsConfig.logRocketId);
		this.initialized = true;
	};

	/**
	 * @function
	 * @param {IAnalyticsEvent} event
	 */
	public push = (event: IAnalyticsEvent) => {
		if (!this.initialized) {
			console.warn(`Attempt to use uninitialized transport [${this.name}], event:`, event);
			return;
		}

		LogRocket.track(event.name || event.parameters.action);
	};

	public pushPageView = () => {
		return;
	};

	@subscribe('user.currentUserLoaded')
	private identifyUser(event: Event<IUserRecord>) {
		const user = event.payload;
		LogRocket.identify(String(user.id), {
			name: user.fullName,
			email: user.loginEmail,
			role: user.role
		});
	}
}
