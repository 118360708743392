import * as _ from 'lodash';

import { IActionHandler, handleActions } from '@harbortouch/react-modules';
import { IRequestStatus } from '@lms/redux/actions/common/request.status.enum';

import {
	ACTION_NAMES as USER_ACTION_NAMES,
	IFetchUserDataPayload
} from '@lms/redux/actions/user.actions';

import {
	IUserState,
	initialState,
	IUserPermission
} from '@lms/utils/state/user.state';

import {
	USER_SETTINGS_ACTION_NAMES,
	ILoadInUpdatedUserPayload
} from '@lms/views/pages/account.page/account.settings.service';

import {
	AUTH_ACTION_NAMES,
	IAuthenticatePayload,
	IAuthenticationPhase
} from '@lms/redux/actions/auth.actions';

/**
 * @function
 * @type {IActionHandler<IUserState, IAuthenticatePayload>}
 * @param {IUserState} state
 * @param {IAction<IAuthenticatePayload>} action
 * @returns {IUserState}
 */
const handleAuthenticate: IActionHandler<IUserState, IAuthenticatePayload> = (state, action) => {
	const { phase, data } = action.payload;

	switch (phase) {
		case IAuthenticationPhase.INIT_LOGIN:
		case IAuthenticationPhase.FAIL: {
			return {
				...state,
				isAuthenticated: false
			};
		}

		case IAuthenticationPhase.ACCEPT_TOKEN: {
			const newPermissions = _.get(data, 'token.permissions');
			const newFineGrainedPermissions = _.get(data, 'token.fineGrainedPermissions');
			const newContentAccessToken = _.get(data, 'token.content_access_token');
			const userId = _.get(data, 'token.user_id');

			return {
				...state,
				isAuthenticated: true,

				metadata: {
					...state.metadata,
					id: userId
				},

				fineGrainedPermissions: newFineGrainedPermissions,
				permissions: newPermissions as IUserPermission[],
				contentAccessToken: newContentAccessToken
			};
		}

		default: return state;
	}
};

/**
 * @function
 * @param {IUserState} state
 * @returns {IUserState}
 */
const handleDeauthenticate: IActionHandler<IUserState, null> = (state) => {
	return {
		...state,
		isAuthenticated: false
	};
};

/**
 * @function
 * @param {IUserState} state
 * @param {IAction<IFetchUserDataPayload>} action
 * @returns {IUserState}
 */
const handleFetchCurrentUserData: IActionHandler<IUserState, IFetchUserDataPayload> = (state, action) => {
	const { status, data } = action.payload;

	switch (status) {
		case IRequestStatus.SUCCESS: {
			return {
				...state,
				metadata: {
					...data.user,

					address: {
						street: null,
						city: null,
						state: null,
						zip: null,
						...data.user.address
					},

					primaryContacts: {
						phone: null,
						...data.user.primaryContacts
					}
				}
			};
		}

		default: return state;
	}
};

/**
 * @function
 * @param {IUserState} state
 * @param {IAction<ILoadInUpdatedUserPayload>} action
 * @returns {IUserState}
 */
const handleLoadInUpdatedUser: IActionHandler<IUserState, ILoadInUpdatedUserPayload> =
	(state, action) => {
		return {
			...state,
			metadata: {
				...action.payload.user,

				address: {
					street: null,
					city: null,
					zip: null,
					state: null,
					...action.payload.user.address
				},

				primaryContacts: {
					phone: null,
					...action.payload.user.primaryContacts
				}
			}
		};
	};

/**
 * User reducer responsible for performing
 * user state mutations
 *
 * @function
 * @type {Reducer<IUserState>}
 * @returns {IUserState}
 */
export default handleActions<IUserState>({
	[AUTH_ACTION_NAMES.AUTHENTICATE]: handleAuthenticate,
	[AUTH_ACTION_NAMES.DEAUTHENTICATE]: handleDeauthenticate,
	[USER_ACTION_NAMES.FETCH_CURRENT_USER_DATA]: handleFetchCurrentUserData,
	[USER_SETTINGS_ACTION_NAMES.LOAD_IN_UPDATED_USER]: handleLoadInUpdatedUser
}, initialState);
