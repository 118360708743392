import * as React from 'react';
import { Button, IButtonSkin } from '@lms/views/elements/button';

interface IProps {
	className: string;
	handleRefreshClick: () => void;
}

export const RefreshNotifications: React.FC<IProps> = (props: IProps) => {
	return (
		<Button
			onClick={() => props.handleRefreshClick()}
			skin={IButtonSkin.TEXT}
			className={props.className}
		>
			You have new notifications. Click here to refresh
		</Button>
	);
};
