import * as React from 'react';
import * as styles from './text.area.scss';

interface IProps {
	disabled?: boolean;
	value?: string;
	rows?: number;
	maxRows?: number;
	placeholder?: string;
	maxLength?: number;
	error?: boolean;
	className?: string;
	label?: string;

	onValuePass?: (value: string) => void;
	onBlur?: (value: string) => void;
}

export const ResizableTextArea: React.FC<IProps> = (props: IProps) => {
	const selectors = styles as any;

	const [value, setValue] = React.useState(props.value);
	const [rows, setRows] = React.useState(props.rows || 1);
	const [minRows] = React.useState(props.rows || 1);
	const [maxRows] = React.useState(props.maxRows || 20);
	const [charCount, setCharCount] = React.useState(props.maxLength || 0);

	React.useEffect(() => {
		if (props.value === '') {
			setValue(props.value);
			setRows(2);
		}
	}, [props.value]);

	const handleChange = (event: any) => {
		const textAreaLineHeight = 24;

		const previousRows = event.target.rows;
		event.target.rows = minRows;

		// eslint:disable-next-line: no-bitwise
		const currentRows = ~~(event.target.scrollHeight / textAreaLineHeight);

		if (currentRows === previousRows) {
			event.target.rows = currentRows;
		}

		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}

		setValue(event.target.value);
		setRows(currentRows < maxRows ? currentRows : maxRows);

		if (props.maxLength > 0) {
			setCharCount(props.maxLength - event.target.value.length);
		}

		props.onValuePass(event.target.value);
	};

	const handleBlur = () => {
		if (props.onBlur) {
			props.onBlur(value);
		}
	};

	return (
		<div className={`${selectors.container} ${props.className ? props.className : ''}`}>
			{props.label && (
				<label>{props.label}</label>
			)}
			<textarea
				disabled={props.disabled}
				maxLength={props.maxLength}
				rows={rows}
				value={value}
				placeholder={props.placeholder}
				className={selectors.textArea}
				onChange={handleChange}
				onBlur={handleBlur}
				data-error={props.error || false}
			/>

			{
				props.maxLength > 0 ?
					<span>{`${charCount}/${props.maxLength} characters left`}</span>
					: null
			}

		</div>
	);

};
