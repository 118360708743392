import {
	action,
	observable,
	runInAction
} from 'mobx';
import { getReleaseNoteInfoResponse} from '@lms/services/release.notes.service';
import * as queryBuilder from 'objection-find-query-builder';

export class ReleaseNotesUnreadStore {
	@observable public unread: number;

	public baseQuery() {
		const builder = queryBuilder.builder();
		return builder
			.eager(['images'])
			.build();
	}

	@action public fetchReleaseNotes = async () => {
		const results = await getReleaseNoteInfoResponse(this.baseQuery());
		runInAction(() => {
			this.unread = results.unread;
		});
	};

	@action public markRead = (read: boolean) => {
		if (read) {
			runInAction(() => {
				this.unread--;
			});
		}
	};
};

export default new ReleaseNotesUnreadStore();
