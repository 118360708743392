import { IPromoTypeRecord } from '@lms/typings/records/promo-type.record';
import { forcefullyExtendObservable } from '@lms/utils/model.utils';

export class PromoTypeModel implements IPromoTypeRecord {
	public id: number;
	public name: string;
	public isArchived: boolean;

	constructor(json: object) {
		forcefullyExtendObservable(this, json);
	}
}
