declare let module: any;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import 'mobx-react/batchingForReactDom'; // https://github.com/mobxjs/mobx-react/pull/787#issuecomment-573599793

import { Store } from 'redux';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import { configure as configureMobX } from 'mobx';
configureMobX({ enforceActions: 'observed' });

import { ConnectedRouter } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';

import { configureStore } from '@lms/redux/application.store';
import { setDeauthenticationMediator } from '@lms/services/clients/lms.api.client';
import { config as metadataConfig } from '@lms/config/metadata.config';

import { IApplicationState } from '@lms/utils/state/application.state';
import { getAuthToken } from '@lms/utils/storage/auth.storage';
import { acceptAuthToken, logOutUser } from '@lms/redux/actions/auth.actions';

import analytics from '@lms/utils/analytics';

import ApplicationWrapper from '@lms/views/wrapper.component';
import '@lms/styles/global.scss';

const isProductionEnvironment = ![
	'workstation',
	'testing'
].includes(metadataConfig.environment);

/**
 * Enables hot module replacement with preservation
 * of the application state
 *
 * @function
 * @param {Store<IApplicationState>} store - Redux store for application
 * @param {History} history - Browser history
 * @param {boolean} hmrEnabled - Flag to check whether hot module replacement is enabled
 */
function enableHotModuleReplacement(store: Store<IApplicationState>, history: History, hmrEnabled: boolean) {
	// TODO is this needed?
	if (!hmrEnabled) {
		return;
	}

	module.hot.accept('./views/wrapper.component.tsx', () => renderAppliction(store, history));
	module.hot.dispose((data: any) => {
		data.store = store;
		data.history = history;
	});
}

/**
 * Renders the application with React
 *
 * @function
 * @param {Store<IApplicationState>} store - Redux store for application
 * @param {History} history - Browser history for react-router
 */
function renderAppliction(store: Store<IApplicationState>, history: History) {
	ReactDOM.render(
		<AppContainer>
			<Provider store={store as any}>
				<ConnectedRouter history={history}>
					<ApplicationWrapper />
				</ConnectedRouter>
			</Provider>
		</AppContainer>,
		document.getElementById('root')
	);
}

/**
 * Initializes and returns the application Redux store
 *
 * @function
 * @param {History} history - Browser history for react-router
 * @param {boolean} hmrEnabled
 * @returns {Store<IApplicationState>}
 */
function getApplicationStore(history: History, hmrEnabled: boolean): Store<IApplicationState> {
	const data = module.hot ? module.hot.data : undefined;

	if (hmrEnabled && data !== undefined) {
		return data.store;
	}

	return configureStore({}, history);
}

/**
 * @function
 * @param {boolean} hmrEnabled
 * @returns {any}
 */
function getBrowserHistory(hmrEnabled: boolean) {
	const data = module.hot ? module.hot.data : undefined;

	if (hmrEnabled && data !== undefined) {
		return data.history;
	}

	return createBrowserHistory();
}

/**
 * Hook to perform Redux state initializations which
 * can't be described in the Store's `initialState`
 * or middleware hooks
 *
 * @function
 * @param {Store<IApplicationState>} store - Redux store for application
 */
function initializeState(store: Store<IApplicationState>): void {
	// TODO refactor
	setDeauthenticationMediator(() => store.dispatch(logOutUser() as any));

	const authToken = getAuthToken();
	if (!_.isNil(authToken)) {
		// TODO refactor
		store.dispatch(acceptAuthToken(authToken) as any);
	}
}

/**
 * Initializes the application in three steps:
 * 1) Configures and creates the application store
 * 2) Renders the view layer with React
 * 3) If required, enables hot module replacement for this module
 *
 * @function
 */
function runApplication() {
	const hmrEnabled = !isProductionEnvironment && module.hot;

	const history: History = getBrowserHistory(hmrEnabled);
	const store: Store<IApplicationState> = getApplicationStore(history, hmrEnabled);

	analytics.initialize();
	initializeState(store);
	renderAppliction(store, history);
	enableHotModuleReplacement(store, history, hmrEnabled);
}

runApplication();
