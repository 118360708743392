import { ILeadRecord } from '@lms/typings/records/lead.record';
import { IParsedLeadsResponse } from '@lms/typings/responses/lead.parse.response';
import { IAttachmentRecord } from '@lms/typings/records/attachment.record';
import { ILeadCommentRecord } from '@lms/typings/records/lead-comment.record';
import { IAssigneeCandidateListQuery } from '@lms/typings/queries/assignee-candidate-list.query';

import * as attachmentTypeEnum from '@harbortouch/lms-enums/lib/enums/attachment.attachment-type.enum';
import * as publicHeaderEnum from '@harbortouch/lms-enums/lib/common/headers';
import * as apiClient from '@lms/services/clients/lms.api.client';
import * as queryBuilder from 'objection-find-query-builder';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { IEventRecord } from '@lms/typings/records/event.record';

const ATTACHMENT_TYPE_HEADER = (publicHeaderEnum as any).ATTACHMENT_TYPE;
const PLAIN_FILE_ATTACHMENT_TYPE = (attachmentTypeEnum as any).PLAIN_FILE;
const STATEMENT_ATTACHMENT_TYPE = (attachmentTypeEnum as any).STATEMENT;
const AUDIO_RECORDING_TYPE = (attachmentTypeEnum as any).AUDIO_RECORDING;

export const patchLeads = (patch: object, query?: object) => {
	return apiClient.patchJson('/leads', patch, query);
};

/**
 * Asynchronously requests a list of leads for the currently
 * authenticated user from the API
 *
 * @async
 * @function
 * @param {Object} [searchParams]
 *     - supports {status:eq : 'submitted', creationDate.gt: '2013-03-11T12:00:00Z', eager: 'address'} querying format
 * @returns {Promise<{results: ILeadRecord[]}>}
 */
export function getLeads(searchParams?: object): Promise<{ results: ILeadRecord[] }> {
	return apiClient.get('/leads', searchParams);
}

/**
 * @function
 * @param {number} userId
 * @param {object} searchParams
 * @returns {Promise<{results: ILeadRecord[]}>}
 */
export function getLeadsOfSubordinates(
	userId: number,
	searchParams?: object
): Promise<{ total: number; results: ILeadRecord[] }> {
	return apiClient.get(`/users/${userId}/subordinates/leads`, searchParams);
}

export function exportLeadsOfSubordinates(userId: number, searchParams?: object): Promise<any> {
	return apiClient.getSpreadsheet(`/users/${userId}/subordinates/leads`, searchParams);
}

export function searchLeads(query: string, limit?: number): Promise<{ results: ILeadRecord[] }> {
	const builder = queryBuilder
		.builder()
		.rangeStart(0)
		.rangeEnd(limit ? limit - 1 : 19)
		.anyLikeLower([
			'id',
			'businessTitle'
		], `%${query}%`);

	return getLeadsOfSubordinates(getStoreProxy().state.user.metadata.id, builder.build());
}

/**
 * Asynchronously requests a specific lead by it's id from the API
 *
 * @async
 * @function
 * @param {number} id
 * @param {object} query
 * @returns {Promise<ILeadRecord>}
 */
export function getLeadById(id: number, query?: object): Promise<ILeadRecord> {
	return apiClient.get(`/leads/${id}`, query);
}

/**
 * @async
 * @function
 * @param {number} leadId
 * @param {IAssigneeCandidateListQuery} query
 * @returns {Promise<any>}
 */
export function getAssigneeCandidateListForLead(leadId: number, query?: IAssigneeCandidateListQuery): Promise<any> {
	return apiClient.get(`/leads/${leadId}/assignee-candidates`, query);
}

/**
 * Asynchronously requests to post a lead with the specified data to the API
 *
 * @function
 * @param {ILeadRecord} lead
 * @returns {Promise<ILeadRecord>}
 */
export function postLead(lead: ILeadRecord): Promise<{ leads: ILeadRecord[] }> {
	return apiClient.postJson('/leads', [lead]);
}

export function postLeadWithoutDuplicates(lead: ILeadRecord): Promise<{ leads: ILeadRecord[] }> {
	return apiClient.postJson('/leads-without-duplicates', [lead]);
}

/**
 * Asynchronously posts initial appointment with the lead being created to the LMS API
 *
 * @function
 * @export
 * @param {number} leadId
 * @param {IEventRecord} appointment
 * @returns {Promise<IEventRecord>}
 */
export function _postAppointment(leadId: number, appointment: IEventRecord): Promise<IEventRecord> {
	return apiClient.postJson(`/leads/${leadId}/appointments`, appointment);
}

/**
 * Checks if the initial appointment form is not empty. Returns an empty promise if it is
 * Calls actual appointment post method if it is not.
 *
 * @export
 * @param {number} leadId
 * @param {IEventRecord} appointment
 * @returns
 */
export function postLeadAppointment(leadId: number, appointment: IEventRecord) {
	if (!appointment) {
		return Promise.resolve({} as IEventRecord);
	}

	return _postAppointment(leadId, appointment);
}

/**
 * Asynchronously patches the specified lead in the LMS API with
 * the specified updates
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Partial<ILeadRecord>} patch - Object of { [leadAttributeName]: value } type
 * @param {object} queryParams
 * @returns {Promise<any>}
 */
export function patchLead(leadId: number, patch: Partial<ILeadRecord>, queryParams?: object): Promise<ILeadRecord> {
	return apiClient.patchJson(`/leads/${leadId}`, patch, queryParams);
}

/**
 * Asynchronously deletes the specified lead in the LMS API
 *
 * @async
 * @function
 * @param {string} leadId
 * @returns {Promise<any>}
 */
export function deleteLead(leadId: number): Promise<any> {
	return apiClient.deleteResource(`/leads/${leadId}`);
}

/**
 * Asynchronously patches the specified attachment bound to the
 * specified lead with the specified updates
 *
 * @async
 * @param {number} leadId
 * @param {number} attachmentId
 * @param {Partial<IAttachmentRecord>} patch
 * @param {object} queryParams
 * @returns {Promise<any>}
 */
export function patchAttachment(
	leadId: number,
	attachmentId: number,
	patch: Partial<IAttachmentRecord>,
	queryParams?: object
): Promise<any> {
	return apiClient.patchJson(`/leads/${leadId}/attachments/${attachmentId}`, patch, queryParams);
}

/**
 * Asynchronously posts uploaded lead entry file to the LMS API so it can be parsed
 * for contained headers and their values for header-to-attribute mapping
 *
 * @async
 * @function
 * @param {Blob} blob
 * @returns {Promise<IParsedLeadsResponse>}
 */
export function postLeadDataFileForParsing(blob: Blob): Promise<IParsedLeadsResponse> {
	return apiClient.postForm<IParsedLeadsResponse>('/leads/parse', [{ field: 'sourceFile', file: blob }]);
}

/**
 * Asynchronously posts the generated leads with correct header-to-attribute mappings to the
 * LMS API so they can be persisted.
 *
 * @async
 * @function
 * @param {ILeadRecord[]} leads - Leads returned by the `createFromParsedData` method from the lead mapping service
 * @returns {Promise<any>}
 */
export function postImportedLeads(leads: ILeadRecord[]): Promise<any> {
	return apiClient.postJson('/leads', leads);
}

/**
 * @async
 * @function
 * @param {object} applicationData
 * @returns {Promise<ILeadRecord>}
 */
export function postApplicationToSC(applicationData: any): Promise<ILeadRecord> {
	return apiClient.postJson('/applications', applicationData);
}

/**
 * Asynchronously posts an attachment file blob to the specified lead to the
 * LMS API
 *
 * @param {number} leadId
 * @param {Blob} blob
 * @param {string} attachmentType
 * @returns {Promise<{attachments: IAttachmentRecord[]}>}
 * @private
 */
function _postLeadAttachment(
	leadId: number,
	blob: Blob,
	attachmentType: string
): Promise<{ attachments: IAttachmentRecord[] }> {
	return apiClient.postForm(`/leads/${leadId}/attachments`, [{ field: 'attachment', file: blob }], null, null, [
		{ name: ATTACHMENT_TYPE_HEADER, value: attachmentType }
	]);
}

/**
 * Asynchronously posts a file blob to the LMS API to attach it to a certain
 * lead with the specified id
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Blob} blob - Uploaded file blob
 * @returns {Promise<any>}
 */
export function postLeadPlainFile(leadId: number, blob: Blob) {
	return _postLeadAttachment(leadId, blob, PLAIN_FILE_ATTACHMENT_TYPE);
}

/**
 * Asynchronously posts a file blob to the LMS API to attach it to a certain
 * lead with the specified id
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Blob} blob - Uploaded file blob
 * @returns {Promise<any>}
 */
export function postLeadStatement(leadId: number, blob: Blob) {
	return _postLeadAttachment(leadId, blob, STATEMENT_ATTACHMENT_TYPE);
}

/**
 * Asynchronously posts a file blob to the LMS API to attach it to a certain
 * lead with the specified id
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Blob} blob - Uploaded file blob
 * @returns {Promise<any>}
 */
export function postLeadAudioRecord(leadId: number, blob: Blob) {
	return _postLeadAttachment(leadId, blob, AUDIO_RECORDING_TYPE);
}

/**
 * Asynchronously posts attachment file blobs certain lead with the specified id
 * to the LMS API
 *
 * @param {number} leadId
 * @param {Blob[]} blobs
 * @param {string} attachmentTypes
 * @returns {Promise<{attachments: IAttachmentRecord[]}>}
 * @private
 */
function _postLeadAttachments(
	leadId: number,
	blobs: Blob[],
	attachmentTypes: string
): Promise<{ attachments: IAttachmentRecord[] }> {
	return apiClient.postForm(
		`/leads/${leadId}/attachments`,
		blobs.map((blob, index) => {
			return {
				field: `attachment${index}`,
				file: blob
			};
		}),
		null,
		null,
		[{ name: ATTACHMENT_TYPE_HEADER, value: attachmentTypes }]
	);
}

/**
 * Asynchronously posts file blobs certain lead with the specified id
 * to the LMS API
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Blob[]} blobs - Uploaded file blob
 * @returns {Promise<any>}
 */
export function postLeadPlainFiles(leadId: number, blobs: Blob[]) {
	if (blobs.length === 0) {
		return Promise.resolve({});
	}

	return _postLeadAttachments(leadId, blobs, PLAIN_FILE_ATTACHMENT_TYPE);
}

/**
 * Asynchronously posts file blobs certain lead with the specified id
 * to the LMS API
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {Blob[]} blobs - Uploaded file blob
 * @returns {Promise<any>}
 */
export function postLeadStatements(leadId: number, blobs: Blob[]) {
	if (blobs.length === 0) {
		return Promise.resolve({});
	}

	return _postLeadAttachments(leadId, blobs, STATEMENT_ATTACHMENT_TYPE);
}

/**
 * Makes a delete request to the LMS API to remove an attachment with the
 * specified id from the specified lead
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {string} attachmentId
 * @returns {Promise<any>}
 */
export function deleteLeadAttachment(leadId: number, attachmentId: number): Promise<any> {
	return apiClient.deleteResource(`/leads/${leadId}/attachments/${attachmentId}`);
}

/**
 * Gets the binary data of the specified attachment from the LMS API to save it
 * to the user's machine from the browser
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {string} attachmentId
 * @returns {Promise<any>}
 */
export function getLeadAttachmentData(leadId: number, attachmentId: number): Promise<Blob> {
	return apiClient.getBlob(`/leads/${leadId}/attachments/${attachmentId}`);
}

/**
 * Gets the binary data of the specified attachment from the specified comment from LMS API
 * to save it to the user's machine from the browser
 *
 * @async
 * @function
 * @param {number} leadId
 * @param {number} commentId
 * @param {string} attachmentId
 * @returns {Promise<any>}
 */
export function getCommentAttachmentData(leadId: number, commentId: number, attachmentId: number): Promise<Blob> {
	return apiClient.getBlob(`/leads/${leadId}/comments/${commentId}/attachments/${attachmentId}`);
}

/**
 * Asynchronously gets the comments for the specified lead from the LMS API
 *
 * @async
 * @function
 * @param {string} leadId
 * @param {object} query
 * @returns {Promise<ILeadCommentRecord[]>}
 */
export function getLeadComments(leadId: number, query?: object): Promise<{ leadComments: ILeadCommentRecord[] }> {
	return apiClient.get(`/leads/${leadId}/comments`, query);
}

/**
 * Asynchronously posts a comment with attachments to the specified lead to the  LMS API
 *
 * @async
 * @function
 * @param {number} leadId
 * @param {string} text
 * @param {Blob[]} attachments
 * @param {object} queryParams
 * @returns {Promise<ILeadCommentRecord>}
 */
export function postLeadComment(
	leadId: number,
	text: string,
	attachments: Blob[],
	queryParams?: object
): Promise<ILeadCommentRecord> {
	return apiClient.postForm(
		`/leads/${leadId}/comments`,

		attachments.map((blob, index) => {
			return {
				field: `attachment${index}`,
				file: blob
			};
		}),

		[{ name: 'text', value: text }],

		queryParams,

		[{ name: ATTACHMENT_TYPE_HEADER, value: PLAIN_FILE_ATTACHMENT_TYPE }]
	);
}

/**
 * @function
 * @returns {Promise<Blob>}
 */
export function getExampleImportCSVFile(): Promise<Blob> {
	return apiClient.getBlob(`/leads/example-import-files/csv`);
}

/**
 * @function
 * @returns {Promise<Blob>}
 */
export function getExampleImportExcelFile(): Promise<Blob> {
	return apiClient.getBlob(`/leads/example-import-files/xlsx`);
}
