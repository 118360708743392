import { delegate } from '@harbortouch/react-modules';
import GenericLoader from '@lms/views/elements/loaders/generic.loader';

/** ********************************************************
 * Asynchronous page component wrappers for code-splitting
 **********************************************************/
export const NotFoundPage = delegate({
	loader: () => import(/* webpackChunkName: "not-found-page" */ '@lms/views/pages/not.found.page'),
	loading: GenericLoader
});

export const LeadListPage = delegate({
	loader: () => import(/* webpackChunkName: "leads-page" */ '@lms/views/pages/lead.list.page'),
	loading: GenericLoader
});

export const LeadInfoPage = delegate({
	loader: () => import(/* webpackChunkName: "lead-info-page" */ '@lms/views/pages/lead.info.page'),
	loading: GenericLoader
});

export const CampaignSubmissionsListPage = delegate({
	loader: () => import(/* webpackChunkName: "campaign-submissions-list-page" */ '@lms/views/pages/campaign.submissions.list.page'),
	loading: GenericLoader
});

export const CampaignSubmissionInfoPage = delegate({
	loader: () => import(/* webpackChunkName: "campaign-submission-info-page" */ '@lms/views/pages/campaign.submission.info.page'),
	loading: GenericLoader
});

export const OfficeInfoPage = delegate({
	loader: () => import(/* webpackChunkName: "office-info-page" */ '@lms/views/pages/office.info.page'),
	loading: GenericLoader
});

export const ImportPage = delegate({
	loader: () => import(/* webpackChunkName: "import-page" */ '@lms/views/pages/import.page'),
	loading: GenericLoader
});

export const ImportInfoPage = delegate({
	loader: () => import(/* webpackChunkName: "import-list-page" */ '@lms/views/pages/import.info.page'),
	loading: GenericLoader
});

export const LeadEntryPage = delegate({
	loader: () => import(/* webpackChunkName: "lead-addition-page" */ '@lms/views/pages/lead.entry.page'),
	loading: GenericLoader
});

export const UserListPage = delegate({
	loader: () => import(/* webpackChunkName: "user-list-page" */ '@lms/views/pages/user.list.page'),
	loading: GenericLoader
});

export const UserDetailsPage = delegate({
	loader: () => import(/* webpackChunkName: "user-details-page" */ '@lms/views/pages/user.details.page'),
	loading: GenericLoader
});

export const UserAdditionPage = delegate({
	loader: () => import(/* webpackChunkName: "user-addition-page" */ '@lms/views/pages/user.addition.page'),
	loading: GenericLoader
});

export const DashboardPage = delegate({
	loader: () => import(/* webpackChunkName: "dashboard-page" */ '@lms/views/pages/dashboard.page'),
	loading: GenericLoader
});

export const InternalToolsPage = delegate({
	loader: () => import(/* webpackChunkName: "internal-tools-page" */ '@lms/views/pages/internal.tools.page'),
	loading: GenericLoader
});

export const OfficesListPage = delegate({
	loader: () => import(/* webpackChunkName: "office-list-page" */ '@lms/views/pages/office.list.page'),
	loading: GenericLoader
});

export const ForgotPasswordPage = delegate({
	loader: () => import(/* webpackChunkName: "forgot-password-page" */ '@lms/views/pages/forgot.password.page'),
	loading: GenericLoader
});

export const ResetPasswordPage = delegate({
	loader: () => import(/* webpackChunkName: "forgot-password-page" */ '@lms/views/pages/reset.password.page'),
	loading: GenericLoader
});

export const ReleaseNotesListPage = delegate({
	loader: () => import(/* webpackChunkName: "release-notes-list-page" */ '@lms/views/pages/release.notes.list.page'),
	loading: GenericLoader
});

export const ReleaseNoteInfoPage = delegate({
	loader: () => import(/* webpackChunkName: "release-note-info-page" */ '@lms/views/pages/release.note.info.page'),
	loading: GenericLoader
});
