export enum ISystemNotificationSkin {
	AFFIRMATIVE = 'affirmative',
	WARNING = 'warning',
	NEGATIVE = 'negative'
}

export interface ISystemNotificationState {
	isVisible: boolean;

	skin: ISystemNotificationSkin;
	message: string;
}

export const initialState: ISystemNotificationState = {
	isVisible: false,
	skin: null,
	message: null
};
