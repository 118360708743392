import * as React from 'react';
import { connect } from 'react-redux';

import {
	IAuthenticationPhase,
	acceptAuthenticationCode,
	authenticate
} from '@lms/redux/actions/auth.actions';

import { IApplicationState } from '@lms/utils/state/application.state';
import { getQueryParams } from '@lms/utils/query.utils';

import AuthLoader from '@lms/views/elements/loaders/auth.loader';

interface IStateProps {
	isAuthenticated: boolean;
}

interface IDispatchProps {
	acceptCode: (code: string) => void;
	failAuthentication: () => void;
}

interface IOwnProps {
	path: string;

	exact?: boolean;
	location?: {
		search: string;
	};
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

/**
 * Injects an `isAuthenticated` boolean value into
 * the component from the application user state
 *
 * @param {IApplicationState} state
 * @returns {IStateProps}
 */
function mapStateToProps(state: IApplicationState): IStateProps {
	return {
		isAuthenticated: state.user.isAuthenticated
	};
}

/**
 * Injects:
 *
 * 1. A method to dispatch `acceptAuthorization` thunk to
 * request an API token with the authorization code
 *
 * 2. A method to dispatch `push` from react-router to redirect
 * the user to the homepage if they're already authenticated
 *
 * @param {*} dispatch
 * @param {IProps} ownProps
 * @returns {IDispatchProps}
 */
function mapDispatchToProps(dispatch: any): IDispatchProps {
	return {
		acceptCode: (code: string) => dispatch(acceptAuthenticationCode(code)),
		failAuthentication: () => dispatch(authenticate({ phase: IAuthenticationPhase.FAIL }))
	};
}

class AcceptAuthorizationRoute extends React.Component<IProps, any> {
	public UNSAFE_componentWillMount(): void {
		const queryString = this.props.location.search;

		/* Empty query string would not provide us with the required `code` parameter */
		if (queryString === '') {
			this.props.failAuthentication();
			return;
		}

		const queryParameters = getQueryParams(queryString);
		this.props.acceptCode(queryParameters.code);
	}

	public render(): React.ReactNode {
		return (
			<div>
				<AuthLoader />
			</div>
		);
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps
)(AcceptAuthorizationRoute);
