const locale = 'en-US';
const usRegexp = /(\d+).(\d+).(\d+),?\s+(\d+).(\d+)(.(\d+))?/;

const formatOptions = {
	timeZone: 'UTC',
	hour12: false,
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric'
};

const utcFormatter = new Intl.DateTimeFormat(locale, formatOptions);

function getDateParts(date: string): number[] {
	const sanitizedDate = date.replace(/[\u200E\u200F]/g, ''); // romoves left-to-right special symbols
	const parts = usRegexp.exec(sanitizedDate);
	return parts.slice(1).map((part) => parseInt(part, 10));

}

function diffMinutes(dateOneParts: number[], dateTwoParts: number[]) {
	let day = dateOneParts[1] - dateTwoParts[1];
	const hour = dateOneParts[3] - dateTwoParts[3];
	const min = dateOneParts[4] - dateTwoParts[4];

	if (day > 15) { day = -1; }
	if (day < -15) { day = 1; }

	return 60 * (24 * day + hour) + min;
}

/**
 * @function
 *
 * @export
 * @param {string} timeZone
 * @param {Date} date
 * @returns
 */
export function getTimezoneOffset(timeZone: string, date: Date) {
	const localeTimezoneOptions = { ...formatOptions, timeZone };
	const localeFormatter = new Intl.DateTimeFormat(locale, localeTimezoneOptions);

	return -diffMinutes(
		getDateParts(utcFormatter.format(date)),
		getDateParts(localeFormatter.format(date))
	);
}
