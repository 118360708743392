declare let APP_DATA: any;

export interface IAnalyticsConfiguration {
	gaEnabled: boolean;
	gaTrackingId: string;
	logRocketEnabled: boolean;
	logRocketId: string;
	measurementId: string;
}

export const config: IAnalyticsConfiguration = {
	...APP_DATA.analytics
} as IAnalyticsConfiguration;
