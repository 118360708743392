import * as React from 'react';
import SidebarNavigation from './navigation';
import LeadLabels from './lead.labels';
import {observer} from 'mobx-react';
import ScrollBar from '@lms/views/components/scroll.bar';
import Modal from '@lms/views/components/modal';
import {ILabelModel} from '@lms/models/label.model';
import LabelForm from '@lms/views/components/forms/label.form';
import applicationStore from '@lms/stores/application.store';
import ReleaseNotesItem from './release.notes';

const Sidebar: React.FC = observer(() => {
	const [scrollBarRef, setScrollBarRef] = React.useState(null);
	const [isCollapsed, setIsCollapsed] = React.useState(applicationStore.isSidebarCollapsed);
	const versionNumber = process.env.PACKAGE_VERSION;


	// Label modals
	const [showLabelModal, setShowLabelModal] = React.useState(false);
	const [activeLabel, setActiveLabel] = React.useState(null);

	const handleCollapseClick = () => {
		applicationStore.toggleSidebar();
	};

	const handleSizeChange = () => {
		scrollBarRef.update();
	};

	const handleNavClick = () => {
		if (window.innerWidth < 993) {
			handleCollapseClick();
		}
	};

	React.useEffect(() => {
		setIsCollapsed(applicationStore.isSidebarCollapsed);
	}, [applicationStore.isSidebarCollapsed]);

	return (
		<>
			<div
				className="sidebar"
				data-test="sidebar"
				data-collapsed={isCollapsed}
			>
				<ScrollBar
					onInit={(ref: any) => {
						setScrollBarRef(ref);
					}}
				>
					<aside className="sidebar-body">

						<div className="sidebar-header">
							<i className="fal fa-long-arrow-left mobile-close" onClick={handleCollapseClick}></i>
							<img src="/logo-icon.svg" className="main-logo" height={45} onClick={handleCollapseClick}/>
							<div className="main-title">Lead Management System</div>
						</div>

						<nav className="sidebar-menu">
							<SidebarNavigation
								onClick={handleNavClick}
							/>
						</nav>

						<nav className="sidebar-labels">
							<LeadLabels
								onSizeChange={handleSizeChange}
								onClickNewLabel={() => setShowLabelModal(true)}
								onClickUpdate={(label: ILabelModel) => setActiveLabel(label)}
							/>
						</nav>
						<nav className="sidebar-release-notes">
							<ReleaseNotesItem
								name="labels.navigation.releaseNotes"
								path="/release-notes"
								versionNumber={versionNumber}
								onClick={handleNavClick}
							/>
						</nav>
					</aside>
				</ScrollBar>
			</div>
			{
				(showLabelModal || activeLabel) && (
					<Modal
						onClose={() => {
							setShowLabelModal(false);
							setActiveLabel(null);
						}}
						size={'small'}
					>
						<LabelForm
							onClose={() => {
								setShowLabelModal(false);
								setActiveLabel(null);
							}}
							label={activeLabel}
						/>
					</Modal>
				)
			}
		</>
	);
});

export default Sidebar;
