import * as React from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import * as styles from './styles.scss';
import { IButtonSkin, Button } from '@lms/views/elements/button';
import { useForm } from 'react-hook-form';
import { postLabel, patchLabel } from '@lms/services/lead.labels.service';
import applicationMetadataStore from '@lms/features/application.metadata/store';
import { ILabelModel } from '@lms/models/label.model';

interface IProps {
	label?: ILabelModel;
	onClose?: () => void;
}

const LabelForm: React.FC<IProps> = observer((props: IProps) => {
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const selectors = styles as any;
	const isNewRecord = !props.label;
	const { handleSubmit, register, errors } = useForm({
		defaultValues: isNewRecord ? {
			color: '4'
		} : props.label
	});

	const handleFormSubmit = async (values: any) => {
		setIsSubmitting(true);

		if (isNewRecord) {
			const newLabel = await postLabel(values);
			applicationMetadataStore.addLabel(newLabel);
		} else {
			const updatedLabel = await patchLabel(props.label.id, values);
			applicationMetadataStore.updateLabel(updatedLabel);
		}

		setIsSubmitting(false);
		props.onClose();
	};

	return (
		<>
			<div className="modal-body">
				<h2 className="sm-margin">
					{isNewRecord ? 'New Label' : 'Update Label'}
				</h2>
				{
					isNewRecord &&
					<p>New label will only be visible to you and no other users will have access to this label.</p>
				}

				<form
					data-test="label-form"
					onSubmit={handleSubmit(handleFormSubmit)}
					className={selectors.form}
				>
					<div
						className="input-basic"
						data-error={_.get(errors, 'name.message', false)}
						data-test="label-name"
					>
						<label htmlFor="name">Label Title</label>
						<input
							type="text"
							id="name"
							name="name"
							ref={register({
								required: 'Label title cannot be empty'
							})}
						/>
					</div>

					<div
						className="input-basic"
						data-error={_.get(errors, 'color.message', false)}
						data-test="label-color"
					>
						<label>Label Color</label>
						<div className={selectors.colors}>
							{
								_.map(_.range(1, 7), (i: number) => {
									return (
										<div key={i}>
											<label htmlFor={`color-${i}`} data-color={i}></label>
											<input
												type="radio"
												id={`color-${i}`}
												name="color"
												value={i}
												ref={register({
													required: 'Please select a color'
												})}
											/>
											<span></span>
										</div>
									);
								})
							}
						</div>
					</div>

					<div className="modal-actions">
						<Button
							size={'md'}
							type={'submit'}
							disabled={isSubmitting}
						>
							{
								isNewRecord
									? (!isSubmitting ? 'Create' : 'Creating...')
									: (!isSubmitting ? 'Save' : 'Saving...')
							}
						</Button>
						<Button
							skin={IButtonSkin.ACTION}
							outlined
							size={'md'}
							type={'button'}
							onClick={props.onClose}
						>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		</>
	);
});

export default LabelForm;
