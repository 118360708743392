import * as React from "react";
import FeedbackForm from "@lms/views/components/forms/feedback.form";
import Modal from "@lms/views/components/modal";
import Feedback from "@harbortouch/lms-models/lib/feedback.model";
import {createFeedback} from "@lms/services/feedback.service";
import {Button, IButtonSkin} from "@lms/views/elements/button";

interface IProps {
	onClose: () => void;
}

const FeedbackModal: React.FC<IProps> = (props) => {
	const { onClose } = props;
	const [isSubmitted, setSubmitted] = React.useState(false);

	const handleSubmit = async (formValues: Partial<Feedback>) => {
		await createFeedback(formValues);
		setSubmitted(true);
	};
	return (
		<Modal
			onClose={onClose}
			size="small"
		>
			<div className="modal-body">
				{!isSubmitted ? (
					<>
						<h2>Feedback Form</h2>
						<FeedbackForm
							onClose={onClose}
							onSubmit={handleSubmit}
						/>
					</>
				) : (
					<>
						<h2>Thank You!</h2>
						<p>Development team has been notified and someone will look into this shortly.</p>
						<div className="modal-actions">
							<Button
								skin={IButtonSkin.DANGER}
								size={'md'}
								onClick={onClose}
							>
								Continue
							</Button>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default FeedbackModal;
