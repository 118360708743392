import { IOfficeRecord } from '@lms/typings/records/office.record';
import * as apiClient from '@lms/services/clients/lms.api.client';
import * as queryBuilder from 'objection-find-query-builder';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

export function getOfficesOfUser(
	userId?: number,
	searchParams?: object
): Promise<{ total: number; results: IOfficeRecord[] }> {
	return apiClient.get(`/users/${userId}/offices`, searchParams);
}

export function getOffice(
	userId: number,
	officeCode: string,
	searchParams?: object
): Promise<IOfficeRecord> {
	return apiClient.get(`/users/${userId}/offices/${officeCode}`, searchParams);
}

export function getOfficesOfLead(
	leadId?: number,
	searchParams?: object
): Promise<{ total: number; results: IOfficeRecord[] }> {
	return apiClient.get(`/leads/${leadId}/offices`, searchParams);
}

export function searchOfficesOfUser(query: string, limit?: number): Promise<{ results: IOfficeRecord[] }> {
	const builder = queryBuilder
		.builder()
		.rangeStart(0)
		.rangeEnd(limit ? limit - 1 : 19)
		.anyLikeLower([
			'code',
			'name'
		], `%${query}%`);

	return getOfficesOfUser(getStoreProxy().state.user.metadata.id, builder.build());
}

export function updateOffice(
	userId: number,
	officeCode: string,
	payload: object,
	params?: object
): Promise<IOfficeRecord> {
	return apiClient.patchJson(`/users/${userId}/offices/${officeCode}`, payload, params);
}
