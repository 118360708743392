import { MiddlewareAPI, Dispatch } from 'redux';
import { IAction } from '@harbortouch/react-modules';
import { IApplicationState } from '@lms/utils/state/application.state';
import { persistFive9Lead, getFive9Lead } from '@lms/utils/storage/five9.storage';
import { navigate } from '@lms/redux/actions/navigation.actions';

import {
	AUTH_ACTION_NAMES,
	IAuthenticatePayload,
	IAuthenticationPhase
} from '@lms/redux/actions/auth.actions';

import {
	ACTION_NAMES as FIVE9_ACTION_NAMES,
	IUploadFive9LeadPayload,
	uploadFive9Lead,
	IUploadPhase
} from '@lms/redux/actions/five9.actions';

export default
(store: MiddlewareAPI<IApplicationState>) =>
	(next: Dispatch<IApplicationState>) =>
		(action: IAction<any>) => {
			switch (action.type) {
				case FIVE9_ACTION_NAMES.UPLOAD_LEAD: {
					const { phase, data } = action.payload as IUploadFive9LeadPayload;

					if (phase === IUploadPhase.FINISH) {
						persistFive9Lead(null);
						store.dispatch(navigate(`/leads/${data.postedLead.id}`) as any);
					}

					break;
				}

				case AUTH_ACTION_NAMES.AUTHENTICATE: {
					const { phase } = action.payload as IAuthenticatePayload;

					if (phase === IAuthenticationPhase.FINISH) {
						const five9Lead = getFive9Lead();

						if (five9Lead) {
							store.dispatch(uploadFive9Lead(five9Lead) as any);
						}
					}

					break;
				}
			}

			return next(action);
		};
