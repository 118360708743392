import * as _ from 'lodash';
import { IActionHandler, handleActions } from '@harbortouch/react-modules';

import { IMetadataState, initialState } from '@lms/utils/state/metadata.state';
import { IRequestStatus } from '@lms/redux/actions/common/request.status.enum';

import {
	ACTION_NAMES,
	IApplicationInitializationPhase,
	IFetchAvailableBrandsPayload,
	IInitializeApplicationPayload
} from '@lms/redux/actions/metadata.actions';

/**
 * @function
 * @param {IMetadataState} state
 * @param {IAction<IInitializeApplicationPayload>} action
 * @returns {IMetadataState}
 */
const handleInitializeApplication: IActionHandler<IMetadataState, IInitializeApplicationPayload> =
	(state, action) => {
		const { phase } = action.payload;

		switch (phase) {
			case IApplicationInitializationPhase.FINISH: {
				return {
					...state,
					applicationFinishedLoading: true
				};
			}

			default: return state;
		}
	};

/**
 * @function
 * @param {IMetadataState} state
 * @param {IAction<IFetchAvailableBrandsPayload>} action
 * @returns {IMetadataState}
 */
const handleFetchAvailableBrands: IActionHandler<IMetadataState, IFetchAvailableBrandsPayload> =
	(state, action) => {
		const { status, data } = action.payload;

		switch (status) {
			case IRequestStatus.SUCCESS: {
				return {
					...state,
					availableBrands: data.brands,
					brandRoleMap: data.brands
						.reduce((brands, brand) => {
							brands[brand.key] = _.keyBy(brand.roles, 'key');
							return brands;
						}, {} as any)
				};
			}

			default: return state;
		}
	};

/**
 * User reducer responsible for performing
 * user state mutations
 *
 * @function
 * @type {Reducer<IMetadataState>}
 * @returns {IMetadataState}
 */
export default handleActions<IMetadataState>({
	[ACTION_NAMES.INITIALIZE_APPLICATION]: handleInitializeApplication,
	[ACTION_NAMES.FETCH_AVAILABLE_BRANDS]: handleFetchAvailableBrands
}, initialState);
