import { makeActionCreator } from '@harbortouch/react-modules';
import { IBrandRecord } from '@lms/typings/records/brand.record';
import { IRequestStatus } from '@lms/redux/actions/common/request.status.enum';
import { getBrands } from '@lms/services/brand.service';

export enum ACTION_NAMES {
	INITIALIZE_APPLICATION = '@lms/metadata/INITIALIZE_APPLICATION',
	FETCH_AVAILABLE_BRANDS = '@lms/metadata/FETCH_AVAILABLE_BRANDS',
}

export interface IFetchAvailableBrandsPayload {
	status: IRequestStatus;
	data?: {
		brands: IBrandRecord[];
	};
}

export enum IApplicationInitializationPhase {
	LOAD_RESOURCES = 'LOAD_RESOURCES',
	FINISH = 'FINISH',
	FAIL = 'FAIL'
}

export interface IInitializeApplicationPayload {
	phase: IApplicationInitializationPhase;
}

export const initializeApplication =
	makeActionCreator<IInitializeApplicationPayload>(ACTION_NAMES.INITIALIZE_APPLICATION);

/**
 * @thunk
 * @function
 * @returns {ThunkAction}
 */
export function fetchAvailableBrands() {
	const fetchAction = makeActionCreator<IFetchAvailableBrandsPayload>(ACTION_NAMES.FETCH_AVAILABLE_BRANDS);

	return (dispatch: any) => {
		dispatch(fetchAction({ status: IRequestStatus.REQUESTING }));

		return getBrands()
			.then(({ brands }) => {
				dispatch(fetchAction({
					status: IRequestStatus.SUCCESS,
					data: { brands }
				}));
			});
	};
}
