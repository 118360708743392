import * as React from 'react';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { IUserPermission } from '@lms/utils/state/user.state';
import Authorized from '@lms/views/components/authorized';

const CreateMenu: React.FC = () => {
	const reduxStore = getStoreProxy();

	return (
		<>
			<Authorized requirePermissions={[IUserPermission.CREATE_LEADS]}>
				<div className="submenu-item" onClick={() => reduxStore.dispatch(navigate('/add-lead'))}>
					New Lead
				</div>
			</Authorized>

			<Authorized requirePermissions={[IUserPermission.CREATE_USERS]}>
				<div className="submenu-item" onClick={() => reduxStore.dispatch(navigate('/add-user'))}>
					New User
				</div>
			</Authorized>
		</>
	);
};

export default CreateMenu;
