import * as React from 'react';

import { observer } from 'mobx-react';
import Icon from '@lms/views/elements/icon-legacy';

import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';
import * as styles from './styles.scss';

// TODO rewrite with Provider when redux is removed
import systemNotificationStore from '@lms/features/system.notifications/store';

const SystemNotification: React.SFC<any> = observer<React.SFC>(() => {
	const selectors = styles as any;
	const notification = systemNotificationStore.lastIn;

	if (!notification) {
		return null;
	}

	return (
		<div
			className={`${selectors['system-notification']}`}
			data-skin={notification.skin}
		>
			{
				(systemNotificationStore.count > 1) && (
					<span className={`${selectors['system-notification__count']}`}>
						({systemNotificationStore.count})
					</span>
				)
			}

			{
				notification.skin === ISystemNotificationSkin.AFFIRMATIVE && (
					<Icon name="check circle" />
				)
			}

			{
				notification.skin === ISystemNotificationSkin.NEGATIVE && (
					<Icon name="warning sign" />
				)
			}

			<span>{notification.message}</span>
		</div>
	);
});

export default SystemNotification;
