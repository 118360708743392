import * as _ from 'lodash';
import * as NotificationType from '@harbortouch/lms-enums/lib/enums/notification.type.enum';
import { navigateToSelectedLead } from '@lms/models/lead.model/utils';
import { INotificationRecord } from '@lms/typings/records/notification.record.d';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

export const notificationTypes = {
	[(NotificationType as any).LEAD_ASSIGNED]: 'lead',
	[(NotificationType as any).LEAD_ASSIGNED_SECONDARY]: 'lead',
	[(NotificationType as any).LEAD_STATUS_CHANGED]: 'lead',
	[(NotificationType as any).LEAD_UNASSIGNED]: 'lead',
	[(NotificationType as any).LEAD_UNASSIGNED_SECONDARY]: 'lead',
	[(NotificationType as any).LEAD_TYPE_CHANGED]: 'lead',
	[(NotificationType as any).LEAD_DELETED]: 'lead',
	[(NotificationType as any).ATTACHMENT_ADDED]: 'lead',
	[(NotificationType as any).COMMENT_ADDED]: 'lead',
	[(NotificationType as any).APPOINTMENT_UPCOMING_ASSIGNEE]: 'lead',
	[(NotificationType as any).APPOINTMENT_NEEDS_UPDATE_ASSIGNEE]: 'lead',
	[(NotificationType as any).ENTITY_CREATED]: 'lead',
	[(NotificationType as any).USER_DELETED_MANAGER]: 'default',
	[(NotificationType as any).CAMPAIGN_SUBMISSION_CREATED]: 'submission',
	[(NotificationType as any).CAMPAIGN_SUBMISSION_STATUS_CHANGED]: 'submission'
};

export const notificationRequestTypes = _.keys(notificationTypes);

const notificationClickActions = {
	lead: (item: INotificationRecord) => {
		let id = _.get(item, 'parameters.entityId', false);
		if (!id) {
			id = _.get(item, 'parameters.lead.entityId', undefined);
		}
		navigateToSelectedLead(id);
	},
	submission: (item: INotificationRecord) => {
		const id = _.get(item, 'parameters.submission.id');
		getStoreProxy().dispatch(navigate(`/campaign-submissions/${id}`));
	},
	default: (): void => {
		return null;
	}
};

export const executeNotificationClickAction = (item: INotificationRecord) => {
	const entityType = _.get(notificationTypes, item.type, 'default');
	const action = _.get(notificationClickActions, entityType);
	action(item);
};
