import { eventBus } from 'mobx-event-bus2';
import { push } from 'connected-react-router';
import { makeActionCreator } from '@harbortouch/react-modules';

import { IApplicationState } from '@lms/utils/state/application.state';

export enum NAVIGATION_ACTION_NAMES {
	NAVIGATE_PAGE = '@lms/navigation/NAVIGATE_PAGE',
}

export interface INavigatePayload {
	path: string;
}

/**
 * Must be used for any routing/navigation changes
 *
 * @thunk
 * @function
 * @param {string} nextPath
 * @returns {ThunkAction}
 */
export function navigate(nextPath: string) {
	const navigateAction = makeActionCreator<INavigatePayload>(NAVIGATION_ACTION_NAMES.NAVIGATE_PAGE);

	return (dispatch: any, getState: any) => {
		const state = getState() as IApplicationState;

		if (state.router.location && state.router.location.pathname === nextPath) {
			return;
		}

		dispatch(navigateAction({ path: nextPath }));
		dispatch(push(nextPath));

		eventBus.post('navigation.toPage', { path: nextPath });
	};
}
