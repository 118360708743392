import * as superAgentPrefix from 'superagent-prefix';
import * as superAgentAuthBearer from 'superagent-auth-bearer';
import * as request from 'superagent';
(superAgentAuthBearer as any)(request);

import * as errorCodeEnum from '@harbortouch/lms-enums/lib/enums/error.code.enum';

import * as tokenService from '@lms/services/token.service';
import { config as apiConfig } from '@lms/config/api.config';

import axios, { Canceler } from 'axios';
import { CancellationController } from './cancellation.client';

const AUTHENTICATION_REVOKED_ERROR = (errorCodeEnum as any).AUTHENTICATION_REVOKED;
const baseUrl = superAgentPrefix(apiConfig.lms.baseUrl);

interface IAuthBearerRequest extends request.SuperAgentRequest {
	authBearer: (token: string) => void;
}

export type ICancellableRequest = [Promise<any>, Canceler];

type IMediator = () => void;

/**
 * Mediator function for deauthenticating user when
 * their authorization token has expired
 *
 * @function
 * @type {null}
 * @private
 */
let _deauthenticate: IMediator | null = null;
export function setDeauthenticationMediator(mediator: IMediator) {
	_deauthenticate = mediator;
}

/**
 * Wraps the request promise with then/catch clauses for
 * application functionality compatibility
 *
 * @function
 * @param {Promise<any>} requestPromise
 * @returns {Promise<any>}
 * @private
 */
function _wrapRequestPromise(requestPromise: Promise<any>) {
	return requestPromise
		.then((response) => {
			return response.body;
		})
		.catch((error) => {
			const { code } = error.response.body;

			if (code === AUTHENTICATION_REVOKED_ERROR) {
				_deauthenticate();
				return;
			}

			throw error;
		});
}

/**
 * Wraps the request promise with then/catch clauses for
 * application functionality compatibility
 *
 * @function
 * @param {Promise<any>} requestPromise
 * @returns {Promise<any>}
 * @private
 */
function _wrapAxiosRequestPromise(requestPromise: Promise<any>) {
	return requestPromise
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			const code = error.response ? error.response.code : false;

			if (code === AUTHENTICATION_REVOKED_ERROR) {
				_deauthenticate();
				return;
			}

			throw error;
		});
}

/**
 * @private
 * @type {string}
 */
let _authToken: string | null = null;

/**
 * @function
 * @param {string} accesToken
 */
export function setAccessToken(accesToken: string) {
	_authToken = accesToken;
}

export function cancellableGet(endpoint: string, queryParams?: object, ignoreTokenCheck = false): ICancellableRequest {
	const controller = new CancellationController();

	if (!ignoreTokenCheck && !checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const config = {
		cancelToken: controller.getToken(),
		params: {
			...queryParams
		},
		headers: {}
	};

	if (_authToken) {
		config.headers = { Authorization: `Bearer ${_authToken}` };
	}

	const requestBuilder = axios
		.get(`${apiConfig.lms.baseUrl}${endpoint}`, config );

	return [_wrapAxiosRequestPromise(requestBuilder), controller.abortPendingRequest];
}

export function cancellableFormPost(
	endpoint: string,
	formData: FormData,
	onUploadProgress?: (progressEvent: any) => void
): ICancellableRequest {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const controller = new CancellationController();
	const config = {
		cancelToken: controller.getToken(),
		headers: {}
	};

	if (_authToken) {
		config.headers = {
			'Authorization': `Bearer ${_authToken}`,
			'Content-Type': 'multipart/form-data'
		};
	}

	if (onUploadProgress) {
		// @ts-ignore
		config.onUploadProgress = onUploadProgress;
	}

	const requestBuilder = axios
		.post(`${apiConfig.lms.baseUrl}${endpoint}`, formData, config );

	return [_wrapAxiosRequestPromise(requestBuilder), controller.abortPendingRequest];
}

/**
 * Makes a GET request to the specified LMS API endpoint with the
 * specified query parameters
 *
 * @async
 * @function
 * @param {string} endpoint
 * @param {object} queryParams
 * @param {boolean} ignoreTokenCheck
 * @returns {Promise<request.Response>}
 */
export function get(endpoint: string, queryParams?: object, ignoreTokenCheck = false) {
	if (!ignoreTokenCheck && !checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.get(endpoint)
		.query(queryParams)
		.use(baseUrl)
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return _wrapRequestPromise(requestBuilder);
}

/**
 * Makes a GET request with header set for spreadsheet
 *
 * @async
 * @function
 * @param {string} endpoint
 * @param {object} queryParams
 * @returns {Promise<request.Response>}
 */
export function getSpreadsheet(endpoint: string, queryParams?: object) {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.get(endpoint)
		.query(queryParams)
		.use(baseUrl)
		.responseType('blob')
		.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return requestBuilder;
}

/**
 * Makes a GET request to the specified LMS API endpoint with the
 * specified query parameters and returns a browser native Blob object
 * constructed from the response
 *
 * @async
 * @function
 * @param {string} endpoint
 * @param {object} queryParams
 * @returns {Promise<request.Response>}
 */
export function getBlob(endpoint: string, queryParams?: object) {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.get(endpoint)
		.responseType('blob')
		.query(queryParams)
		.use(baseUrl)
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return _wrapRequestPromise(requestBuilder);
}

/**
 * Makes a POST request to the specified LMS API endpoint with
 * the specified JSON payload
 *
 * @async
 * @param {string} endpoint
 * @param {object} payload
 * @param {boolean} ignoreTokenCheck
 * @returns {Promise<request.Response>}
 */
export function postJson(endpoint: string, payload: object, ignoreTokenCheck = false) {
	if (!ignoreTokenCheck && !checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.post(endpoint)
		.send(payload)
		.use(baseUrl)
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return _wrapRequestPromise(requestBuilder);
}

export interface IAttachmentEntry {
	field: string;
	file: Blob;
}

export interface IFieldEntry {
	name: string;
	value: any;
}

export interface IHeaderEntry {
	name: string;
	value: any;
}

/**
 * Makes a POST multipart/form-data request to the specified LMS API endpoint
 * which includes the specified attachments and form fields in the payload
 *
 * @async
 * @function
 * @param {string} endpoint
 * @param {IAttachmentEntry[]} attachments
 * @param {IFieldEntry[]} fields
 * @param {object} queryParams
 * @param {IHeaderEntry[]} headers
 * @returns {Promise<Response>}
 */
export function postForm<Response>(
	endpoint: string,
	attachments?: IAttachmentEntry[],
	fields?: IFieldEntry[],
	queryParams?: object,
	headers?: IHeaderEntry[]
): Promise<Response> {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.post(endpoint)
		.query(queryParams);

	if (attachments) {
		attachments.forEach((attachmentEntry) => {
			requestBuilder.attach(attachmentEntry.field, attachmentEntry.file);
		});
	}

	if (fields) {
		fields.forEach((fieldEntry) => {
			requestBuilder.field(fieldEntry.name, fieldEntry.value);
		});
	}

	if (headers) {
		headers.forEach((headerEntry) => {
			requestBuilder.set(headerEntry.name, headerEntry.value);
		});
	}

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	requestBuilder
		.use(baseUrl)
		.withCredentials();

	return _wrapRequestPromise(requestBuilder);
}

/**
 * Makes a PATCH request to the specified LMS API endpoing which includes
 * the specified entity attribute updates in the payload
 *
 * @async
 * @function
 * @param {string} endpoint
 * @param {object} payload
 * @param {object} queryParams
 * @returns {Promise<request.Response>}
 */
export function patchJson(endpoint: string, payload: object, queryParams?: object) {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.patch(endpoint)
		.query(queryParams)
		.send(payload)
		.use(baseUrl)
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return _wrapRequestPromise(requestBuilder);
}

/**
 * Makes a DELETE request to the specified LMS API endpoint
 *
 * @async
 * @function
 * @param {string} endpoint
 * @returns {Promise<request.Response>}
 */
export function deleteResource(endpoint: string) {
	if (!checkIsTokenValidAndBump()) {
		_deauthenticate();
	}

	const requestBuilder = request
		.delete(endpoint)
		.use(baseUrl)
		.withCredentials();

	if (_authToken) {
		(requestBuilder as IAuthBearerRequest).authBearer(_authToken);
	}

	return _wrapRequestPromise(requestBuilder);
}

/**
 * @function
 * @returns {boolean}
 */
function checkIsTokenValidAndBump() {
	if (tokenService.isTokenValid()) {
		tokenService.bumpLastActiveTime();
		return true;
	}

	return false;
}
