import * as _ from 'lodash';
import * as queryBuilder from 'objection-find-query-builder';
import { action, runInAction } from 'mobx';

import { ILeadModel } from '@lms/models/lead.model/index';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { patchLead } from '@lms/services/lead.service';
import { sanitizeFormValue } from '@lms/utils/form.utils';
import { isEmptyAddress } from '@lms/utils/address.utils';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import leadInfoStore from '@lms/features/lead.bookkeeping/lead.info/stores/lead.info.store';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { EventModel } from '@lms/models/event.model';

/**
 * Builds the default API public search query to be used for
 * retrieving a lead record
 *
 * @function
 * @returns {object} - Query parameter object
 */
export function buildDefaultLeadRecordQuery() {
	const builder = queryBuilder.builder();
	return builder
		.eager([
			'leadAttachments',
			'leadComments',
			'assignee',
			'assigneeSecondary',
			'customFields',
			'createdBy',
			'primaryContacts',
			'ownerContacts',
			'address',
			'legalAddress',
			'office',
			'labels',
			'appointments',
			'reminders',
			'promoType',
			'enterpriseExecAssignee',
			'fullActionHistory.executor'
		]).build();
}

/**
 * @function
 * @param {ILeadRecord} json
 * @returns {ILeadRecord}
 */
export function getFailsafeLeadRecordFromJSON(json: ILeadRecord): object {
	const record = { ...json };

	if (json.appointments) {
		// @ts-ignore
		record.appointments = json.appointments.map((appointment) => new EventModel(appointment));
	}

	if (json.reminders) {
		// @ts-ignore
		record.reminders = json.reminders.map((event) => new EventModel(event));
	}

	if (!json.address) {
		record.address = {
			city: null,
			street: null,
			zip: null,
			state: null
		};
	}

	if (!json.legalAddress) {
		record.legalAddress = {
			city: null,
			street: null,
			zip: null,
			state: null
		};
	}

	if (!json.primaryContacts) {
		record.primaryContacts = {};
	}

	if (!json.ownerContacts) {
		record.ownerContacts = {};
	}

	return record;
}

/**
 * Updates the specified Lead model attribute for a given instance
 * of a LeadModel and requests a PATCH operation on the associated record
 * in the backend
 *
 * @function
 * @async
 * @param {ILeadModel} lead
 * @param {string} attrName
 * @param {any} newValue
 * @returns {Promise<void>}
 */
export const patchLeadModelAttribute = action(async (
	lead: ILeadModel,
	attrName: keyof ILeadRecord,
	newValue: any
) => {
	const previousValue = _.get(lead, attrName);
	if (previousValue === null && newValue === '') {
		// empty strings are set to null when parsing data in the backend,
		// so they are considered to be equal here
		return;
	}
	if (_.isEqual(previousValue, newValue)) {
		return;
	}
	_.set(lead, attrName, newValue);

	try {
		const patchObject = _.set({}, attrName, sanitizeFormValue(newValue));

		const patchedLeadRecord = await patchLead(
			lead.id,
			patchObject,
			buildDefaultLeadRecordQuery()
		);

		runInAction(() => {
			_.assign(lead, getFailsafeLeadRecordFromJSON(patchedLeadRecord));
		});
	} catch (error) {
		runInAction(() => {
			_.set(lead, attrName, previousValue);
		});

		throw error;
	}
});

/**
 * @async
 * @function
 */
export const patchLeadModelAttributes = action(async (lead: ILeadModel, patch: object) => {
	const previousValues = _.pick(lead, _.keys(patch));
	if (_.isEqual(previousValues, patch)) {
		return;
	}
	_.merge(lead, patch);

	try {
		const patchObject = _.cloneDeepWith(patch, sanitizeFormValue);
		const patchedLeadRecord = await patchLead(lead.id, patchObject, buildDefaultLeadRecordQuery());

		runInAction(() => {
			_.assign(lead, getFailsafeLeadRecordFromJSON(patchedLeadRecord));
		});
	} catch (error) {
		runInAction(() => {
			_.merge(lead, previousValues);
		});

		throw error;
	}
});

/**
 * @function
 *
 * @export
 * @param {ILeadModel} lead
 * @returns boolean
 */
export function doesLeadHaveLegalAddress(lead: ILeadModel) {
	return isEmptyAddress(lead.legalAddress);
}

/**
 * Toggles the specified Lead model attribute for a given instance
 * of a LeadModel and requests a PATCH operation on the associated record
 * in the backend
 *
 * @function
 * @async
 * @param {ILeadModel} lead
 * @param {string} attrName
 * @returns {Promise<void>}
 */
export const toggleLeadModelAttribute = action(async (
	lead: ILeadModel,
	attrName: keyof ILeadRecord
) => {
	const previousValue = _.get(lead, attrName);
	_.set(lead, attrName, !previousValue);

	try {
		const patchObject = _.set({}, attrName, !previousValue);

		const patchedLeadRecord = await patchLead(
			lead.id,
			patchObject,
			buildDefaultLeadRecordQuery()
		);

		runInAction(() => {
			_.assign(lead, patchedLeadRecord);
		});
	} catch (error) {
		runInAction(() => {
			_.set(lead, attrName, previousValue);
		});

		throw error;
	}
});

export function navigateToSelectedLead(id?: number) {
	if (!id) {
		return;
	}
	const reduxStore = getStoreProxy();
	leadInfoStore.fetchLead(id);
	reduxStore.dispatch(navigate(`/leads/${id}`));
}
