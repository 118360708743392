import { config as analyticsConfig } from '@lms/config/analytics.config';
import { subscribe, eventBus, Event } from 'mobx-event-bus2';
import { ITransport } from '@lms/utils/analytics/transport/interfaces/transport';
import { IAnalyticsEvent } from '@lms/utils/analytics/events/analytics.event';
import { IAnalyticsProvider } from '@lms/utils/analytics/analytics.provider';
import { GaTransport } from '@lms/utils/analytics/transport/ga.transport';
import { Ga4Transport } from '@lms/utils/analytics/transport/ga4.transport';
import { LogRocketTransport } from '@lms/utils/analytics/transport/logrocket.transport';

import { EngagementTracker } from '@lms/utils/analytics/trackers/engagement.tracker';
import { LeadsTracker } from '@lms/utils/analytics/trackers/leads.tracker';

export class Analytics implements IAnalyticsProvider {
	private trackers: any[];
	private transports: ITransport[];

	constructor() {
		this.transports = [];
		this.trackers = [];
		eventBus.register(this);
	}

	/**
	 * @function
	 */
	public initialize = () => {
		if (analyticsConfig.gaEnabled) {
			this.addTransport(new GaTransport());
			this.addTransport(new Ga4Transport());
		}

		if (analyticsConfig.logRocketEnabled) {
			this.addTransport(new LogRocketTransport());
		}

		/* TODO think about this */
		this.addTracker(new EngagementTracker(this));
		this.addTracker(new LeadsTracker(this));
	};

	/**
	 * @function
	 * @param tracker
	 */
	public addTracker = (tracker: any) => {
		this.trackers.push(tracker);
	};

	/**
	 * @function
	 * @param {ITransport} transport
	 * @returns {Promise<void>}
	 */
	public addTransport = async (transport: any) => {
		if (!transport.initialized) {
			await transport.initialize();
		}

		this.transports.push(transport);
	};

	/**
	 * @function
	 * @param {IAnalyticsEvent} event
	 */
	public pushEvent = (event: IAnalyticsEvent) => {
		this.transports.forEach((transport) => {
			transport.push(event);
		});
	};

	@subscribe('navigation.toPage')
	private pushPageView(event: Event<{ path: string }>) {
		const { path } = event.payload;
		this.transports.forEach((transport) => {
			transport.pushPageView(path);
		});
	}
}

const analytics = new Analytics();
export default analytics;
