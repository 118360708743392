import * as React from 'react';
const PerfectScrollbar = require('perfect-scrollbar');

interface IProps {
	children: React.ReactElement;
	onInit?: (scrollBar: any) => void;
	options?: any;
	light?: boolean;
	containerRef?: any;
}

const ScrollBar: React.FC<IProps> = (props: IProps) => {
	const containerRef = props.containerRef || React.useRef<HTMLDivElement>(null);
	let scrollbar: any = null;

	React.useEffect(() => {
		if (containerRef) {
			scrollbar = new PerfectScrollbar(containerRef.current, props.options);
			if (props.onInit) {
				props.onInit(scrollbar);
			}
		}
		return () => {
			scrollbar.destroy();
		};
	}, []);

	return (
		<div ref={containerRef} className="scrollbar-container" data-light={!!props.light}>
			{props.children}
		</div>
	);
};

export default ScrollBar;
