import * as React from 'react';
import * as _ from 'lodash';
import { useFormContext } from 'react-hook-form';

interface IProps {
	label: string;
	type?: 'text' | 'password';
	validation?: any;
}

const InputField: React.FC<IProps> = (props: IProps) => {
	const form = useFormContext();
	const name = _.camelCase(props.label);

	const inputProps = {
		type: props.type || 'text',
		id: name,
		name
	};

	if (props.validation) {
		_.set(inputProps, 'ref', form.register(props.validation));
	}

	return (
		<div
			className="input-basic"
			data-error={_.get(form.errors, `${name}.message`, false)}
			data-test={name}
		>
			<label htmlFor={name}>{props.label}</label>
			<input {...inputProps} />
		</div>
	);
};

export default InputField;
