import * as _ from 'lodash';
import { makeActionCreator } from '@harbortouch/react-modules';

import { IApplicationState } from '@lms/utils/state/application.state';
import { IUserRecord } from '@lms/typings/records/user.record';
import { patchUser } from '@lms/services/user.service';
import { sanitizeFormValue } from '@lms/utils/form.utils';
import { buildDefaultUserRecordQuery } from '@lms/models/user.model/utils';

export enum USER_SETTINGS_ACTION_NAMES {
	LOAD_IN_UPDATED_USER = '@lms/user.settings/LOAD_IN_UPDATED_USER'
}

export interface ILoadInUpdatedUserPayload {
	user: IUserRecord;
}

export interface IUpdateAttributeValuePayload {
	attrName: string;
	value: any;
}

export const loadInAction =
	makeActionCreator<ILoadInUpdatedUserPayload>(USER_SETTINGS_ACTION_NAMES.LOAD_IN_UPDATED_USER);

export function sendAttributeUpdate(patch: IUpdateAttributeValuePayload) {
	return (dispatch: any, getState: any) => {
		const state = getState() as IApplicationState;

		const patchObject = _.set({}, patch.attrName, sanitizeFormValue(patch.value));
		const userId = state.user.metadata.id;

		return patchUser(userId, patchObject, buildDefaultUserRecordQuery())
			.then((user) => {
				dispatch(loadInAction({ user }));
			});
	};
}
