import { action, observable } from 'mobx';
import { saveSidebarCollapsedStatus, getSidebarCollapsedStatus } from '@lms/views/modules/sidebar/sidebar.service';

export class ApplicationStore {
	@observable public isSidebarCollapsed: boolean = getSidebarCollapsedStatus();

	@action public toggleSidebar = () => {
		this.isSidebarCollapsed = !this.isSidebarCollapsed;
		saveSidebarCollapsedStatus(this.isSidebarCollapsed);
		window.dispatchEvent(new Event('resize'));
	};
}

export default new ApplicationStore();
