import { action } from 'mobx';

import { ILeadModel, LeadModel } from '@lms/models/lead.model';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import {
	patchLeadModelAttribute,
	patchLeadModelAttributes
} from '@lms/models/lead.model/utils';

interface ILeadRecordPatch {
	[attribute: string]: string;
}

export interface ILeadRecordProjection extends ILeadModel {
	patchRecordAttribute: (attrName: keyof ILeadRecord, newValue: any) => Promise<void>;
	patchRecordAttributes: (patch: ILeadRecordPatch) => Promise<void>;
	toggleRecordAttribute: (attrName: keyof ILeadRecord) => Promise<void>;
}

export class LeadRecordProjection extends LeadModel implements ILeadRecordProjection {
	constructor(json: ILeadRecord) {
		super(json);
	}

	/**
	 * Public setter with side effects which allows to patch any
	 * attribute of the lead record persisted in the backend
	 *
	 * @function
	 * @param {string} attrName
	 * @param {any} newValue
	 */
	@action public patchRecordAttribute = (attrName: keyof ILeadRecord, newValue: any) => {
		return patchLeadModelAttribute(this, attrName, newValue);
	};

	/**
	 * Public setter with side effects which allows to
	 * patch a set of attributes within the record
	 * persisted in the backend
	 *
	 * @function
	 * @param {ILeadRecordPatch} patch
	 */
	@action public patchRecordAttributes = (patch: ILeadRecordPatch) => {
		return patchLeadModelAttributes(this, patch);
	};

	/**
	 * Public setter with side effects which allows to toggle
	 * and patch any attribute of the lead record persisted in
	 * the backend
	 *
	 * @function
	 * @param {string} attrName
	 */
	@action public toggleRecordAttribute = (attrName: keyof ILeadRecord) => {
		return patchLeadModelAttribute(this, attrName, !(this as any)[attrName]);
	};
}
