import * as _ from 'lodash';
import * as SoldReasons from '@harbortouch/lms-enums/lib/enums/lead.sold-reason.enum';
import { IViewReadyEntity } from '@lms/utils/selectors/metadata.selectors';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

export enum ILastActionEnum {
	NONE = (SoldReasons as any).NONE,
	FULL_ACQUIRING = (SoldReasons as any).FULL_ACQUIRING,
	GATEWAY_ONLY = (SoldReasons as any).GATEWAY_ONLY,
}

/**
 * Cache for lead status view projections
 *
 * @type {IViewReadyEntity[]}
 * @private
 */
let _viewReadyLeadSoldTypes: IViewReadyEntity[] | null = null;

/**
 * TODO refactor when redux is removed
 *
 * @function
 * @returns {IViewReadyEntity[]}
 */
export function getViewReadyLeadSoldTypes(): IViewReadyEntity[] {
	const soldTypes = _.values(SoldReasons);

	if (!_viewReadyLeadSoldTypes) {
		_viewReadyLeadSoldTypes = soldTypes.map((name: string) => {
			return {
				value: name,
				label: getDictionaryValue(`labels.leads.soldType.${name}`),
				attribute: 'soldReason'
			};
		});
	}

	return _viewReadyLeadSoldTypes;
}
