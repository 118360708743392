import * as React from 'react';
import { connect } from 'react-redux';

import { IApplicationState } from '@lms/utils/state/application.state';
import { userHasRequiredPermissions } from '@lms/utils/selectors/user.selectors';
import { IUserPermission } from '@lms/utils/state/user.state';

interface IStateProps {
	isAuthorized: boolean;
}

interface IOwnProps {
	requirePermissions: IUserPermission[];
}

type IProps = IStateProps & IOwnProps;

/**
 * Injects an `isAuthorized` flag into the component from
 * application user state
 *
 * @function
 * @param {IApplicationState} state
 * @param {IOwnProps} ownProps
 * @returns {IStateProps}
 */
function mapStateToProps(state: IApplicationState, ownProps: IOwnProps) {
	return {
		isAuthorized: userHasRequiredPermissions(state, ownProps.requirePermissions)
	};
}

const Authorized: React.SFC<IProps> = (props) => {
	if (!props.children) {
		return null;
	}

	if (props.isAuthorized) {
		return (
			<React.Fragment>
				{props.children}
			</React.Fragment>
		);
	}

	return null;
};

export default connect<IStateProps, any, any>(mapStateToProps)(Authorized);
