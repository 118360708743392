import * as apiClient from '@lms/services/clients/lms.api.client';
import { ITimezonesResponse } from '@lms/typings/responses/timezones.response';

/**
 * Retrieve time zone array from LMS API
 *
 * @function
 * @export
 * @returns {Promise<ITimezonesResponse>}
 */
export function getTimezones(): Promise<ITimezonesResponse> {
	return apiClient.get('/timezones', null);
}
