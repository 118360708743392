import { MiddlewareAPI, Dispatch } from 'redux';
import { IAction } from '@harbortouch/react-modules';

import { IApplicationState } from '@lms/utils/state/application.state';
import { IRequestStatus } from '@lms/redux/actions/common/request.status.enum';

import {
	ACTION_NAMES as METADATA_ACTION_NAMES,
	IApplicationInitializationPhase,
	fetchAvailableBrands,
	initializeApplication
} from '@lms/redux/actions/metadata.actions';

import {
	ACTION_NAMES as USER_ACTION_NAMES, fetchCurrentUserData,
	IFetchUserDataPayload,
	updateLastLoginDateAndTimezone
} from '@lms/redux/actions/user.actions';

export default
(store: MiddlewareAPI<IApplicationState>) =>
	(next: Dispatch<IApplicationState>) =>
		(action: IAction<any>) => {
			next(action);

			switch (action.type) {
				case METADATA_ACTION_NAMES.INITIALIZE_APPLICATION: {
					const { phase } = action.payload;

					switch (phase) {
					// TODO refactor to mobx

						case IApplicationInitializationPhase.LOAD_RESOURCES: {
							Promise.all([
								store.dispatch(fetchCurrentUserData() as any),
								store.dispatch(fetchAvailableBrands() as any)
							]).then(() => {
								store.dispatch(initializeApplication({
									phase: IApplicationInitializationPhase.FINISH
								}));
							});
						}
					}

					break;
				}

				case USER_ACTION_NAMES.FETCH_CURRENT_USER_DATA: {
					const { status } = action.payload as IFetchUserDataPayload;

					if (status === IRequestStatus.SUCCESS) {
						store.dispatch(updateLastLoginDateAndTimezone() as any);
					}

					break;
				}

				default: break;
			}
		};
