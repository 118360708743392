import { IBrandRecord } from '@lms/typings/records/brand.record';
import * as apiClient from '@lms/services/clients/lms.api.client';

/**
 * Asynchronously requests a list of all brands from the API
 *
 * @async
 * @function
 * @param {object} queryParams
 * @returns {Promise<IBrandRecord[]>}
 */
export function getBrands(queryParams?: object): Promise<{ brands: IBrandRecord[] }> {
	return apiClient.get('/brands', queryParams);
}
