import * as Moment from 'moment';

import { isBlankString } from '@lms/utils/string.utils';
import { config as metadataConfig } from '@lms/config/metadata.config';
import moment from 'moment-timezone';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { getTimezoneOffset } from '@lms/features/application.metadata/timezones/utils/timezones.offset.utils';

/**
 * Formats ISO string into US date format (MM/DD/YY)
 *
 * @function
 * @param {string} isoDate
 * @returns {string}
 */
export function getFormattedDateFromISO(isoDate: any): string {
	if (isBlankString(isoDate)) {
		return null;
	}

	return Moment(isoDate).format(metadataConfig.fullDateFormat);
}

export interface IDateFilterRanges {
	start: string;
	end: string;
}

/**
 * Normalizes date filter ranges to be consumable by LMS API
 * public search. Will push the start/end date to the beginning/end
 * of day if the start and end dates are equivalent
 *
 * @function
 * @param {string} start
 * @param {string} end
 * @returns {IDateFilterRanges}
 */
export function normalizeDateFilterRanges({ start, end }: IDateFilterRanges): IDateFilterRanges {
	return {
		start: Moment(new Date(start)).startOf('day').toISOString(),
		end: Moment(new Date(end)).endOf('day').toISOString()
	};
}

/**
 * Converts a millisecond duration value into days
 *
 * @function
 * @param {number} timeInSeconds
 * @param {number} precision - Integer number of fraction digits
 * @returns {number}
 * @private
 */
export function getDaysFromSeconds(timeInSeconds: number, precision = 1): number {
	if (!timeInSeconds) {
		return null;
	}

	const duration = Moment.duration(timeInSeconds, 'seconds').asDays();
	return parseFloat(duration.toFixed(precision));
}

export interface ITimeDifference {
	difference: string;
	unit: string;
}

/**
 * Returns verbose time difference string. Plural if difference is greater than one.
 *
 * @function
 * @param {ITimeDifference} {difference, unit}
 * @returns {string}
 */
function showVerboseTime({difference, unit}: ITimeDifference) {
	if (parseInt(difference,10) > 1) {
		return `${unit}s ago`;
	}

	return `${unit} ago`;
}

/**
 * Returns the time difference between the current datetime and
 * the specified datetime.
 *
 * This utility will iterate over various time difference units (e.g. minutes,
 * hours, days, weeks) and pick the highest-order time difference unit to display
 * the difference
 *
 * @function
 * @param {string} dateToCompare - ISO date string to compare the current datetime with
 * @param {boolean} verbose - Defines the output format. When false, the unit string within the
 *                            output will be shortened (e.g. "m" instead of "minutes"), when true,
 *                            output will not be shortened.
 * @returns {ITimeDifference}
 */
export function getFormattedTimeDifferenceFromNow(
	dateToCompare: string,
	verbose = false
): ITimeDifference {
	const now = Moment(new Date());
	const updatedAt = Moment(dateToCompare);

	let difference = null;
	let differenceUnit = null;

	['second', 'minute', 'hour', 'day', 'week', 'month', 'year'].map((unit: any) => {
		if (updatedAt.isBefore(now, unit)) {
			const differenceByUnit = now.diff(updatedAt, unit);

			if (differenceByUnit !== 0) {
				differenceUnit = unit;
				difference = differenceByUnit;
			}
		}
	});

	if (difference === null || differenceUnit === null) {
		return { difference, unit: differenceUnit };
	}

	return {
		difference,
		unit: verbose ? showVerboseTime({difference, unit: differenceUnit}) : (differenceUnit as any).substr(0, 1)
	};
}

export function getDayDifference(date: string): number {
	const reduxStore = getStoreProxy();

	const oneDay = 86400000; // day in miliseconds
	const userTimezone = reduxStore.state.user.metadata.timezone;

	const now = moment(new Date()).tz(userTimezone);
	const notificationDate = moment(date).tz(userTimezone);

	const difference = (now as any) - (notificationDate as any);
	const timePassedInDays = difference / oneDay;

	return timePassedInDays;
}

export function toUserTime(date: string, format: string): string {
	const reduxStore = getStoreProxy();
	const userTimezone = reduxStore.state.user.metadata.timezone;
	const newDate = moment(date).tz(userTimezone);
	return newDate.format(format);
}

enum IDateAdjustmentDirection {
	TO_LOCAL = 'toLocal',
	FROM_LOCAL = 'fromLocal'
}

function handleDateCorrection(
	selectedTimezone: string,
	datePicked: Date,
	adjustmentDirection: IDateAdjustmentDirection
) {
	const selectedOffset = getTimezoneOffset(selectedTimezone, new Date(datePicked));
	const localOffset = -new Date().getTimezoneOffset();
	const minuteDiff = localOffset - selectedOffset;
	const direction = adjustmentDirection === IDateAdjustmentDirection.TO_LOCAL ? -1 : 1;
	return new Date(datePicked.getTime() + minuteDiff * 60 * 1000 * direction);
}

export function toLocalDate(selectedTimezone: string, datePicked: Date) {
	return handleDateCorrection(selectedTimezone, datePicked, IDateAdjustmentDirection.TO_LOCAL);
}

export function fromLocalDate(selectedTimezone: string, datePicked: Date) {
	return handleDateCorrection(selectedTimezone, datePicked, IDateAdjustmentDirection.FROM_LOCAL);
}

