import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { IApplicationState } from '@lms/utils/state/application.state';
import { History } from 'history';

import userReducer from '@lms/redux/reducers/user.reducer';
import metadataReducer from '@lms/redux/reducers/metadata.reducer';
import systemNotificationReducer from '@lms/redux/reducers/system.notification.reducer';

/**
 * Root-level reducer for the whole application
 *
 * @function
 * @type {Reducer<IApplicationState>}
 * @returns {IApplicationState}
 */
const createRootReducer = (history: History) => combineReducers<IApplicationState>({
	router: connectRouter(history),
	user: userReducer,
	metadata: metadataReducer,
	systemNotification: systemNotificationReducer
});

export default createRootReducer;
