import * as React from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import LocalStore from './local.store';
import { useOnKeyPress } from '@lms/views/utils/hooks/on.key.press.hook';
import { useOnClickOutside } from '@lms/views/utils/hooks/on.click.outside.hook';
import { useWindowSize } from '@lms/views/utils/hooks/use.window.size.hook';

export interface IMenuProps {
	values: any;
	store: LocalStore;
	onChange?: (values: any, action?: any) => void;
	onInputChange: (value: string, reason: any) => void;
}

interface IProps {
	store: LocalStore;
	dropdownRef: any;
	onChange: (values: any, action: any) => void;
	menuRender: (menuProps: IMenuProps) => React.ReactElement;
}

export const DropdownMenu: React.FC<IProps> = observer((props: IProps) => {
	if (!props.store.isOpen) {
		return null;
	}

	return (
		<DropdownMenuList {...props} />
	);
});

const VIEWPORT_THRESHOLD = 90;
const MAX_INPUT_LENGTH = 35;

const DropdownMenuList: React.FC<IProps> = observer((props: IProps) => {
	useOnClickOutside(props.dropdownRef, () => props.store.setIsOpen(false));
	useOnKeyPress('Escape', () => props.store.setIsOpen(false));
	useOnKeyPress('Tab', () => props.store.setIsOpen(false));
	const windowSize = useWindowSize();

	React.useEffect(() => {
		return () => {
			props.store.setQuery('');
		};
	}, []);

	const parentBoundingRect = props.dropdownRef.current.getBoundingClientRect();
	const isParentCloseToRightEdge = windowSize.width < parentBoundingRect.right + VIEWPORT_THRESHOLD;

	const onChange = (newValues: any, action: any) => {
		if (newValues && !_.isString(newValues) && !_.isArray(newValues)) {
			newValues = [newValues];
			props.store.setIsOpen(false);
		}

		props.store.setValues(newValues);
		if (props.onChange) {
			props.onChange(newValues, action);
		}
	};

	const onInputChange = (value: string, reason: any) => {
		if (value.length > MAX_INPUT_LENGTH) {
			value = value.substr(0, MAX_INPUT_LENGTH);
		}
		if (reason.action === 'input-change') {
			props.store.setQuery(value);
		}
	};

	return (
		<div
			className={'dropdown-menu-wrapper'}
			data-right={isParentCloseToRightEdge}
		>
			{
				props.menuRender({
					values: props.store.values,
					store: props.store,
					onChange,
					onInputChange
				})
			}
		</div>
	);
});
