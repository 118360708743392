import { IAuthTokenRecord } from '@lms/typings/records/auth-token.record';
import { config as apiConfig } from '@lms/config/api.config';
import { buildQueryString, getQueryParams } from '@lms/utils/query.utils';
import { getChallenge, getCodeVerifier } from '@lms/utils/storage/auth.storage';
import * as apiClient from '@lms/services/clients/lms.api.client';

export const REDIRECT_URL = `${location.protocol}//${location.host}/accept-auth`;

/**
 * Redirects the page to the LMS API authorization
 * page with all the required OAuth 2.0 query parameters
 *
 * @function
 */
export function redirectToLoginPage() {
	const challenge = getChallenge();
	const queryParams = getQueryParams(window.location.search);
	const queryString = buildQueryString({
		response_type: 'code',
		client_id: apiConfig.lms.clientId,
		code_challenge: challenge,
		code_challenge_method: 'S256',
		redirect_uri: REDIRECT_URL,
		...(queryParams?.redirect && { redirect: queryParams.redirect })
	});

	const { lms } = apiConfig;
	window.location.replace(`${lms.baseUrl}${lms.authUri}?${queryString}`);
}

/**
 * Asynchronously requests to post the current user's authorization
 * information and authorization code to the API in return for an
 * authorization token from the API.
 *
 * @function
 * @param {string} code
 * @returns {Promise<IAuthTokenRecord>}
 */
export function postAuthorizationCode(code: string): Promise<IAuthTokenRecord> {
	const codeVerifier = getCodeVerifier();

	return apiClient.postJson(apiConfig.lms.tokenUri, {
		grant_type: 'authorization_code',
		client_id: apiConfig.lms.clientId,
		code_verifier: codeVerifier,
		code,
		redirect_uri: REDIRECT_URL
	}, true);
}
