import { subscribe, eventBus } from 'mobx-event-bus2';

import { IAnalyticsProvider } from '@lms/utils/analytics/analytics.provider';
import { LoginEvent } from '@lms/utils/analytics/events/engagement/login.event';

/* TODO: think about this */
export class EngagementTracker {
	private analytics: IAnalyticsProvider;

	constructor(analytics: IAnalyticsProvider) {
		eventBus.register(this);
		this.analytics = analytics;
	}

	@subscribe('user.authenticationSuccess')
	private handleAuthenticationSuccess() {
		this.analytics.pushEvent(new LoginEvent());
	}
}
