import { observable, action } from 'mobx';

export default class LocalStore {
	@observable public values: any;
	@observable public query: string;
	@observable public isOpen: boolean;

	constructor(values?: any) {
		this.values = values || [];
		this.query = '';
		this.isOpen = false;
	}

	@action public setValues(values: any) {
		this.values = values;
	}

	@action public setQuery(value: string) {
		this.query = value;
	}

	@action public setIsOpen(value: boolean) {
		this.isOpen = value;
	}
}
