import * as ReactGA from 'react-ga';
import { action, observable } from 'mobx';

import { ITransport } from '@lms/utils/analytics/transport/interfaces/transport';
import { IAnalyticsEvent } from '@lms/utils/analytics/events/analytics.event';
import { config as analyticsConfig } from '@lms/config/analytics.config';

export class GaTransport implements ITransport {
	public readonly name: string;
	@observable public initialized: boolean;

	constructor() {
		this.name = 'google.analytics';
		this.initialized = false;
	}

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public initialize = () => {
		ReactGA.initialize(analyticsConfig.gaTrackingId);
		this.initialized = true;
	};

	/**
	 * @function
	 * @param {IAnalyticsEvent} event
	 */
	public push = (event: IAnalyticsEvent) => {
		if (!this.initialized) {
			console.warn(`Attempt to use uninitialized transport [${this.name}], event:`, event);
			return;
		}

		ReactGA.event(event.parameters as ReactGA.EventArgs);
	};

	public pushPageView = (path: string) => {
		ReactGA.pageview(path);
	};
}
