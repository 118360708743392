import * as EventStatus from '@harbortouch/lms-enums/lib/enums/event.status.enum';

enum IEventStatus {
	SCHEDULED = (EventStatus as any).SCHEDULED,
	CONFIRMED = (EventStatus as any).CONFIRMED,
	COMPLETED = (EventStatus as any).COMPLETED,
	CANCELLED = (EventStatus as any).CANCELLED,
}

export default IEventStatus;
