import * as queryBuilder from 'objection-find-query-builder';
import * as _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { createContext } from 'react';
import { getUserNotifications, markNotificationRead, markAllNotificationsRead } from './notifications.service';
import { INotificationRecord } from '@lms/typings/records/notification.record';
import { notificationRequestTypes } from './utils';

export class NotificationsStore {
	@observable private itemsOffset = 0;
	@observable public itemsPerLoad = 20;
	@observable public total = 0;
	@observable public notifications: INotificationRecord[];

	@observable public unread: number;
	@observable public isLoading = false;
	@observable public shouldRefreshNotifications = false;

	public getPaginationQuery = () => {
		const builder = queryBuilder.builder();

		return builder
			.inSet('type', notificationRequestTypes)
			.rangeStart(this.itemsOffset)
			.rangeEnd(this.itemsOffset + (this.itemsPerLoad - 1))
			.build();
	};

	@action public handleRefreshNotifications = async () => {
		const { unread, total } = await getUserNotifications(this.getPaginationQuery());

		runInAction(() => {
			if (this.unread < unread) {
				this.total = total;
				this.shouldRefreshNotifications = true;
			} else {
				this.shouldRefreshNotifications = false;
			}
		});
	};

	@action public removeLoadedNotifications = () => {
		this.notifications = this.notifications.slice(0, 20);
		this.itemsOffset = 0;
	};

	@action public refreshNotifications = () => {
		this.itemsOffset = 0;
		this.fetchNotifications();
		this.shouldRefreshNotifications = false;
	};

	@action public fetchNotifications = async () => {
		const { results: notifications, unread, total } = await getUserNotifications(this.getPaginationQuery());

		runInAction(() => {
			this.itemsOffset = 0;
			this.total = total;
			this.notifications = notifications;
			this.unread = unread;
		});
	};

	@action public fetchNotificationsOnIntersection = async () => {
		this.isLoading = true;
		this.itemsOffset += this.itemsPerLoad;
		const { results: notifications, total } = await getUserNotifications(this.getPaginationQuery());

		if (notifications.length === 0) {
			runInAction(() => {
				this.isLoading = null;
			});
			return;
		}

		runInAction(() => {
			if (notifications.length !== 0) {
				this.notifications.push(...notifications);
				this.isLoading = false;
				this.total = total;
			}
		});
	};

	public markRead = async (item: INotificationRecord) => {
		if (!item.read) {
			await markNotificationRead([{ id: item.id }]);
			this.updateNotificationTray(item.id);
		}
	};

	@action public markAllRead = async () => {
		if (this.unread !== 0) {
			await markAllNotificationsRead();

			for (const item of this.notifications) {
				if (!item.read) {
					_.set(item, 'read', true);
				}
			}

			runInAction(() => {
				this.unread = 0;
			});
		}
	};

	@action private updateNotificationTray = (data: number) => {
		_.forEach(this.notifications, (item) => {
			if (data === item.id && !item.read) {
				_.set(item, 'read', true);
				this.unread--;
			}
		});
	};
}

export default createContext(new NotificationsStore());
