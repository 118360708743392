import * as React from 'react';
import { getFormattedTimeDifferenceFromNow } from '@lms/utils/date.utils';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';
import * as styles from './styles.scss';

interface IProps {
	from: string;
	className?: string;
	verbose?: boolean;
}

const LastUpdate: React.FC<IProps> = (props: IProps) => {
	const selectors = styles as any;
	const timeDifference = getFormattedTimeDifferenceFromNow(props.from, props.verbose);

	return (
		<span className={`${selectors['blob']} ${props.className || ''}`}>
			{
				timeDifference.difference
					? (`${timeDifference.difference} ${timeDifference.unit}`)
					: getDictionaryValue('messages.justUpdated')
			}
		</span>
	);
};

export default LastUpdate;
