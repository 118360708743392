import * as React from 'react';
import { observer } from 'mobx-react';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';
import Tippy from '@tippy.js/react';

interface IProps {
	path: string;
	name: string;
	onClick?: () => void;
}

const ICONS = {
	'labels.navigation.dashboard': 'fa-tachometer-fast',
	'labels.navigation.leads': 'fa-list',
	'labels.navigation.campaignSubmissions': 'fa-mail-bulk',
	'labels.navigation.import': 'fa-cloud-upload',
	'labels.navigation.users': 'fa-users',
	'labels.navigation.offices': 'fa-city',
	'labels.navigation.tools': 'fa-tools',
	'labels.navigation.settings': 'fa-cog',
	'labels.navigation.user': 'fa-user'
} as any;

const NavItem: React.FC<IProps> = observer((props: IProps) => {
	const reduxStore = getStoreProxy();
	const isActive = reduxStore.state.router.location.pathname === props.path;

	const handleNavigateClick = () => {
		reduxStore.dispatch(navigate(props.path));

		if (props.onClick) {
			props.onClick();
		}
	};

	return (
		<li data-active={isActive} >
			<Tippy content={getDictionaryValue(props.name)} placement="right">
				<a onClick={handleNavigateClick}>
					<i className={`fal ${ICONS[props.name]} fa-fw`}></i>
					<span>
						{getDictionaryValue(props.name)}
					</span>
				</a>
			</Tippy>
		</li>
	);
});

export default NavItem;
