import { IOrderingType } from '@lms/utils/state/common/ordering.type.enum';

/**
 * Returns the icon type that needs to be rendered for specific
 * fitlers that are sorted in Ascending/Descending fashion
 *
 * @param {string} filterValue - Value of targeted filter
 * @returns {string | null}
 */
export function getNumericOrderingIconType(filterValue: IOrderingType) {
	if (filterValue === IOrderingType.ASCENDING) {
		return 'sort numeric up';
	} else if (filterValue === IOrderingType.DESCENDING) {
		return 'sort numeric down';
	} else if (filterValue === IOrderingType.NEUTRAL) {
		return 'sort';
	}

	return null;
}

/**
 * Returns the icon type that needs to be rendered for specific
 * fitlers that are sorted in Ascending/Descending fashion
 *
 * @param {string} filterValue - Value of targeted filter
 * @returns {string | null}
 */
export function getAmountOrderingIconType(filterValue: IOrderingType) {
	if (filterValue === IOrderingType.ASCENDING) {
		return 'sort amount up';
	} else if (filterValue === IOrderingType.DESCENDING) {
		return 'sort amount down';
	} else if (filterValue === IOrderingType.NEUTRAL) {
		return 'sort';
	}

	return null;
}

export function getLeadStatusColor(item: any) {
	let statusColor = 'default';

	statusColor = ['new_lead'].includes(item.status) ? 'orange' : statusColor;
	statusColor = ['dead', 'problematic', 'declined_by_underwriting'].includes(item.status) ? 'red' : statusColor;
	statusColor = [
		'sold',
		'installation_assigned',
		'installation_scheduled',
		'installation_complete',
		'bonus_submitted_for_payment'
	].includes(item.status) ? 'green' : statusColor;
	return statusColor;
}

export function getLeadPriorityColor(item: any) {
	let priorityColor = 'default';

	priorityColor = ['urgent', 'asap'].includes(item.priority) ? 'red' : priorityColor;
	return priorityColor;
}

export function getViewReadyFileSize(bytes: number, decimals = 2) {
	if (bytes === 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getReleaseNoteStatusColor(item: any) {
	let statusColor = 'default';
	statusColor = item.activity?.isRead ? statusColor : 'orange';
	return statusColor;
}
