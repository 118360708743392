import { ILeadRecord } from '@lms/typings/records/lead.record';
import { BaseAnalyticsEvent } from '@lms/utils/analytics/events/base.event';
import { LEADS_CATEGORY } from '@lms/utils/analytics/events/leads/constants';

export class LeadCreatedEvent extends BaseAnalyticsEvent {
	constructor(lead: ILeadRecord) {
		super('leads lead created', {
			category: LEADS_CATEGORY,
			action: 'created_lead',
			label: lead.leadSource,
			value: lead.id
		});
	}
}
