import * as React from 'react';
import * as _ from 'lodash';

export const useOnClickOutside = (refs: any, handler: any) => {
	React.useEffect(() => {
		const listener = (event: any) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!_.isArray(refs)) {
				refs = [refs];
			}

			let clickInside = false;
			_.each(refs, (ref) => {
				clickInside = clickInside || (!ref.current || ref.current.contains(event.target));
			});

			if (!clickInside) {
				handler(event);
			}

			return;
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [refs, handler]);
};
