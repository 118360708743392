import { observable, action } from 'mobx';
import { Store } from 'redux';

import { IApplicationState } from '@lms/utils/state/application.state';

// TODO remove when redux is removed
class ReduxStoreProxy {
	@observable public state: IApplicationState;
	public dispatch: any;

	constructor(store: Store<IApplicationState>) {
		this.dispatch = store.dispatch;
		this.state = store.getState();
		store.subscribe(() => this.updateReduxStore(store));
	}

	/**
	 * @function
	 * @param {Store<IApplicationState>} store
	 */
	@action public updateReduxStore(store: Store<IApplicationState>) {
		this.state = store.getState();
	}
}

let _storeProxy: ReduxStoreProxy | null = null;

export function synchronizeStoreProxy(store: Store<IApplicationState>) {
	_storeProxy = new ReduxStoreProxy(store);
}

export function getStoreProxy() {
	return _storeProxy;
}
