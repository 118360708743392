import * as _ from "lodash";
import * as React from "react";
import {FormProvider, useForm} from "react-hook-form";
import InputField from '@lms/views/components/forms/fields/input.field';
import {Button, IButtonSkin} from "@lms/views/elements/button";
import {ResizableTextArea} from "@lms/views/elements/text.area";
import Feedback from "@harbortouch/lms-models/lib/feedback.model";
import IFeedbackType from "@lms/enums/feedback.type.enum";

interface IProps {
	onClose: () => void;
	onSubmit: (values: any, form?: any) => void;
	initialValues?: Partial<Feedback>;
}

const FeedbackForm: React.FC<IProps> = (props) => {
	const form = useForm();

	React.useEffect(() => {
		form.register('description', { required: 'Please enter a description' });
		form.register('type', { required: 'Please select a type' });
		form.setValue('description', props.initialValues?.description);
		form.setValue('type', props.initialValues?.type);

		return () => {
			form.unregister('description');
		};
	}, [form.register, form.unregister, props.initialValues]);

	const onSubmit = (data: any) => {
		props.onSubmit({
			...data,
			metadata: {
				url: window.location.href,
				agent: navigator.userAgent
			}
		}, form);
	};

	return (
		<div data-test="change-password-form">
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<div className="mb-2">
						<InputField
							label="Title"
							validation={{
								required: 'Please enter a title'
							}}
						/>
						<div
							className="input-basic"
							data-error={_.get(form.errors.type, 'message', false)}
						>
							<label>Type</label>
							<div className="input-radio-buttons">
								{Object.values(IFeedbackType).map((type: string) => (
									<div key={type} onClick={() => form.setValue('type', type)}>
										<input
											type="radio"
											id={type}
											name="type"
											value={type}
										/>
										<label htmlFor={type}>{type}</label>
									</div>
								))}
							</div>
						</div>
						<div
							className="input-basic"
							data-error={_.get(form.errors.description, 'message', false)}
						>
							<ResizableTextArea
								maxRows={5}
								rows={3}
								label="Description"
								maxLength={1000}
								onValuePass={(value: string) => {
									form.setValue('description', value);
								}}
								error={!!_.get(form.errors.description, 'message', false)}
							/>
						</div>
					</div>

					<div className="modal-actions">
						<Button
							skin={IButtonSkin.DANGER}
							size={'md'}
							type={'submit'}
							isLoading={form.formState.isSubmitting}
						>
                            Send
						</Button>
						<Button
							skin={IButtonSkin.ACTION}
							outlined
							size={'md'}
							type={'button'}
							onClick={props.onClose}
						>
                            Cancel
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default FeedbackForm;
