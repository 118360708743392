// TODO: enum generation from backend definition
import { IUserRecord } from '@lms/typings/records/user.record';

export enum IUserPermission {
	CREATE_LEADS = 'CREATE_LEADS',
	DELETE_LEADS = 'DELETE_LEADS',
	MODIFY_LEADS = 'MODIFY_LEADS',

	GENERATE_LEAD_PROGRESSION_REPORT = 'GENERATE_LEAD_PROGRESSION_REPORT',
	GENERATE_LEAD_TIME_REPORT = 'GENERATE_LEAD_TIME_REPORT',
	GENERATE_LEAD_COUNT_REPORT = 'GENERATE_LEAD_COUNT_REPORT',
	GENERATE_RECENT_LEADS_REPORT = 'GENERATE_RECENT_LEADS_REPORT',
	GENERATE_USER_TOP_REPORT = 'GENERATE_USER_TOP_REPORT',
	GENERATE_USER_DISTRIBUTION_REPORT = 'GENERATE_USER_DISTRIBUTION_REPORT',

	CHANGE_OTHER_USERS_PASSWORDS = 'CHANGE_OTHER_USERS_PASSWORDS',
	CHANGE_LEAD_STATUS = 'CHANGE_LEAD_STATUS',
	CHANGE_LEAD_STATUS_TO_NEW_LEAD = 'CHANGE_LEAD_STATUS_TO_NEW_LEAD',
	CHANGE_LEAD_PRIORITY = 'CHANGE_LEAD_PRIORITY',
	CHANGE_LEAD_MID = 'CHANGE_LEAD_MID',
	ASSIGN_LEADS = 'ASSIGN_LEADS',
	ASSIGN_SECONDARY_TO_LEADS = 'ASSIGN_SECONDARY_TO_LEADS',
	COMMENT_LEADS = 'COMMENT_LEADS',
	EXPORT_LEADS = 'EXPORT_LEADS',
	MANAGE_LEAD_ATTACHMENTS = 'MANAGE_LEAD_ATTACHMENTS',
	SEE_LEADS_FOR_ALL_BRANDS = 'SEE_LEADS_FOR_ALL_BRANDS',
	SEE_LEADS_NOT_ASSIGNED_TO_SELF = 'SEE_LEADS_NOT_ASSIGNED_TO_SELF',
	SEE_ALL_BRAND_LEADS = 'SEE_ALL_BRAND_LEADS',
	SEE_ALL_BRAND_OFFICES = 'SEE_ALL_BRAND_OFFICES',
	SEE_LEAD_AUDIT= 'SEE_LEAD_AUDIT',
	SEE_USERS_OUTSIDE_HIERARCHY = 'SEE_USERS_OUTSIDE_HIERARCHY',
	ASSIGN_TO_NON_SUBORDINATES = 'ASSIGN_TO_NON_SUBORDINATES',
	CREATE_DUPLICATE_LEADS = 'CREATE_DUPLICATE_LEADS',
	SEE_OFFICE_LIST = 'SEE_OFFICE_LIST',
	MANAGE_USER_PERMISSIONS = 'MANAGE_USER_PERMISSIONS',
	GENERATE_APPLICATION = 'GENERATE_APPLICATION',

	SEE_DATA_FOR_ALL_USERS = 'SEE_DATA_FOR_ALL_USERS',
	SEE_USERS_FOR_ALL_BRANDS = 'SEE_USERS_FOR_ALL_BRANDS',
	SEE_USER_LIST = 'SEE_USER_LIST',
	SEE_USER_AUDIT = 'SEE_USER_AUDIT',
	CREATE_USERS = 'CREATE_USERS',
	SEE_CREATOR_IN_LEAD_LIST = 'SEE_CREATOR_IN_LEAD_LIST',
	CREATE_USERS_OF_ANY_LEVEL = 'CREATE_USERS_OF_ANY_LEVEL',
	DELETE_USERS = 'DELETE_USERS',
	MODIFY_OTHER_USERS = 'MODIFY_OTHER_USERS',
	ASSIGN_FOR_ANY_DISTANCE = 'ASSIGN_FOR_ANY_DISTANCE',
	TOGGLE_INSTALLATION_PROGRESS_TRACKING = 'TOGGLE_INSTALLATION_PROGRESS_TRACKING',

	ACCESS_INTERNAL_TOOLS = 'ACCESS_INTERNAL_TOOLS',
	MANAGE_PROMO_TYPES = 'MANAGE_PROMO_TYPES',
	MODIFY_NOTIFICATION_SETTINGS = 'MODIFY_NOTIFICATION_SETTINGS',

	MANAGE_CAMPAIGN_SUBMISSIONS = 'MANAGE_CAMPAIGN_SUBMISSIONS',
	SEE_DEPARTMENTS = 'SEE_DEPARTMENTS',
	UNLOCK_APPLICATION = 'UNLOCK_APPLICATION',
}

export interface IUserState {
	metadata: IUserRecord;
	fineGrainedPermissions: { [permissionName: string]: any };
	permissions: IUserPermission[];
	contentAccessToken: string;
	isAuthenticated: boolean;
}

/**
 * @type {IUserState}
 */
export const initialState: IUserState = {
	metadata: {
		id: null,
		firstName: null,
		lastName: null,

		brand: {},
		address: {},
		primaryContacts: {}
	},

	fineGrainedPermissions: [],
	permissions: [],
	contentAccessToken: null,
	isAuthenticated: false
};
