import { makeActionCreator } from '@harbortouch/react-modules';
import { eventBus } from 'mobx-event-bus2';

import { IApplicationState } from '@lms/utils/state/application.state';
import { IUserRecord } from '@lms/typings/records/user.record';
import { IRequestStatus } from '@lms/redux/actions/common/request.status.enum';
import { buildDefaultUserRecordQuery } from '@lms/models/user.model/utils';

import {
	getUserById,
	patchUser
} from '@lms/services/user.service';

export enum ACTION_NAMES {
	FETCH_CURRENT_USER_DATA = '@lms/user/FETCH_CURRENT_USER_DATA',
	UPDATE_LAST_LOGIN_DATE_AND_TIMEZONE = '@lms/user/UPDATE_LAST_LOGIN_DATE_AND_TIMEZONE'
}

export interface IFetchUserDataPayload {
	status: IRequestStatus;
	data?: {
		user: IUserRecord;
	};
}

export interface IUpdateLastLoginDatePayload {
	status: IRequestStatus;
}

/**
 * @thunk
 * @function
 * @returns {ThunkAction}
 */
export function fetchCurrentUserData() {
	const fetchAction = makeActionCreator<IFetchUserDataPayload>(ACTION_NAMES.FETCH_CURRENT_USER_DATA);

	return (dispatch: any, getState: any) => {
		const state = getState() as IApplicationState;
		const { id } = state.user.metadata;

		dispatch(fetchAction({ status: IRequestStatus.REQUESTING }));

		return getUserById(id, buildDefaultUserRecordQuery())
			.then((user) => {
				dispatch(fetchAction({
					status: IRequestStatus.SUCCESS,
					data: { user }
				}));
				eventBus.post('user.currentUserLoaded', user);
			});
	};
}

/**
 * @thunk
 * @function
 * @returns {ThunkAction}
 */
export function updateLastLoginDateAndTimezone() {
	const updateAction = makeActionCreator<IUpdateLastLoginDatePayload>(ACTION_NAMES.UPDATE_LAST_LOGIN_DATE_AND_TIMEZONE);

	return (dispatch: any, getState: any) => {
		const state = getState() as IApplicationState;

		const { id } = state.user.metadata;
		const now = new Date().toISOString();
		const currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

		dispatch(updateAction({ status: IRequestStatus.REQUESTING }));
		patchUser(id, {
			lastLoginAt: now,
			timezone: currentTimezone
		})
			.then(() => {
				dispatch(updateAction({ status: IRequestStatus.SUCCESS }));
			});
	};
}
