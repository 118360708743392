import { BaseAnalyticsEvent } from '@lms/utils/analytics/events/base.event';
import { ENGAGEMENT_CATEGORY } from '@lms/utils/analytics/events/engagement/constants';

export class LoginEvent extends BaseAnalyticsEvent {
	constructor() {
		super('engagement login', {
			category: ENGAGEMENT_CATEGORY,
			action: 'login'
		});
	}
}
