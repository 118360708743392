import * as _ from 'lodash';
import * as queryBuilder from 'objection-find-query-builder';
import { action, runInAction } from 'mobx';

import { IUserRecord } from '@lms/typings/records/user.record';
import { IUserModel } from '@lms/models/user.model/index';

import { sanitizeFormValue } from '@lms/utils/form.utils';
import { patchUser } from '@lms/services/user.service';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';

/**
 * Builds the default API public search query to be used for
 * retrieving a user
 *
 * @function
 * @returns {object} - Query parameter object
 */
export function buildDefaultUserRecordQuery() {
	const builder = queryBuilder.builder();
	return builder
		.eager([
			'brand',
			'manager',
			'address',
			'primaryContacts',
			'customPermissions',
			'primaryOffices',
			'secondaryOffices'
		])
		.build();
}

/**
 * @function
 * @param {IUserRecord} json
 * @returns {IUserRecord}
 */
export function getFailsafeUserRecordFromJSON(json: IUserRecord) {
	const record = { ...json };

	if (!json.address) {
		record.address = {
			place: null,
			city: null,
			street: null,
			zip: null,
			state: null
		};
	}

	if (!json.primaryContacts) {
		record.primaryContacts = {};
	}

	return record;
}

/**
 * Updates the specified Lead model attribute for a given instance
 * of a LeadModel and requests a PATCH operation on the associated record
 * in the backend
 *
 * @function
 * @async
 * @param {ILeadModel} lead
 * @param {string} attrName
 * @param {any} newValue
 * @returns {Promise<void>}
 */
export const patchUserModelAttribute = action(async (
	user: IUserModel,
	attrName: keyof IUserModel,
	newValue: any
) => {
	const previousValue = _.get(user, attrName);
	_.set(user, attrName, newValue);

	try {
		const patchObject = _.set({}, attrName, sanitizeFormValue(newValue));

		const patchedLeadRecord = await patchUser(
			user.id,
			patchObject,
			buildDefaultUserRecordQuery()
		);

		runInAction(() => {
			_.assign(user, getFailsafeUserRecordFromJSON(patchedLeadRecord));
		});
	} catch (error) {
		runInAction(() => {
			_.set(user, attrName, previousValue);
		});

		throw error;
	}
});

export const patchUserModelAttributes = action(async (
	user: IUserModel,
	data: any
) => {
	try {
		const patchedLeadRecord = await patchUser(
			user.id,
			data,
			buildDefaultUserRecordQuery()
		);

		runInAction(() => {
			_.assign(user, getFailsafeUserRecordFromJSON(patchedLeadRecord));
		});
	} catch (error) {
		throw error;
	}
});

export function navigateToSelectedUser(id: number) {
	const reduxStore = getStoreProxy();
	reduxStore.dispatch(navigate(`/users/${id}`));
}
