import { IDepartmentRecord } from '@lms/typings/records/department.record';
import { forcefullyExtendObservable } from '@lms/utils/model.utils';

export class DepartmentModel implements IDepartmentRecord {
	public id: number;
	public name: string;
	public isArchived: boolean;

	constructor(json: object) {
		forcefullyExtendObservable(this, json);
	}
}
