import * as _ from 'lodash';
import * as leadStatusEnum from '@harbortouch/lms-enums/lib/enums/lead.status.enum';

import { IViewReadyEntity } from '@lms/utils/selectors/metadata.selectors';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';
import { IApplicationState } from '@lms/utils/state/application.state';

// Substatuses
import { getViewReadyLeadLastActions } from '@lms/enums/lead.last.action.enum';
import { getViewReadyLeadFailureReasons } from '@lms/enums/lead.failure-reason.enum';
import { getViewReadyLeadSoldTypes } from '@lms/enums/lead.sold.type.enum';

export enum ILeadStatus {
	NEW_LEAD = (leadStatusEnum as any).NEW_LEAD,
	VIEWED = (leadStatusEnum as any).VIEWED,
	WAITING_ON_MERCHANT = (leadStatusEnum as any).WAITING_ON_MERCHANT,
	IN_PROGRESS = (leadStatusEnum as any).IN_PROGRESS,
	QUOTE_SENT = (leadStatusEnum as any).QUOTE_SENT,
	APPLICATION_SENT = (leadStatusEnum as any).APPLICATION_SENT,
	SUBMITTED_TO_UNDERWRITING = (leadStatusEnum as any).SUBMITTED_TO_UNDERWRITING,
	DECLINED_BY_UNDERWRITING = (leadStatusEnum as any).DECLINED_BY_UNDERWRITING,
	SOLD = (leadStatusEnum as any).SOLD,
	PROBLEMATIC = (leadStatusEnum as any).PROBLEMATIC,
	DEAD = (leadStatusEnum as any).DEAD,
	INSTALLATION_ASSIGNED = (leadStatusEnum as any).INSTALLATION_ASSIGNED,
	INSTALLATION_SCHEDULED = (leadStatusEnum as any).INSTALLATION_SCHEDULED,
	INSTALLATION_COMPLETE = (leadStatusEnum as any).INSTALLATION_COMPLETE,
	BONUS_SUBMITTED_FOR_PAYMENT = (leadStatusEnum as any).BONUS_SUBMITTED_FOR_PAYMENT,
	CONTACTED = (leadStatusEnum as any).CONTACTED,
	DUPLICATE = (leadStatusEnum as any).DUPLICATE,
}

export const NonArchivedLeadStatus = {
	NEW_LEAD: 'new_lead',
	VIEWED: 'viewed',
	WAITING_ON_MERCHANT: 'waiting_on_merchant',
	IN_PROGRESS: 'in_progress',
	QUOTE_SENT: 'quote_sent',
	APPLICATION_SENT: 'application_sent',
	SUBMITTED_TO_UNDERWRITING: 'submitted_to_underwriting',
	DECLINED_BY_UNDERWRITING: 'declined_by_underwriting',
	PROBLEMATIC: 'problematic',
	INSTALLATION_ASSIGNED: 'installation_assigned',
	INSTALLATION_SCHEDULED: 'installation_scheduled',
	CONTACTED: 'contacted'
};

export const ArchivedLeadStatus = {
	INSTALLATION_COMPLETE: 'installation_complete',
	BONUS_SUBMITTED_FOR_PAYMENT: 'bonus_submitted_for_payment',
	DEAD: 'dead',
	SOLD: 'sold',
	DUPLICATE: 'duplicate'
};

export const CanBeArchivedLeadStatus = {
	INSTALLATION_COMPLETE: 'installation_complete',
	BONUS_SUBMITTED_FOR_PAYMENT: 'bonus_submitted_for_payment',
	DEAD: 'dead',
	SOLD: 'sold'
};

export const ShouldRequireMerchantIdLeadStatus = {
	INSTALLATION_ASSIGNED: 'installation_assigned',
	INSTALLATION_SCHEDULED: 'installation_scheduled',
	INSTALLATION_COMPLETE: 'installation_complete',
	BONUS_SUBMITTED_FOR_PAYMENT: 'bonus_submitted_for_payment',
	SOLD: 'sold'
};

/**
 * TODO refactor when redux is removed
 *
 * @function
 * @returns {IViewReadyEntity[]}
 */
export function getViewReadyLeadStatuses(state: IApplicationState, isArchived?: boolean): IViewReadyEntity[] {
	const statusDecider = () => {
		if (typeof isArchived === 'undefined' || isArchived === null) {
			return leadStatusEnum;
		}
		return isArchived ? ArchivedLeadStatus : NonArchivedLeadStatus;
	};

	const statuses = _.values(statusDecider());

	return statuses.map((name: string) => {
		return {
			value: `${name}`,
			label: getDictionaryValue(`labels.leads.status.${name}`),
			status: isArchived ? 'Archived' : null
		};
	});
}

export function getFullLeadStatuses(): IViewReadyEntity[] {
	const archivedStatuses = _.values(ArchivedLeadStatus);
	const statuses = [
		..._.values(NonArchivedLeadStatus),
		...archivedStatuses
	];

	const getChildren = (name: string) => {
		switch (name) {
			case ILeadStatus.IN_PROGRESS as any:
				return getViewReadyLeadLastActions();
			case ILeadStatus.DEAD as any:
				return getViewReadyLeadFailureReasons();
			case ILeadStatus.SOLD as any:
				return getViewReadyLeadSoldTypes();
			default:
				return null;
		}
	};

	return statuses.map((name: string) => {
		const children = getChildren(name);
		const label = getDictionaryValue(`labels.leads.status.${name}`);

		return {
			value: `${name}`,
			label,
			status: archivedStatuses.includes(name) ? 'Archived' : null,
			childrenAttribute: children ? children[0].attribute : null,
			children: children
				? [
					{value: name, label: `All ${label} leads`, attribute: 'status'},
					...children
				]
				: null
		};
	});
}
