export interface ITimezoneModel {
	key: string;
	label: string;
	offset: number;
	offsetLabel?: string;
}

export class TimezoneModel implements ITimezoneModel {
	public value: string;
	public label: string;
	public offset: number;
	public key: string;

	constructor(timezone: ITimezoneModel) {
		this.value = timezone.key;
		this.label = `${timezone.label}, ${timezone.offsetLabel}`;
		this.offset = timezone.offset;
		this.key = timezone.key;
	}
}
