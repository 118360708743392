import * as apiClient from '@lms/services/clients/lms.api.client';

import { IFive9LeadRecord } from '@lms/typings/records/five9.lead.record';
import { ILeadRecord } from '@lms/typings/records/lead.record';

/**
 * Asynchronously posts Five9 lead file to the LMS API so it can be parsed
 * and remapped into an ILeadModel type lead object
 *
 * @async
 * @function
 * @param {IFive9LeadModel} lead
 * @returns {Promise<any>}
 */
export function postFive9LeadForParsing(lead: IFive9LeadRecord): Promise<{ leads: ILeadRecord[] }> {
	return apiClient.postJson('/five9/leads/parse', [lead]);
}
