import { IBrandRecord } from '@lms/typings/records/brand.record';
import { IUserRoleRecord } from '@lms/typings/records/user-role.record';

export interface IMetadataState {
	applicationFinishedLoading: boolean;
	availableBrands: IBrandRecord[];

	brandRoleMap: {
		[brandId: string]: {
			[roleName: string]: IUserRoleRecord;
		};
	};
}

export const initialState: IMetadataState = {
	applicationFinishedLoading: false,
	availableBrands: [],
	brandRoleMap: {}
};
