import * as _ from 'lodash';

import { observable, action, computed } from 'mobx';

import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';
import {
	ISystemNotificationModel,
	SystemNotificationModel
} from '@lms/features/system.notifications/models/system.notification.model';

export class SystemNotificationStore {
	@observable private notifications: ISystemNotificationModel[];

	/**
	 * @getter
	 * @returns {ISystemNotificationModel}
	 */
	@computed get firstIn(): ISystemNotificationModel {
		return _.head(this.notifications);
	}

	/**
	 * @getter
	 * @returns {ISystemNotificationModel}
	 */
	@computed get lastIn(): ISystemNotificationModel {
		return _.last(this.notifications);
	}

	/**
	 * @getter
	 * @returns {number}
	 */
	@computed get count(): number {
		return this.notifications.length;
	}

	constructor() {
		this.notifications = [];
	}

	/**
	 * @function
	 * @param {string} correlationId
	 * @param {string} message
	 * @param {ISystemNotificationSkin} skin
	 * @returns {string} - id of the notification
	 */
	@action public pushNotification = (
		correlationId: string,
		message: string,
		skin: ISystemNotificationSkin
	) => {
		// Avoid duplication of correlated notifications
		const correlatedNotification = _.find(this.notifications, { correlationId });

		if (correlatedNotification) {
			this.removeNotificationsWithCorrelation(correlationId);
		}

		const notification = new SystemNotificationModel(correlationId, message, skin);
		this.notifications.push(notification);
	};

	/**
	 * @function
	 * @param {string} correlationId
	 */
	@action public removeNotificationsWithCorrelation = (correlationId: string) => {
		this.notifications = this.notifications
			.filter((notification) => notification.correlationId !== correlationId);
	};

	/**
	 * @function
	 */
	@action public clearNotifications = () => {
		this.notifications = [];
	};
}

const systemNotificationStore = new SystemNotificationStore();
export default systemNotificationStore;
