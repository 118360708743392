import { IFive9LeadRecord } from '@lms/typings/records/five9.lead.record';

const STORAGE_PREFIX = 'five9';
const LEAD_NAME = 'lead';

const LEAD_KEY = `${STORAGE_PREFIX}.${LEAD_NAME}`;

/**
 * Returns the Five9 lead that's currently being accepted
 * from `localStorage`
 *
 * @function
 * @returns {IFive9LeadRecord}
 */
export function getFive9Lead(): IFive9LeadRecord {
	return JSON.parse(localStorage.getItem(LEAD_KEY));
}

/**
 * Persists the Five9 lead that's being accepted to `localStorage`
 *
 * @function
 */
export function persistFive9Lead(lead: IFive9LeadRecord): void {
	localStorage.setItem(LEAD_KEY, JSON.stringify(lead));
}
