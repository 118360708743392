import * as React from 'react';
import Tippy from '@tippy.js/react';
import LabelMenu from './label.menu';
import { ILabelModel } from '@lms/models/label.model';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';

interface IProps {
	leadLabel: ILabelModel;
	hidden?: boolean;
	onUpdate: () => void;
}

export const LeadLabelItem: React.FC<IProps> = (props: IProps) => {
	const [showMenu, setShowMenu] = React.useState(false);
	const menuRef = React.useRef<HTMLDivElement>(null);
	const { leadLabel } = props;
	const reduxStore = getStoreProxy();
	const path = `/labels/${props.leadLabel.id}`;

	const handleMenuClick = () => {
		setShowMenu(true);
	};

	const handleMenuClose = () => {
		setShowMenu(false);
	};

	const handleClick = () => {
		reduxStore.dispatch(navigate(path));
	};

	return (
		<Tippy content={leadLabel.name} placement="right">
			<div
				className="label-item"
				data-active={reduxStore.state.router.location.pathname === path}
				data-menu-active={showMenu}
				data-hidden={props.hidden}
			>
				<span
					className={`label-icon`}
					data-color={leadLabel.color}
					onClick={handleClick}
				/>

				<span className="label-item-title" onClick={handleClick}>
					{leadLabel.name}
				</span>

				<i
					className="fal fa-ellipsis-h label-actions"
					onClick={handleMenuClick}
					ref={menuRef}
				/>
				{
					showMenu && <LabelMenu
						leadLabel={leadLabel}
						onClose={handleMenuClose}
						onUpdate={props.onUpdate}
						triggerRef={menuRef}
					/>
				}
			</div>
		</Tippy>
	);
};
