import * as React from 'react';
import * as styles from './styles.scss';

export enum IButtonSkin {
	ACTION = "button__action",
	SUCCESS = "button__success",
	WARNING = "button__warning",
	DANGER = "button__danger",
	TEXT = "button__text",
	DARK = "button__dark",
	WHITE = "button__white",
	SPACE_BLACK = "button__space-black",
}

interface IProps {
	skin?: IButtonSkin;
	type?: 'button' | 'submit' | 'reset' | 'fake';
	size?: 'xs' | 'sm' | 'md' | 'lg';
	children?: any;

	className?: string;
	iconLeft?: string;
	iconRight?: string;
	test?: string;

	outlined?: boolean;
	isLoading?: boolean;
	disabled?: boolean;
	iconOnly?: boolean;

	onClick?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<IProps> = (props: IProps) => {
	const selectors = styles as any;
	const shouldPreventClicks = props.disabled || props.isLoading;

	const elementProps = {
		'className': `${selectors.button} ${selectors[props.skin] || selectors[IButtonSkin.ACTION]} ${props.className || ''}`,
		'onClick': shouldPreventClicks ? null : props.onClick,
		'data-loading': props.isLoading,
		'data-outlined': props.outlined,
		'data-size': props.size || 'md',
		'data-test': props.test
	} as any;

	const content = (
		<>
			{props.iconLeft && !props.isLoading &&
            <i className={`fal ${props.iconLeft} ${!props.iconOnly && selectors.iconLeft}`}/>
			}

			{props.children}

			{props.iconRight && !props.isLoading &&
            <i className={`fal ${props.iconRight} ${selectors.iconRight}`}/>
			}

			{props.isLoading &&
            <i className={`fal fa-spinner-third ${selectors.iconRight}`} data-loading={props.isLoading}/>
			}
		</>
	);

	if (props.type === 'fake') {
		return (
			<div
				{...elementProps}
				data-disabled={props.disabled}
				data-type="fake"
			>
				{content}
			</div>
		);
	}

	return (
		<button
			{...elementProps}
			role="button"
			type={props.type}
			disabled={props.disabled}
		>
			{content}
		</button>
	);
};
