import { MiddlewareAPI, Dispatch } from 'redux';
import { IAction } from '@harbortouch/react-modules';
import { IApplicationState } from '@lms/utils/state/application.state';

import {
	NAVIGATION_ACTION_NAMES
} from '@lms/redux/actions/navigation.actions';

import { hideSystemNotification } from '@lms/redux/actions/system.notification.actions';

/**
 * @deprecated
 * @param {MiddlewareAPI<IApplicationState>} store
 * @returns {(next: Dispatch<IApplicationState>) => (action: IAction<any>) => void}
 */
export default
(store: MiddlewareAPI<IApplicationState>) =>
	(next: Dispatch<IApplicationState>) =>
		(action: IAction<any>) => {
			next(action);

			switch (action.type) {
				case NAVIGATION_ACTION_NAMES.NAVIGATE_PAGE:
					store.dispatch(hideSystemNotification());
					break;
			}
		};
