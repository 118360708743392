import * as React from 'react';
import { observer } from 'mobx-react';
import ReleaseNotePreview from './release.note.preview';
import { getReleaseNoteInfoResponse, updateReleaseNoteActivity } from '@lms/services/release.notes.service';
import Modal from '@lms/views/components/modal';
import releaseNotesUnreadStore from '@lms/views/modules/sidebar/release.notes/release.notes.unread.store';
import * as queryBuilder from 'objection-find-query-builder';

const ReleaseNotePopup: React.FC = observer(() => {
	const [releaseNote, setReleaseNote] = React.useState(null);
	const currentScreenWidth = screen.width;

 	const baseQuery = () => {
		const builder = queryBuilder.builder();
		return builder
			.eager(['images'])
			.build();
	};

	const fetchItem = async () => {
		const result = await getReleaseNoteInfoResponse(baseQuery());
		setReleaseNote(result.latestReleaseNote);
	};

	const handleUpdateActivity = async (id: number, read: boolean) => {
		await updateReleaseNoteActivity(id, {isReminded: true, isRead: read});
		releaseNotesUnreadStore.markRead(read);
	};

	React.useEffect(() => {
		fetchItem();
	}, []);

	if (releaseNote) {
		const showReleaseNote = !releaseNote.activity?.isReminded;

		return (
			<>
				{
					showReleaseNote && (
						<Modal
							onClose={() => setReleaseNote(null)}
							size={
								currentScreenWidth > 768
									? 'medium'
									: 'small'
							}
							scrollable
							fullHeight={currentScreenWidth <= 768}
							fullWidth={currentScreenWidth <= 576}
						>
							<ReleaseNotePreview
								item={releaseNote}
								onClose={() => {
									setReleaseNote(null);
								}}
								handleUpdateActivity={handleUpdateActivity}
							/>
						</Modal>

					)
				}
			</>
		);
	}
});

export default ReleaseNotePopup;

export { ReleaseNotePreview };
