import * as React from 'react';
import Authorized from '@lms/views/components/authorized';
import NavItem from './nav.item';
import { IUserPermission } from '@lms/utils/state/user.state';

interface IProps {
	onClick?: () => void;
}

const SidebarNavigation: React.FC<IProps> = (props: IProps) => {
	return (
		<ul>
			<NavItem
				name="labels.navigation.dashboard"
				path="/dashboard"
				onClick={props.onClick}
			/>

			<NavItem
				name="labels.navigation.leads"
				path="/leads"
				onClick={props.onClick}
			/>
			<Authorized requirePermissions={[IUserPermission.MANAGE_CAMPAIGN_SUBMISSIONS]}>
				<NavItem
					name="labels.navigation.campaignSubmissions"
					path="/campaign-submissions"
					onClick={props.onClick}
				/>
			</Authorized>

			<Authorized requirePermissions={[IUserPermission.CREATE_LEADS]}>
				<NavItem
					name="labels.navigation.import"
					path="/import"
					onClick={props.onClick}
				/>
			</Authorized>

			<Authorized requirePermissions={[IUserPermission.SEE_USER_LIST]}>
				<NavItem
					name="labels.navigation.users"
					path="/users"
					onClick={props.onClick}
				/>
			</Authorized>

			<Authorized requirePermissions={[IUserPermission.SEE_OFFICE_LIST]}>
				<NavItem
					name="labels.navigation.offices"
					path="/offices"
					onClick={props.onClick}
				/>
			</Authorized>

			<Authorized requirePermissions={[IUserPermission.ACCESS_INTERNAL_TOOLS]}>
				<NavItem
					name="labels.navigation.tools"
					path="/tools"
					onClick={props.onClick}
				/>
			</Authorized>
		</ul>
	);
};

export default SidebarNavigation;
