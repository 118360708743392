declare let APP_DATA: any;

export interface IApiConfiguration {
	lms: {
		baseUrl: string;
		authUri: string;
		tokenUri: string;
		clientId: string;
	};
}

export const config: IApiConfiguration = APP_DATA.api as IApiConfiguration;
