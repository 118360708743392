import { ILeadDuplicatesResponse } from '@lms/typings/responses/lead-duplicates.response';
import { ILeadDuplicatesQuery } from '@lms/typings/queries/lead-duplicates.query';

import { ILeadRecord } from '@lms/typings/records/lead.record';
import { ILeadMatchStatus } from '@lms/enums/lead.match-status.enum';

import * as apiClient from '@lms/services/clients/lms.api.client';

/**
 * @function
 * @export
 * @param {object} searchParams
 * @returns {Promise<{ results: any }>}
 */
export function getLeadDuplicates(searchParams: ILeadDuplicatesQuery): Promise<ILeadDuplicatesResponse> {
	return apiClient.get('/lead-duplicates', searchParams);
}

/**
 * Takes a partial (either not yet existing, or already existing) lead and
 * searches for duplicates of this lead.
 *
 * Given that a single lead duplicate check may result in several duplicate
 * matches, this function will reduce the lead duplicate response into a single
 * enum value based on the following rules (in respective order):
 *
 * 1. If at least one hard duplicate was matched in the lead duplicate response,
 *    the response will be reduced to `ILeadMatchStatus.FULL_MATCH`;
 * 2. If no hard duplicate was matched, but at least one partial duplicate was
 *    matched, the response will be reduced to `ILeadMatchStatus.PARTIAL_MATCH`;
 * 3. Otherwise the response will be reduced `null`, as no match was found.
 *
 * @function
 * @param {ILeadRecord} lead - Partial lead the duplicates of which we will be attempting to find
 * @param {string} brandId - Brand to which duplicate search should be limited
 * @returns {Promise<void>}
 */
export async function findMatchForLead(lead: Partial<ILeadRecord>): Promise<ILeadMatchStatus> {
	const query = {
		businessTitle: lead.businessTitle
	} as any;

	if (lead.address) {
		query.latitude = lead.address.lat;
		query.longitude = lead.address.lng;
	}

	let matches = await getLeadDuplicates(query);

	/* Filter out the lead itself if it exists in the system,
	 * as the back-end will return it with the list of duplicates */
	if (lead.id) {
		matches = matches.filter(({ leadId }) => leadId !== lead.id);
	}

	if (matches.find(({ matchStatus }) => matchStatus as any === ILeadMatchStatus.FULL_MATCH)) {
		return ILeadMatchStatus.FULL_MATCH;
	}

	if (matches.length !== 0) {
		return ILeadMatchStatus.PARTIAL_MATCH;
	}

	return null;
}
