import { IAddressRecord } from '@lms/typings/records/address.record';
import { IStructuredAddress } from '@harbortouch/react-storybook/dist/collections/data/address';
import * as _ from 'lodash';

/**
 * Address components to be evaluated in any type of validation
 */
const ADDRESS_COMPONENTS = [
	'city',
	'country',
	'lat',
	'lng',
	'place',
	'state',
	'street',
	'zip'
];

/**
 * Checks whether an address is complete in terms of the
 * data we wish to collect from the address, i.e. zip code
 * and latitude/longitude
 *
 * @function
 * @param {IAddressRecord} address
 * @returns {boolean}
 */
export function isIncompleteAddress(address: IAddressRecord): boolean {
	return !(address.zip || (address.lat && address.lng));
}

/**
 * @function
 *
 * @export
 * @param {IAddressRecord} address
 * @returns {boolean}
 */
export function isEmptyAddress(address: IAddressRecord): boolean {
	return _.compact(_.values(_.pick(address, ADDRESS_COMPONENTS))).length !== 0;
}

export function hasNoAddress(address: IAddressRecord): boolean {
	return _.compact(_.values(_.pick(address, ADDRESS_COMPONENTS))).length === 0;
}

export function addMissingAddressAttributes(address?: IAddressRecord): void {
	_.each(ADDRESS_COMPONENTS, (attribute: string) => {
		_.set(address, attribute, _.get(address, attribute, null));
	});
}

/**
 * Used to transform IStructuredAddress object from react-storybook
 * into an object with the same contract as IAddressRecord
 *
 * @function
 * @param {IStructuredAddress} structuredAddress
 * @returns {IAddressRecord}
 */
export function normalizeStructuredAddress(structuredAddress?: IStructuredAddress): IAddressRecord {
	const normalized = {} as IAddressRecord;

	const streetNumber = _.get(structuredAddress, 'streetNumber.long_name', null);
	const streetName = _.get(structuredAddress, 'streetName.long_name', null);

	/* Join street number and name in US format */
	const street = _.compact([ streetNumber, streetName ]).join(' ');

	_.set(normalized, 'street', street);
	_.set(normalized, 'city', _.get(structuredAddress, 'city.long_name', null));
	_.set(normalized, 'state', _.get(structuredAddress, 'state.long_name', null));
	_.set(normalized, 'country', _.get(structuredAddress, 'country.long_name', null));
	_.set(normalized, 'zip', _.get(structuredAddress, 'postalCode.long_name', null));
	_.set(normalized, 'lat', _.get(structuredAddress, 'location.lat', null));
	_.set(normalized, 'lng', _.get(structuredAddress, 'location.lng', null));
	_.set(normalized, 'place', _.get(structuredAddress, 'placeName', null));

	return normalized;
}

/**
 * Produces an address string from an address record
 *
 * @function
 * @param {IAddressRecord} address
 * @returns {string}
 */
export function formatAddress(address: IAddressRecord): string {
	if (address.place) {
		return address.place;
	}

	const formattedAddressComponents = [
		address.street,
		address.city,
		address.state,
		address.country
	];

	return _.compact(formattedAddressComponents).join(', ');
}
