import * as React from 'react';
import { observer } from 'mobx-react';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';
import Tippy from '@tippy.js/react';
import releaseNotesUnreadStore from './release.notes.unread.store';

interface IProps {
	path: string;
	name: string;
	versionNumber: string;
	onClick: () => void;
}

const ReleaseNotesItem: React.FC<IProps> = observer((props: IProps) => {
	const versionNumber = props.versionNumber;
	const reduxStore = getStoreProxy();
	const isActive = reduxStore.state.router.location.pathname === props.path;

	React.useEffect(() => {
		releaseNotesUnreadStore.fetchReleaseNotes();
	}, []);

	const unread = releaseNotesUnreadStore.unread;
	const isUnread = unread > 0;

	const handleNavigateClick = () => {
		props.onClick();
		reduxStore.dispatch(navigate(props.path));
	};

	return (
		<Tippy content="Release Notes" placement="right">
			<div data-active={isActive} data-unread={isUnread}>
				<a onClick={handleNavigateClick}>
					<i className={`fal fa-broadcast-tower fa-fw`}></i>
					<span>
						LMS v{versionNumber}. Release Notes
					</span>
					{
						isUnread && <span className="circle-count">{unread < 100 ? unread : `99+`}</span>
					}
				</a>
			</div>
		</Tippy>
	);
});

export default ReleaseNotesItem;
