import * as React from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import ImageGallery from 'react-image-gallery';
import { IButtonSkin, Button } from '@lms/views/elements/button';
import { ReleaseNoteModel } from '@lms/models/release.note.models/release.note.model';
import { storageConfig } from '@lms/config/storage.config';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

interface IProps {
	item: ReleaseNoteModel;
	onClose: () => void;
	handleSetRead?: (id: number) => void;
	handleSetReminded?: (id: number) => void;
	handleUpdateActivity?: (id: number, read: boolean) => void;
}

const ReleaseNotePreview: React.FC<IProps> = observer((props: IProps) => {
	const [index, setIndex] = React.useState(0);
	const reduxStore = getStoreProxy();
	const gallery = React.useRef(null);
	const isRead = props.item.activity?.isRead;
	const isReminded = props.item.activity?.isReminded;
	const itemImages = props.item.images;
	const id = props.item.id;
	const title = props.item.title;
	const versionNumber = props.item.versionNumber;

	const handleUpdateActivity = (read: boolean) => {
		if (props.handleUpdateActivity) {
			props.handleUpdateActivity(id, read);
		}
	};

	const handleBulletOnClick = ({itemIndex}: any) => {
		setIndex(itemIndex);
	};

	const images = _.sortBy(itemImages, 'createdAt').map((image) => ({
		original: storageConfig.baseUrl + image.s3Filename,
		description: image.caption || " ",
		bulletOnClick: handleBulletOnClick
	}));

	React.useEffect(() => {
		const shouldMarkReminded = !isReminded;
		const shouldMarkRead = !isRead && (!images.length || index === images.length - 1);
		if (shouldMarkRead || shouldMarkReminded) {
			handleUpdateActivity(shouldMarkRead);
		}
	}, [index]);

	const showSeparateMoreButton = screen.width > 576 && index < images.length - 1;

	const handleClickNext = () => {
		setIndex(index + 1);
		gallery.current.slideToIndex(index + 1);
	};

	const handleClickMore = () => {
		if (!isRead && images.length && index !== images.length - 1) {
			handleUpdateActivity(true);
		}
		props.onClose();
		reduxStore.dispatch(navigate(`/release-notes/${id}`));
	};

	const handleDismiss = () => {
		props.onClose();
	};

	const handleArrowKeys = (e: any) => {
		if (e.keyCode === 39 && index < images.length - 1) {
			setIndex(index + 1);
		} else if (e.keyCode === 37 && index > 0) {
			setIndex(index - 1);
		}
		return;
	};
	document.onkeyup = (e) => handleArrowKeys(e);

	return (
		<div
			className="modal-body release-note-preview"
		>
			<h2 className="sm-margin">{`What's New in v${versionNumber}?`}</h2>
			<p>{title}</p>
			<div className="carousel-gallery">
				<ImageGallery
					ref={gallery}
					items={images}
					infinite={false}
					showThumbnails={false}
					showFullscreenButton={false}
					useTranslate3D={false}
					showPlayButton={false}
					showBullets={true}
					showNav={false}
					startIndex={index}
				/>
			</div>
			<div className="modal-actions release-note-preview">
				<Button
					size={'md'}
					type={'submit'}
					onClick={handleDismiss}
				>
					Dismiss
				</Button>
				<div>
					{
						showSeparateMoreButton && (
							<Button
								skin={IButtonSkin.WARNING}
								outlined
								size={'md'}
								type={'button'}
								onClick={handleClickMore}
							>
								Find Out More
							</Button>
						)
					}
					<Button
						skin={
							index === (images.length - 1) || !images.length
								? IButtonSkin.WARNING
								: IButtonSkin.ACTION
						}
						outlined={index < images.length - 1}
						size={'md'}
						type={'button'}
						onClick={
							index === (images.length - 1) || !images.length
								? handleClickMore
								: handleClickNext
						}
					>
						{
							index === (images.length - 1) || !images.length
								? "Find Out More"
								: "Next"
						}
					</Button>
				</div>
			</div>
		</div>
	);
});


export default ReleaseNotePreview;
