import * as React from 'react';

import { connect } from 'react-redux';
import { observer } from 'mobx-react';
import { Switch, Route, matchPath, match as IMatch, Redirect } from 'react-router-dom';
import * as authStorage from '@lms/utils/storage/auth.storage';

import applicationMetadataStore from '@lms/features/application.metadata/store';

import { IApplicationState } from '@lms/utils/state/application.state';
import { IUserPermission } from '@lms/utils/state/user.state';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { History } from 'history';

import { initLogin } from '@lms/redux/actions/auth.actions';
import '@lms/utils/analytics/trackers/engagement.tracker';

import {
	LeadListPage,
	CampaignSubmissionsListPage,
	CampaignSubmissionInfoPage,
	LeadInfoPage,
	LeadEntryPage,
	UserListPage,
	UserDetailsPage,
	UserAdditionPage,
	DashboardPage,
	NotFoundPage,
	ImportPage,
	ImportInfoPage,
	InternalToolsPage,
	OfficesListPage,
	OfficeInfoPage,
	ReleaseNotesListPage,
	ReleaseNoteInfoPage
} from '@lms/views/pages';

import { CalendarPage, SettingsPage } from '@lms/views/pages/account.page';

import GenericLoader from '@lms/views/elements/loaders/generic.loader';

import RestrictedRoute from '@lms/views/components/route/restricted.route.helper';
import Sidebar from '@lms/views/modules/sidebar';
import MainHeader from '@lms/views/modules/header';
import SystemNotification from '@lms/views/components/system.notification';
import SystemNotificationMobx from '@lms/features/system.notifications/views/notification';
// import SurveyPopup from '@lms/views/components/survey.popup';
import ReleaseNotePopup from '@lms/views/components/release.note.popup';
import * as styles from './application.styles.scss';

interface IRouteProps {
	match: IMatch<any>;
	history: History;
}

interface IStateProps {
	isAuthenticated: boolean;
	applicationFinishedLoading: boolean;
}

interface IDispatchProps {
	navigateToDefault: () => void;
	initLogin: () => void;
}

type IProps = IRouteProps & IStateProps & IDispatchProps;

/**
 * @function
 * @param {IApplicationState} state
 * @returns {IStateProps}
 */
function mapStateToProps(state: IApplicationState): IStateProps {
	return {
		isAuthenticated: state.user.isAuthenticated,
		applicationFinishedLoading: state.metadata.applicationFinishedLoading
	};
}

/**
 * @function
 * @param dispatch
 * @returns {IDispatchProps}
 */
function mapDispatchToProps(dispatch: any): IDispatchProps {
	return {
		navigateToDefault: () => dispatch(navigate('/leads')),
		initLogin: () => dispatch(initLogin())
	};
}

@observer class Application extends React.Component<IProps> {
	public getParams = (pathname: string) => {
		const matchProfile = matchPath(pathname, {
			path: pathname,
			exact: true
		});
		return matchProfile;
	};

	public UNSAFE_componentWillMount() {
		const { pathname } = this.props.history.location;
		const rootPath = '/';
		const currentParams = this.getParams(pathname);

		if (!this.props.isAuthenticated) {
			if (currentParams && currentParams.isExact) {
				authStorage.persistRedirectRoute(currentParams.url);
			}
			return this.props.initLogin();
		}

		if (currentParams.url === rootPath) {
			this.props.navigateToDefault();
		}
	}

	public render(): React.ReactNode {
		const selectors = styles as any;

		if (!this.props.applicationFinishedLoading) {
			return null;
		}

		if (applicationMetadataStore.hasLoadingFailed) {
			return (
				<GenericLoader error={true} />
			);
		}

		return (
			<div className={selectors.pageWrapper}>
				<Sidebar />
				<div className={selectors.pageBody}>
					<MainHeader />
					{
						// Disabled until the next survey
						// <SurveyPopup
						// 	id="panama"
						// 	url="https://form.typeform.com/to/PK6iRuAP"
						// 	message="Help Us Improve! Click to Start Survey on Generating Merchant Applications to the Sales Center"
						// />
					}
					{
						<ReleaseNotePopup />
					}
					<SystemNotification />
					<SystemNotificationMobx />
					<Switch>
						<RestrictedRoute exact requireAuthentication path="/leads" component={LeadListPage} />
						<RestrictedRoute exact requireAuthentication path="/leads/:id" component={LeadInfoPage} />
						<RestrictedRoute exact requireAuthentication path="/labels/:id" component={LeadListPage} />

						<RestrictedRoute
							exact
							requireAuthentication
							path="/campaign-submissions"
							requirePermissions={[IUserPermission.MANAGE_CAMPAIGN_SUBMISSIONS]}
							component={CampaignSubmissionsListPage}
						/>

						<RestrictedRoute
							exact
							requireAuthentication
							path="/campaign-submissions/:id"
							requirePermissions={[IUserPermission.MANAGE_CAMPAIGN_SUBMISSIONS]}
							component={CampaignSubmissionInfoPage}
						/>

						<RestrictedRoute exact requireAuthentication path="/add-lead" component={LeadEntryPage} />
						<RestrictedRoute exact requireAuthentication path="/import/:id" component={ImportInfoPage} />
						<RestrictedRoute exact requireAuthentication path="/import" component={ImportPage} />

						<RestrictedRoute
							exact
							requireAuthentication
							requirePermissions={[IUserPermission.SEE_USER_LIST]}
							path="/users"
							component={UserListPage}
						/>

						<RestrictedRoute
							exact
							requireAuthentication
							requirePermissions={[IUserPermission.SEE_OFFICE_LIST]}
							path="/offices"
							component={OfficesListPage}
						/>

						<RestrictedRoute
							exact
							requireAuthentication
							path="/offices/:code"
							component={OfficeInfoPage}
							requirePermissions={[IUserPermission.SEE_OFFICE_LIST]}
						/>

						<RestrictedRoute exact requireAuthentication path="/users/:id" component={UserDetailsPage} />

						<RestrictedRoute
							exact
							requireAuthentication
							requirePermissions={[IUserPermission.CREATE_USERS]}
							path="/add-user"
							component={UserAdditionPage}
						/>

						<RestrictedRoute
							exact
							requireAuthentication
							requirePermissions={[IUserPermission.ACCESS_INTERNAL_TOOLS]}
							path="/tools"
							component={InternalToolsPage}
						/>

						<RestrictedRoute
							exact
							path="/release-notes"
							component={ReleaseNotesListPage}
						/>

						<RestrictedRoute
							exact
							path="/release-notes/:id"
							component={ReleaseNoteInfoPage}
						/>

						<Route exact requireAuthentication path="/settings">
							<Redirect to="/account" />
						</Route>

						<RestrictedRoute exact requireAuthentication path="/account" component={SettingsPage} />
						<RestrictedRoute exact requireAuthentication path="/account/calendar" component={CalendarPage} />

						<RestrictedRoute exact requireAuthentication path="/dashboard" component={DashboardPage} />
						<Route path="*" component={NotFoundPage} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default connect<IStateProps, IDispatchProps, any>(
	mapStateToProps,
	mapDispatchToProps
)(Application);
