import * as apiClient from '@lms/services/clients/lms.api.client';
import { IDepartmentRecord } from '@lms/typings/records/department.record';

interface IDepartmentsResponse {
	total: number;
	results: IDepartmentRecord[];
}

/**
 * @function
 * @async
 * @returns {Promise<IDepartmentsResponse>}
 */
export function getDepartments(queryParams?: object): Promise<IDepartmentsResponse> {
	return apiClient.get('/departments', queryParams);
}

/**
 * @function
 * @param {number} id
 * @param {object} payload
 * @param {object} params
 * @returns {Promise<IDepartmentRecord>}
 */
export function updateDepartment(id: number, payload: object, params?: object): Promise<IDepartmentRecord> {
	return apiClient.patchJson(`/departments/${id}`, payload, params);
}

/**
 * @function
 * @param {object} payload
 * @returns {Promise<IDepartmentRecord>}
 */
export function postDepartments(payload: { name: string }[]): Promise<IDepartmentRecord[]> {
	return apiClient.postJson(`/departments/`, payload);
}

/**
 * @function
 * @param {number} id
 * @returns {Promise<IDepartmentRecord>}
 */
export function deleteDepartment(id: number): Promise<IDepartmentRecord> {
	return apiClient.deleteResource(`/departments/${id}`);
}
