import * as React from 'react';
import * as styles from './styles.scss';
import { observer } from 'mobx-react';
import { EventItems } from './event.items';
import eventStore from '@lms/stores/event.store';
import { navigateToSelectedLead } from '@lms/models/lead.model/utils';
import { EventModel } from '@lms/models/event.model';

interface IProps {
	onClick?: (item: EventModel) => void;
	onClose?: () => void;
}

const EventsTray: React.FC<IProps> = observer((props: IProps) => {
	const selectors = styles as any;
	const [height, setHeight] = React.useState(null);

	const updateHeight = () => {
		setHeight(window.innerHeight - 65);
	};

	React.useEffect(() => {
		updateHeight();
		window.addEventListener('resize', updateHeight);

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	});

	const handleClick = (item: EventModel) => {
		navigateToSelectedLead(item.lead.id);
		props.onClose();
	};

	if (!eventStore.items) {
		return null;
	}

	return (
		<>
			<div
				className={`${selectors.container} sidebar-tray`}
				style={{maxHeight: `${height}px`}}
			>
				{
					eventStore.items.length > 0
						? (
							<>
								<EventItems
									onClick={handleClick}
									onClose={props.onClose}
									items={eventStore.items}
								/>
							</>
						)
						: <div className={selectors.noItems}>You have no upcoming events</div>
				}

			</div>
		</>
	);
});

export default EventsTray;
