import * as React from 'react';
import { connect } from 'react-redux';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

interface IStateProps {
	loadingMessage: string;
}

type IProps = IStateProps;

/**
 * Injects "loading" message and "loading" error into the component
 * from the dictionary in application state
 *
 * @function
 * @param {IApplicationState} state
 * @returns {IStateProps}
 */
function mapStateToProps(): IStateProps {
	return {
		loadingMessage: getDictionaryValue('messages.loadingAuth')
	};
}

const AuthLoader: React.SFC<IProps> = (props) => {
	return (
		<div>
			<h1>{props.loadingMessage}</h1>
		</div>
	);
};

export default connect<IStateProps, any, any>(mapStateToProps)(AuthLoader);
