import timezoneStore from './timezones/store';
import { observable, action, runInAction } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import * as _ from 'lodash';
import metadataStore from '@lms/stores/metadata.store';
import { getLabels } from '@lms/services/lead.labels.service';
import { ILabelModel, LabelModel } from '@lms/models/label.model';

// TODO use this for app metadata instead of redux in the future
export class ApplicationMetadataStore {
	@observable public isApplicationLoading: boolean;
	@observable public hasLoadingFailed: boolean;
	@observable public labels: ILabelModel[];

	constructor() {
		eventBus.register(this);

		this.isApplicationLoading = true;
		this.hasLoadingFailed = false;
		this.labels = [];

		this.handleAuthenticationSuccess = this.handleAuthenticationSuccess.bind(this);
	}

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public loadApplicationMetadata = async () => {
		this.isApplicationLoading = true;

		try {
			// TODO move all redux metadata here
			await Promise.all([
				metadataStore.fetchAll(),
				timezoneStore.fetchTimezones(),
				this.fetchLabels()
			]);

			runInAction(() => {
				this.isApplicationLoading = false;
				this.hasLoadingFailed = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isApplicationLoading = false;
				this.hasLoadingFailed = true;
			});

			throw e;
		}
	};

	public getItem = (items: any, id: number) => {
		return _.find(items, {id});
	};

	@action public prependItem = (items: any, item: ILabelModel) => {
		items.unshift(item);
	};

	@action public deleteItem = (items: any, id: number) => {
		const index = _.findIndex(items, {id});
		if (index !== -1) {
			items.splice(index, 1);
		}
	};

	@action public updateItem = (items: any, item: ILabelModel) => {
		const index = _.findIndex(items, {id: item.id});
		_.set(items, index, item);
	};

	public getLabel = (id?: number) => {
		if (!id) {
			return null;
		}
		return this.getItem(this.labels, id);
	};

	public addLabel = (item: ILabelModel) => {
		this.prependItem(this.labels, item);
	};

	public deleteLabel = (id: number) => {
		this.deleteItem(this.labels, id);
	};

	public updateLabel = (item: ILabelModel) => {
		this.updateItem(this.labels, item);
	};

	@action public fetchLabels = async () => {
		try {
			const labels = await getLabels();

			runInAction(() => {
				this.labels = _.map(labels, (label: ILabelModel) => new LabelModel(label));
			});
		} catch (e) {
			throw e;
		}
	};

	/**
	 * Listens for a successful authentication of the user and commands
	 * to load the application metadata required for rendering and interacting
	 * with the application (analogous to INITIALIZE_APPLICATION//LOAD_RESOURCES
	 * action in redux)
	 *
	 * @function
	 */
	@subscribe('user.authenticationSuccess')
	private handleAuthenticationSuccess() {
		this.loadApplicationMetadata();
	}
}

const applicationMetadataStore = new ApplicationMetadataStore();
export default applicationMetadataStore;
