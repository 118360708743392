import * as NotificationType from '@harbortouch/lms-enums/lib/enums/notification.type.enum';
import { INotificationRecord } from '@lms/typings/records/notification.record';

interface IProps {
	prefix?: string;
	param1?: string;

	action?: string;
	param2?: string;

	oldValue?: string;
	fileName?: string;
	newValue?: string;

	joint?: string;

	param3?: string;

	sufix?: string;

	icon: string;
}

export const getNotificationText = (notification: INotificationRecord) => {
	const { parameters } = notification;

	const notificationsMessages = {
		[(NotificationType as any).LEAD_ASSIGNED]:
			{
				prefix: "Lead ",
				param1: parameters.entityName,
				action: " was assigned to ",
				param2: parameters.assigneeFullName,
				joint: " by ",
				param3: parameters.authorFullName,
				icon: "fa-user-plus"
			},
		[(NotificationType as any).LEAD_UNASSIGNED]:
			{
				prefix: "You were unassigned from ",
				param1: parameters.entityName,
				joint: " by ",
				param3: parameters.authorFullName,
				icon: "fa-user-minus"
			},
		[(NotificationType as any).LEAD_ASSIGNED_SECONDARY]:
			{
				prefix: "You were assigned as a secondary assignee to ",
				param1: parameters.entityName,
				joint: " by ",
				param3: parameters.authorFullName,
				icon: "fa-user-plus"
			},
		[(NotificationType as any).LEAD_UNASSIGNED_SECONDARY]:
			{
				prefix: "Lead ",
				param1: parameters.entityName,
				action: " was unassigned from ",
				param2: parameters.assigneeFullName,
				joint: " by ",
				param3: parameters.authorFullName,
				icon: "fa-user-minus"
			},
		[(NotificationType as any).LEAD_STATUS_CHANGED]:
			{
				prefix: "Lead status of ",
				param1: parameters.entityName,
				action: " was changed from ",
				oldValue: parameters.oldValue,
				joint: " to ",
				newValue: parameters.newValue,
				icon: "fa-edit"
			},
		[(NotificationType as any).LEAD_TYPE_CHANGED]:
			{
				prefix: "Lead type of ",
				param1: parameters.entityName,
				action: " was changed from ",
				oldValue: parameters.oldValue,
				joint: " to ",
				newValue: `$parameters.newValue.`,
				icon: "fa-edit"
			},
		[(NotificationType as any).LEAD_DELETED]:
			{
				prefix: "Lead ",
				param1: parameters.leadName,
				action: " was deleted from the system.",
				icon: "fa-minus-square"
			},
		[(NotificationType as any).ATTACHMENT_ADDED]:
			{
				param1: parameters.authorFullName,
				action: " attached ",
				fileName: parameters.attachmentFilename,
				joint: " to ",
				param3: parameters.entityName,
				sufix: " lead.",
				icon: "fa-paperclip"
			},
		[(NotificationType as any).COMMENT_ADDED]:
			{
				param1: parameters.authorFullName,
				action: " left a comment on ",
				param2: parameters.entityName,
				sufix: " lead.",
				icon: "fa-comment-alt-plus"
			},
		[(NotificationType as any).APPOINTMENT_UPCOMING_ASSIGNEE]:
			{
				prefix: "Appointment with ",
				param1: parameters.lead && parameters.lead.entityName,
				action: " tomorrow at ",
				param2: `${parameters.appointment && parameters.appointment.localTime} (${parameters.appointment && parameters.appointment.timezone}).`,
				icon: "fa-calendar-day"
			},
		[(NotificationType as any).APPOINTMENT_NEEDS_UPDATE_ASSIGNEE]:
			{
				prefix: "Appointment with ",
				param1: parameters.lead && parameters.lead.entityName,
				action: " was ",
				param2: parameters.appointment && parameters.appointment.daysSince,
				sufix: " day(s) ago. Please update appointment status.",
				icon: "fa-calendar-exclamation"
			},
		[(NotificationType as any).ENTITY_CREATED]:
			{
				prefix: "Lead ",
				param1: parameters.entityName,
				action: " was created.",
				icon: "fa-plus-circle"
			},
		[(NotificationType as any).USER_DELETED_MANAGER]:
			{
				prefix: "User ",
				param1: parameters.deletedUser ? parameters.deletedUser.fullName : '',
				action: " was deleted from the system.",
				icon: "fa-minus-circle"
			},
		[(NotificationType as any).CAMPAIGN_SUBMISSION_CREATED]:
			{
				prefix: "Campaign Submission ",
				param1: parameters.submission ? parameters.submission.title : '',
				action: " was created.",
				icon: "fa-plus-square"
			},
		[(NotificationType as any).CAMPAIGN_SUBMISSION_STATUS_CHANGED]:
			{
				prefix: "Campaign Submission status of ",
				param1: parameters.submission ? parameters.submission.title : '',
				action: " was changed from ",
				param2: parameters.original ? parameters.original.status : '',
				joint: " to ",
				param3: parameters.submission ? parameters.submission.status : '',
				icon: "fa-edit"
			}
	};

	return notificationsMessages[notification.type as any];
};
