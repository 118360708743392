import * as React from 'react';
import { connect } from 'react-redux';
import Icon from '@lms/views/elements/icon-legacy';

import { IApplicationState } from '@lms/utils/state/application.state';
import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';
import * as styles from './styles.scss';

interface IStateProps {
	isVisible: boolean;
	message: string;
	skin: string;
}

type IProps = IStateProps;

/**
 * @function
 * @param {IApplicationState} state
 * @returns {IStateProps}
 */
function mapStateToProps(state: IApplicationState): IStateProps {
	const { systemNotification } = state;

	return {
		isVisible: systemNotification.isVisible,
		message: systemNotification.message,
		skin: systemNotification.skin
	};
}

/**
 * @deprecated
 * @param {IProps} props
 * @returns {any}
 * @constructor
 */
const SystemNotification: React.SFC<IProps> = (props: IProps) => {
	const selectors = styles as any;

	if (!props.isVisible) {
		return null;
	}

	return (
		<div
			className={`${selectors['system-notification']}`}
			data-skin={props.skin}
		>
			{
				props.skin === ISystemNotificationSkin.AFFIRMATIVE && (
					<Icon name="check circle" />
				)
			}

			{
				props.skin === ISystemNotificationSkin.NEGATIVE && (
					<Icon name="warning sign" />
				)
			}

			<span>{props.message}</span>
		</div>
	);
};

export default connect<IStateProps, any, any>(mapStateToProps)(SystemNotification);
