import * as userRoleEnum from '@harbortouch/lms-enums/lib/enums/user.role.enum';

export enum IUserRole {
	ADMIN = (userRoleEnum as any).ADMIN,
	GLOBAL_MG = (userRoleEnum as any).GLOBAL_MG,
	SENIOR_MG = (userRoleEnum as any).SENIOR_MG,

	CSM = (userRoleEnum as any).CSM,
	GROUP_MANAGER = (userRoleEnum as any).GROUP_MANAGER,
	OFFICE_MANAGER = (userRoleEnum as any).OFFICE_MANAGER,
	BRANCH_MANAGER = (userRoleEnum as any).BRANCH_MANAGER,
	SKYFORCE_MANAGER = (userRoleEnum as any).SKYFORCE_MANAGER,
	SKYTAB_MANAGER = (userRoleEnum as any).SKYTAB_MANAGER,

	SALES_REP = (userRoleEnum as any).SALES_REP,
	SKYFORCE_SALES_REP = (userRoleEnum as any).SKYFORCE_SALES_REP,
	SKYTAB_SALES_REP = (userRoleEnum as any).SKYTAB_SALES_REP,

	APPOINTMENT_SETTER = (userRoleEnum as any).APPOINTMENT_SETTER,
	QA = (userRoleEnum as any).QA
}
