import * as React from 'react';
import { observer } from 'mobx-react';
import LocalStore from './local.store';
import { DropdownValues } from './dropdown.values';
import { DropdownMenu, IMenuProps } from './dropdown.menu';

interface IProps {
	label: string;
	values?: any;
	allItemsText?: string;
	onChange?: (values: string[], actions?: any) => void;
	menuRender: (menuProps: IMenuProps) => React.ReactElement;
	handleRender?: (handleProps: IHandleProps) => React.ReactElement;
	initialValues?: any;
	nullOption?: string;
}

export interface IHandleProps {
	store: LocalStore;
}

export const Dropdown: React.FC<IProps> = observer((props: IProps) => {
	const localStore = new LocalStore(props.initialValues);
	const dropdownRef = React.useRef();

	const onReset = () => {
		localStore.setValues([]);
		props.onChange([]);
	};

	return (
		<div className={'dropdown-wrapper'} ref={dropdownRef}>
			{
				props.handleRender ? (
					props.handleRender({
						store: localStore
					})
				) : (
					<DropdownValues
						store={localStore}
						label={props.label}
						allItemsText={props.allItemsText}
						onReset={onReset}
						nullOption={props.nullOption}
					/>
				)
			}

			<DropdownMenu
				store={localStore}
				dropdownRef={dropdownRef}
				onChange={props.onChange}
				menuRender={props.menuRender}
			/>
		</div>
	);
});
