import * as React from 'react';
import * as styles from './styles.scss';
import { useOnClickOutside } from '@lms/views/utils/hooks/on.click.outside.hook';
import { observer } from 'mobx-react';
import { ILabelModel } from '@lms/models/label.model';
import { deleteLabel } from '@lms/services/lead.labels.service';
import applicationMetadataStore from '@lms/features/application.metadata/store';
import { IButtonSkin, Button } from '@lms/views/elements/button';

interface IProps {
	leadLabel: ILabelModel;
	onClose: () => void;
	onUpdate: () => void;
	triggerRef: any;
}

const LabelMenu: React.FC<IProps> = observer((props: IProps) => {
	const selectors = styles as any;
	const selfRef = React.useRef<HTMLDivElement>(null);
	const [showConfirm, setShowConfirm] = React.useState(false);
	useOnClickOutside([props.triggerRef, selfRef], props.onClose);

	const handleUpdateClick = () => {
		props.onUpdate();
		props.onClose();
	};

	const handleDeleteClick = () => {
		setShowConfirm(true);
	};

	const handleConfirmDelete = () => {
		setShowConfirm(true);
		props.onClose();
		deleteLabel(props.leadLabel.id);
		applicationMetadataStore.deleteLabel(props.leadLabel.id);
	};

	return (
		<div className={selectors.list} ref={selfRef}>
			{
				showConfirm ? (
					<>
						<div className={selectors.confirm}>
							Are you sure you would like to delete this label?
						</div>
						<div className={selectors.confirmButtons}>
							<Button
								skin={IButtonSkin.DANGER}
								size={'sm'}
								onClick={handleConfirmDelete}
							>
								Delete
							</Button>
							<Button
								skin={IButtonSkin.ACTION}
								outlined
								size={'sm'}
								onClick={props.onClose}
							>
								Cancel
							</Button>
						</div>
					</>
				) : (
					<>
						<div className={selectors.label} onClick={handleUpdateClick}>
							Update
						</div>

						<div className={selectors.label} onClick={handleDeleteClick}>
							Delete
						</div>
					</>
				)
			}
		</div>
	);
});

export default LabelMenu;
