import { computed, observable } from 'mobx';

/**
 * Forcefully extends the target object with the observable
 * properties, overriding any intersection properties in the
 * target object.
 *
 * To be used as a safeguard from mobx failing to add an observable property
 * when there's already an existing property descriptor on the target object.
 * This is why historically this function was named `safelyExtendObservable`.
 *
 * Warning: this is a rather heavy operation.
 *
 * @function
 * @param {object} target
 * @param {object} properties
 */
/* TODO write test for this! very important*/
export function forcefullyExtendObservable(target: object, properties: object) {
	for (const property in properties) {
		if (!properties.hasOwnProperty(property)) {
			return;
		}

		const descriptor = Object.getOwnPropertyDescriptor(properties, property);
		Object.defineProperty(target, property, descriptor);

		/* MobX requires to decorate get/set properties with `computed` decorator */
		const decorator = descriptor.get ? computed : observable;

		/*
		 * Manually apply decorator to target property. "as any" required
		 * to allow for the fourth parameter (`applyImmediately`) which is undeclared in public api
		 */
		const resultDescriptor = (decorator as any)(target, property, descriptor, true);

		if (resultDescriptor) {
			Object.defineProperty(target, property, resultDescriptor);
		}
	}
}
