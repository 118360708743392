import * as apiClient from '@lms/services/clients/lms.api.client';
import { IReleaseNoteRecord } from '@lms/typings/records/release-note.record';
import { IReleaseNoteInfo } from '@lms/typings/responses/release-note-info.response';

interface IReleaseNotesResponse {
	total: number;
	results: IReleaseNoteRecord[];
}

export function getReleaseNotes(queryParams?: object): Promise<IReleaseNotesResponse> {
	return apiClient.get('/admin/release-notes', queryParams);
}

export function updateReleaseNote(id: number, payload: object, params?: object): Promise<IReleaseNoteRecord> {
	return apiClient.patchJson(`/admin/release-notes/${id}`, payload, params);
}

export function postReleaseNotes(payload: { name: string }[]): Promise<IReleaseNoteRecord[]> {
	return apiClient.postJson(`/admin/release-notes`, payload);
}

export function deleteReleaseNote(id: number): Promise<IReleaseNoteRecord> {
	return apiClient.deleteResource(`/admin/release-notes/${id}`);
}

export function getReleaseNotesHistory(queryParams?: object): Promise<IReleaseNotesResponse> {
	return apiClient.get('/me/release-notes', queryParams);
}

export function getReleaseNoteInfo (id: number, queryParams?: object): Promise<IReleaseNoteRecord> {
	return apiClient.get(`/me/release-notes/${id}`, queryParams);
}

export function updateReleaseNoteActivity(id: number, payload: object, params?: object): Promise<IReleaseNoteRecord> {
	return apiClient.patchJson(`/me/release-notes/${id}/activity`, payload, params);
}

export function getReleaseNoteInfoResponse (queryParams?: object): Promise<IReleaseNoteInfo> {
	return apiClient.get('/me/release-notes-activity', queryParams);
}
