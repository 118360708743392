import axios from 'axios';

export class CancellationController {
	private cancelToken = axios.CancelToken;
	private signal = this.cancelToken.source();

	public abortPendingRequest = () => {
		return this.signal.cancel();
	};

	public getToken = () => this.signal.token;
};
