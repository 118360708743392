import * as React from 'react';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { logOutUser } from '@lms/redux/actions/auth.actions';
import FeedbackModal from "@lms/views/components/modal/feedback/feedback.modal";

const UserMenu: React.FC = () => {
	const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
	const reduxStore = getStoreProxy();

	const handleSettingsClick = () => {
		reduxStore.dispatch(navigate('/settings'));
	};

	const handleLogOutClick = () => {
		reduxStore.dispatch(logOutUser());
	};

	const handleFeedbackClick = () => {
		setShowFeedbackModal(true);
	};

	return (
		<>
			<div  className="submenu-item" onClick={handleSettingsClick} data-test="settings-button">
				Settings
			</div>
			<div className="submenu-item" onClick={handleFeedbackClick} data-test="feedback-button">
				Give Feedback
			</div>
			<div className="submenu-item" onClick={handleLogOutClick} data-test="logout-button">
				Sign out
			</div>
			{showFeedbackModal && (
				<FeedbackModal onClose={() => setShowFeedbackModal(false)} />
			)}
		</>
	);
};

export default UserMenu;
