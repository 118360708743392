import * as queryBuilder from 'objection-find-query-builder';
import * as _ from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { IEventRecord } from '@lms/typings/records/event.record';
import { EventModel } from '@lms/models/event.model';
import EventStatus from '@harbortouch/lms-enums/lib/enums/event.status.enum';
import { getEvents, postEvent, patchEvent, deleteEvent } from '@lms/services/event.service';
import { eventBus } from 'mobx-event-bus2';

export class EventStore {
	@observable public total = 0;
	@observable public items: EventModel[];

	private itemsOffset = 0;
	private itemsPerLoad = 50;

	constructor() {
		eventBus.register(this);
	}

	public getPaginationQuery = () => {
		const builder = queryBuilder.builder();

		return builder
			.eager(['lead'])
			.inSet('status', [EventStatus.SCHEDULED, EventStatus.CONFIRMED])
			.greaterThanOrEqual('date', (new Date()).toISOString())
			.rangeStart(this.itemsOffset)
			.rangeEnd(this.itemsOffset + (this.itemsPerLoad - 1))
			.orderByAsc('date')
			.build();
	};

	@action public fetch = async () => {
		const { results, total } = await getEvents(this.getPaginationQuery());

		runInAction(() => {
			this.itemsOffset = 0;
			this.total = total;
			this.items = _.map(results, (item: IEventRecord) => new EventModel(item));
		});
	};

	public create = async (values: IEventRecord) => {
		const result = await postEvent(values);
		this.fetch();
		return result;
	};

	public update = async (id: number, values: Partial<IEventRecord>) => {
		const result = await patchEvent(id, values);
		this.fetch();
		return result;
	};

	public delete = async (id: number) => {
		const result = await deleteEvent(id);
		this.fetch();
		return result;
	};
}

export default new EventStore();
