import * as _ from 'lodash';
import { action, runInAction } from 'mobx';
import { saveAs as saveFileAs } from 'file-saver';

import { IAttachmentRecord } from '@lms/typings/records/attachment.record';
import { getLeadAttachmentData, patchAttachment } from '@lms/services/lead.service';
import { forcefullyExtendObservable } from '@lms/utils/model.utils';

export interface ILeadAttachmentModel extends IAttachmentRecord {
	saveToDevice: () => void;
	updateDescription: (value: any) => Promise<any>;
}

export class LeadAttachmentModel implements ILeadAttachmentModel {
	public readonly id: number;

	constructor(json: IAttachmentRecord) {
		forcefullyExtendObservable(this, json);
	}

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	public saveToDevice = async () => {
		try {
			const { id, filename, leadId } = (this as ILeadAttachmentModel);

			const attachmentData = await getLeadAttachmentData(leadId, id);
			saveFileAs(attachmentData, filename);
		} catch (error) {
			throw new Error(error);
		}
	};

	/**
	 * @function
	 * @param {string} value
	 * @returns {Promise<void>}
	 */
	@action public updateDescription = async (value: string) => {
		try {
			const { id, leadId } = (this as ILeadAttachmentModel);
			const patchedAttachmentRecord = await patchAttachment(leadId, id, { description: value });

			runInAction(() => {
				_.assign(this, patchedAttachmentRecord);
			});
		} catch (error) {
			throw new Error(error);
		}
	};
}
