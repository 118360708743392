import { isBlankString } from '@lms/utils/string.utils';
import { Location as IHistoryLocation } from 'history';

interface IQueryParameters {
	[name: string]: any;
}

/**
 * Builds an encoded request query string with
 * encoded parameter values
 *
 * @function
 * @param {object} queryParameters
 * @returns {string}
 */
export function buildQueryString(queryParameters: IQueryParameters): string {
	const paramNames = Object.keys(queryParameters);
	const encodedParameters = paramNames.map((name) => {
		return `${encodeURIComponent(name)}=${encodeURIComponent(queryParameters[name])}`;
	});

	return encodedParameters.join('&');
}

/**
 * Parses a query search string and returns an object containing
 * query parameter key-value pairs
 *
 * @function
 * @param {string} queryString - Query search string, e.g. `?key=value`
 * @returns {any}
 */
export function getQueryParams(queryString: string): any {
	if (isBlankString(queryString)) {
		return null;
	}

	const queryParameters = queryString
		.slice(queryString.indexOf('?') + 1)
		.split('&');

	return queryParameters.reduce((parameters: any, parameter) => {
		const [ key, val ] = parameter.split('=');

		parameters[key] = decodeURIComponent(val);
		return parameters;
	}, {});
}

/**
 * Extracts the query string from a location object and transforms
 * it into a parameter object
 *
 * @function
 * @param {Location} location
 * @returns {any}
 */
export function getQueryParamsFromLocation(location: Partial<IHistoryLocation>) {
	const queryString = location.search;
	return getQueryParams(queryString);
}
