import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import LocalStore from './local.store';

interface IProps {
	label: string;
	store: LocalStore;
	allItemsText?: string;
	onReset: () => void;
	nullOption?: string;
}

export const DropdownValues: React.FC<IProps> = observer((props: IProps) => {
	const [values, setValues] = React.useState(props.store.values);

	React.useEffect(() => {
		setValues(props.store.values);
	}, [props.store.values]);

	const hasNullValue = values === 'isNull';
	const hasValues = (values && _.isArray(values) && !_.isEmpty(values)) || hasNullValue;

	const Values = () => {
		if (!hasValues) {
			return (
				<span className="filter-values">
					{props.allItemsText || 'All'}
					<i className={'fal fa-chevron-down filter-icon'}></i>
				</span>
			);
		}

		return (
			<span className="filter-values">
				{
					hasNullValue
						? props.nullOption
						: values.length > 1 ? `${values.length} selected` : values[0].label
				}
				<i className={'fal fa-times-circle filter-icon filter-reset'} onClick={resetValues}></i>
			</span>
		);
	};

	const resetValues = (event: React.SyntheticEvent) => {
		props.onReset();
		event.stopPropagation();
	};

	const onClick = () => {
		props.store.setIsOpen(!props.store.isOpen);
	};

	return (
		<div
			data-active={props.store.isOpen}
			onClick={onClick}
			className={'filter-values-wrapper'}
			data-has-values={hasValues}
		>
			<span className="filter-label">{props.label}:</span>
			<Values />
		</div>
	);
});
