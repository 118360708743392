import * as React from 'react';
import * as styles from './notifications.scss';
import { observer } from 'mobx-react';
import notificationsStore, { NotificationsStore } from './notifications.store';
import { NotificationItems } from './notifications.item';
import { RefreshNotifications } from './refresh.notifications';
import { INotificationRecord } from '@lms/typings/records/notification.record.d';

interface IProps {
	onNotificationClick?: (item: INotificationRecord) => void;
	onClose?: () => void;
}

export const NotificationsTray: React.FC<IProps> = observer((props: IProps) => {
	const selectors = styles as any;
	const store: NotificationsStore = React.useContext(notificationsStore);
	const [height, setHeight] = React.useState(null);

	if (!store.notifications) {
		return null;
	}

	const handleRefreshNotificationsClick = () => {
		store.refreshNotifications();
	};

	const updateHeight = () => {
		setHeight(window.innerHeight - 65);
	};

	React.useEffect(() => {
		updateHeight();
		window.addEventListener('resize', updateHeight);

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	});

	return (
		<>
			<div
				className={`${selectors.container} sidebar-tray`}
				style={{maxHeight: `${height}px`}}
			>
				{
					store.shouldRefreshNotifications && (
						<RefreshNotifications
							className={selectors.newNotificationsDisclaimer}
							handleRefreshClick={() => handleRefreshNotificationsClick()}
						/>
					)
				}

				<NotificationItems onNotificationClick={props.onNotificationClick} onClose={props.onClose} />

				{
					store.notifications.length === 0 && (
						<div className={selectors.noNotifications}>You have no notifications</div>
					)
				}

			</div>
		</>
	);
});
