import * as Moment from 'moment';

const MAX_INACTIVITY_TIME_MINS = 30;

/**
 * @private
 */
let _tokenExpirationTime: Moment.Moment;

/**
 * @private
 */
let _lastActiveTime: Moment.Moment;

/**
 * @function
 * @param {number} expiration
 */
export function setTokenExpirationTime(expiration: number) {
	if (expiration) {
		_tokenExpirationTime = Moment.unix(expiration);
		bumpLastActiveTime();
	} else {
		_tokenExpirationTime = null;
		_lastActiveTime = null;
	}
}

/**
 * @function
 */
export function bumpLastActiveTime() {
	_lastActiveTime = Moment();
}

/**
 * @function
 * @returns {boolean}
 */
export function isTokenValid() {
	const now = Moment();

	if (!_tokenExpirationTime) {
		return false;
	}

	return (
		_tokenExpirationTime.isAfter(now) &&
		_lastActiveTime.add(MAX_INACTIVITY_TIME_MINS, 'minutes').isAfter(now)
	);
}
