const map = {
	labels: {
		comments: {
			reply: 'Reply',
			posted: 'wrote a comment',
			attached: 'Attached files:'
		},
		activityActions: {
			created: 'created lead',
			updated: 'made changes',
			deleted: 'deleted lead'
		},
		actionReasons: {
			null: 'unknown activity',
			address_added: 'added an address',
			address_updated: 'updated an address',
			address_deleted: 'deleted an address',
			attachment_added: 'attached a file',
			attachment_deleted: 'deleted a file',
			attachment_updated: 'updated a file',
			appointment_added: 'added an appointment with subject',
			appointment_updated: 'edited appointment with subject',
			appointment_deleted: 'deleted an appointment with subject',
			reminder_added: 'added a reminder with subject',
			reminder_updated: 'edited reminder with subject',
			reminder_deleted: 'deleted a reminder with subject',
			comment_added: 'left a comment on this lead',
			contact_added: 'added a contact for this lead',
			contact_updated: 'updated the contact of this lead',
			contact_deleted: 'deleted a contact',
			lead_added: 'created a lead',
			lead_deleted: 'deleted a lead',
			lead_assigned: 'assigned a lead to',
			lead_unassigned: 'unassigned a lead from',
			status_changed: 'has changed the status of a lead',
			field_changed: 'made changes',
			brand_changed: 'changed brand of a lead'
		},
		printableFilters: {
			status: 'Status: ',
			brand: 'Brand: ',
			assigneeRole: 'Assignee Role: ',
			dateRange: 'Date range: ',
			searchQuery: 'Search query: '
		},
		navigation: {
			dashboard: 'Dashboard',
			reports: 'Reports',
			leads: 'Leads',
			campaignSubmissions: 'Campaign Submissions',
			import: 'Import',
			users: 'Users',
			user: 'Account',
			tools: 'Internal tools',
			settings: 'Settings',
			signOut: 'Sign out',
			activities: 'Activities (n/a)',
			offices: 'Offices'
		},
		brands: {
			all_brands: 'All Brands',
			hrbortouch: 'Harbortouch',
			positouch: 'POSitouch',
			futurepos: 'Future POS',
			restrtmngr: 'Restaurant Manager',
			misc: 'Miscellaneous',
			shift4: 'Shift4',
			shift4shop: 'Shift4Shop',
			skytabpos: 'SkyTab POS'
		},
		channels: {
			ISO: 'ISO',
			DIRECT: 'Direct',
			POS: 'POS',
			ISV: 'ISV',
			MSP: 'MSP'
		},
		assigneeRoles: {
			all_assignee_roles: 'All Assignees',
			admin: 'Admin',
			global_mg: 'Global Manager',
			senior_mg: 'Senior Manager',
			csm: 'CSM',
			appointment_setter: 'Appontment Setter',
			qa: 'Quality Assurance',
			dealer: 'Dealer',
			sales_rep: 'Sales Rep'
		},
		businessTypes: {
			null: 'None',
			hospitality_restaurant: 'Hospitality restaurant',
			hospitality_bar: 'Hospitality bar',
			retail: 'Retail',
			medical: 'Medical',
			lodging: 'Lodging',
			fuel: 'Fuel',
			moto: 'Moto',
			ecommerce: 'E-commerce',
			supermarket: 'Supermarket',
			takeout: 'Takeout'
		},
		boolean: {
			true: 'Yes',
			false: 'No'
		},
		booleanMaybe: {
			yes: 'Yes',
			no: 'No',
			not_sure: 'Not Sure'
		},
		incentives: {
			free_emv: 'Free EMV readers',
			gateway: 'Secure gateway P2PE gateway included',
			special_pos_price: 'Special price on the POS System'
		},
		leadSources: {
			null: 'None',
			rep_submitted_list: 'Submitted by representative',
			five9: 'Imported from Five9',
			admin: 'Added by an Administrator',
			global_mg: 'Added by a Global Manager',
			isv: 'Added by an ISV',
			senior_mg: 'Added by a Senior Manager',
			csm: 'Added by a CSM',
			dealer_manager: 'Added by a Dealer Manager',
			appointment_setter: 'Added by an Appointment Setter',
			qa: 'Added by a QA',
			dealer: 'Added by a Dealer',
			dealer_ds: 'Added by a Dealer (DS)',
			dealer_iso: 'Added by a Dealer (ISO)',
			sales_rep: 'Added by a Sales Rep'
		},
		leadAssignment: {
			candidate: {
				name: 'Name',
				role: 'Role',
				distance: 'Distance',
				lastVisit: 'Last Visit',
				status: 'Status',
				closingRatio: 'Closing ratio'
			}
		},
		leadOfficeAssignment: {
			item: {
				name: 'Name',
				code: 'Code',
				distance: 'Distance',
				address: 'Address',
				brand: 'Brand',
				status: 'Status'
			}
		},
		leadEntry: {
			attachments: 'Attachments'
		},
		leadInfo: {
			sectionTitle: 'Lead Info',
			creatorName: 'Creator',
			opportunityInformation: 'Opportunity Info',
			audioRecordings: 'Call Records',
			audioRecord: 'Record at {date}, duration: {duration}',
			recordDescription: 'Notes',
			genericPlaceholder: 'Select',
			assigneePlaceholder: 'None',
			addressPlaceholder: 'Search by name, location, zip, or address...',
			changeAssignee: 'New Assignee',
			commentPlaceholder: 'Write a comment...',
			merchantInformation: 'Merchant Info',
			appointments: 'Appointments',
			activityLog: 'Activity log',
			activityLogDisclaimer: 'Please allow up to 5 minutes for changes to appear. Showing lead activity as recorded from 05/09/2019.',
			generalInformation: 'Your information',
			contactInformation: 'Contact Info',
			plainFileAttachments: 'Other files',
			statementAttachments: 'Statements',
			comments: 'Comments',
			changeStatus: 'Change status',
			changeStatusAndMid: 'Change status/MID',
			installationProgressTooltip: 'If set to "Yes", the following statuses: "Installation Assigned", "Installation Scheduled", "Installation Complete", "Bonus Submitted for Payment" will be added to the lead status list',
			applicationInProgressTooltip: 'The application in the Sales Center is in progress. Therefore, making changes to certain fields and statuses of the lead is currently not possible. If you would like to make changes, go to the application in the Sales Center.',
			changeMid: 'Change MID'
		},
		userDetails: {
			activeUser: 'Active user',
			generalInformation: 'Your Account Details',
			notifications: 'Notifications',
			permissionSettings: 'Permission settings',
			employeeInformation: 'Employee information',
			deleteUser: 'Delete user',
			genericPlaceholder: 'Select',
			addressPlaceholder: 'Search by name, location, zip, or address...',
			permissions: {
				MANAGE_USER_PERMISSIONS: 'Allow to manage user permissions',
				MANAGE_CAMPAIGN_SUBMISSIONS: 'Allow to access and manage campaign submissions',
				RECEIVE_INSTALLATION_COMPLETE_NOTIFICATIONS_OF_ALL_LEADS: 'Receive notification when status of any lead is changed to installation complete',
				RECEIVE_CAMPAIGN_SUBMISSION_STATUS_CHANGED_NOTIFICATIONS: 'Receive notification when status of any campaign submission is changed',
				CHANGE_LEAD_MID: 'Allow to recover MIDs from leads',
				UNLOCK_APPLICATION: 'Allow to unlock leads that were sent to the Sales Center',
				ACCESS_INTERNAL_TOOLS: 'Allow to access and use internal tools'
			}
		},
		offices: {
			list: {
				name: 'Name',
				code: 'Code',
				address: 'Address',
				brand: 'Brand',
				csm: 'Managing CSM'
			},
			attributes: {
				name: 'Name',
				code: 'Office Code',
				brand: 'Brand',
				channel: 'Channel',
				address: 'Address',
				primaryCsm: 'Managing CSM',
				secondaryCsms: 'Secondary CSMs',
				users: 'Office users'
			}
		},
		leads: {
			deleteLead: 'Delete lead',
			datePickerTabs: {
				createdAt: 'Created at',
				updatedAt: 'Updated at'
			},
			appointments: {
				edit: 'Edit Appointment',
				add: 'Add Appointment',
				date: 'Date and Time',
				subject: 'Subject',
				statusLabel: 'Status',
				timeZone: 'Time zone',
				appointment: 'Appointment',
				initialAppointment: 'Has initial appointment been set?',
				comment: 'Comment',
				notificationDisclaimerForAssignee: '* You will be receiving reminders about upcoming \"Confirmed\" appointments and past appointments which are not \"Completed\" or \"Cancelled\".',
				notificationDisclaimerForRegular: '* The assignee will be receiving reminders about upcoming \"Confirmed\" appointments and past appointments which are not \"Completed\" or \"Cancelled\".',
				historyTableHeadings: {
					status: 'Status',
					subject: 'Subject',
					date: 'Date',
					timezone: 'Time zone'
				},
				upcoming: {
					label: '(Upcoming)'
				},
				status: {
					scheduled: 'Scheduled',
					confirmed: 'Confirmed',
					completed: 'Completed',
					cancelled: 'Canceled'
				}
			},
			duplicates: {
				full_match: 'Lead duplicate found',
				partial_match: 'Possible lead duplicate found'
			},
			list: {
				all_brands: 'All ISVs',
				all_assignee_roles: 'All Roles',
				all_status: 'All Status',
				lead: 'Lead',
				referenceNumber: 'Ref. #',
				promoType: 'Lead Source',
				updatedAt: 'Last Update',
				createdAt: 'Created At',
				status: 'Status',
				brand: 'ISV',
				assignee: 'Assignee',
				office: 'Office',
				createdBy: 'Created By',
				priority: 'Priority'
			},
			status: {
				all_status: 'All Status',
				new_lead: 'New Lead',
				viewed: 'Viewed',
				waiting_on_merchant: 'Waiting on Merchant',
				submitted: 'Lead Submitted',
				in_progress: 'In Progress',
				quote_sent: 'Quote Sent',
				application_sent: 'Application Sent',
				problematic: 'Need Assistance',
				submitted_to_underwriting: 'Submitted to Underwriting',
				declined_by_underwriting: 'Declined by Underwriting',
				dead: 'Dead',
				sold: 'Sold',
				installation_assigned: 'Installation Assigned',
				installation_scheduled: 'Installation Scheduled',
				installation_complete: 'Installation Complete',
				bonus_submitted_for_payment: 'Bonus Submitted for Payment',
				contacted: 'Contacted',
				duplicate: 'Duplicate Lead'
			},
			statusListHeader: {
				archived_statuses: 'Archived Statuses'
			},
			failureReason: {
				choosing_competitors_software: 'Choosing competitors software',
				under_contract: 'Under contract',
				will_not_switch_processors: 'Will not switch processors',
				pos_pricing: 'POS pricing',
				cc_pricing: 'CC pricing',
				staying_with_current_provider: 'Staying with current provider',
				merchant_closing_or_closed: 'Merchant closing/closed',
				merchant_selling_business: 'Merchant is selling the business',
				unresponsive: 'Unresponsive',
				no_reason_specified: 'No reason specified'
			},
			lastAction: {
				left_message_for_merchant: 'Left Message for Merchant',
				setup_appointment: 'Spoke to Merchant, setup appointment',
				awaiting_appointment_confirmation: 'Spoke to Merchant, awaiting callback to set appointment',
				awaiting_feedback_before_quote: 'Met with merchant, awaiting feedback before sending quote',
				awaiting_appointment_reschedule: 'Merchant no-showed or rescheduled, awaiting next appointment',
				another_appointment_needed: 'Met with Merchant, another appointment needed',
				null: 'None'
			},
			soldType: {
				null: 'None',
				full_acquiring: 'Full Acquiring',
				gateway_only: 'Gateway Only'
			},
			attributes: {
				inProgressReason: 'Last Action',
				attachments: 'Attachment',
				appointments: 'Appointment',
				ownerIsPrimaryContact: 'Primary contact other than owner',
				assigneeId: 'Assigned to',
				assigneeSecondaryId: 'Secondary assignee',
				brandId: 'Brand',
				referenceNo: 'Reference #',
				titanFileId: 'File ID',
				titanFileIdNotAvailable: 'File ID not available',
				assignee: 'Assignee',
				assigneeSecondary: 'Secondary assignee',
				manager: 'CSM',
				isVerified: 'Verified',
				isActive: 'Active',
				leadSource: 'Source',
				createdAt: 'Created at',
				rank: 'Rank',
				status: 'Status',
				priority: 'Priority',
				leadType: 'Lead Type',
				merchantId: 'MID',
				businessTitle: 'DBA Name',
				opportunityType: 'Opportunity description',
				businessPhoneNumber: 'Business Phone Number',
				incentives: 'What incentives should we mention to the merchant?',
				acceptsCreditCards: 'Does the merchant accept credit cards?',
				acceptsGiftCards: 'Does the merchant accept gift cards?',
				giftCardProvider: 'What gift card provider do they use?',
				officeId: 'Office',
				office: 'Office',
				failureReason: 'Reasoning',
				businessLegalName: 'Legal Name',
				legalAddress: 'Legal Address',
				owner: 'Owner',
				monthlyVolume: 'Monthly Volume',
				creditCardResidualsAmount: 'How much residuals do you earn?',
				earnsResiduals: 'Do you currently earn residuals on this merchant?',
				averageTicket: 'Average Ticket',
				highTicket: 'High Ticket',
				ownerDesignation: 'Owner Designation',
				ownerGender: 'Owner Gender',
				yearsInBusiness: 'Years in Business',
				noOfEmployees: 'Number of Employees',
				businessType: 'Business Type',
				estimatedCloseDate: 'Estimated Close Date',
				percentageToClose: 'Estimated opportunity % to close',
				acceptsCreditCard: 'Accepts Credit Cards',
				businessFederalTaxId: 'Federal Tax ID',
				website: 'Website',
				referralType: 'Referral Type',
				address: 'DBA Address',
				latitude: 'Latitude',
				longitude: 'Longtitude',
				contactMethod: 'Contact Method',
				phone: 'Phone',
				fax: 'Fax',
				email: 'E-Mail',
				label: 'Lead Label',
				locationType: 'Location Type',
				IUSANumber: 'infoGROUP Number',
				employeeSizeRange: 'Employee Size Range',
				salesVolumeRange: 'Sales Volume Range',
				primarySICCode: 'Primary SIC Code',
				primarySICDescription: 'Primary SIC Description',
				promoType: 'Lead Source',
				closedBy: 'Closed by',
				ownerContactsAttribute: 'Owner Contacts',
				primaryContactsAttribute: 'Primary Contacts',
				earnsCreditCardResiduals: 'Do you currently earn residuals on this merchant?',
				ccMonthlyVolume: 'Monthly Volume',
				ccAverageTicket: 'Average Ticket',
				ccHighTicket: 'High Ticket',
				promoTypeId: 'Lead Source',
				isEnterprise: 'Enterprise',
				isInstallationProgressTracked: 'Require dealer to update installation progress',
				totalMIDs: 'Total MID\'s',
				externalId: 'External ID',
				ownerContacts: {
					name: 'Owner Name',
					phone: 'Owner Phone',
					email: 'Owner Email'
				},
				primaryContacts: {
					name: 'Primary Contact',
					phone: 'Primary Phone',
					email: 'Primary Email'
				},
				isSubmittedToSalesCenter: 'Is Submitted to Sales Center'
			},
			filter: {
				search: 'Search for...',
				searchByAssignee: 'Search by assignee...',
				createdAt: 'Created during',
				statusUpdatedAt: 'Updated during',
				datePickerPlaceholder: 'Select date range...'
			},
			priority: {
				standard: 'Standard',
				asap: 'ASAP',
				urgent: 'Urgent'
			},
			leadType: {
				pos: 'POS',
				payments: 'Payments',
				pos_and_payments: 'POS & Payments',
				not_sure: 'Not Sure'
			},
			opportunityTypes: {
				null: 'None',
				software_and_payments: 'Software + Payments',
				pos_as_a_service: 'POS as a Service',
				terminal_ECR: 'Terminal/ECR',
				upgrade_to_payments: 'Upgrade to Payments',
				payments_3rd_party: '3rd Party Integration to Payments',
				gateway_only: 'Gateway Only',
				ownership_change: 'Ownership Change',
				processor_change: 'Processor Change',
				add_on_equipment: 'Add on Equipment for Existing MID',
				change_of_service: 'Change of Service for Existing MID',
				interface_swap: 'Interface Swap',
				change_of_service_not_processor: 'Change of Service (not a processor change)',
				not_sure: 'Not Sure'
			}
		},
		import: {
			steps: {
				upload: 'Upload data',
				map: 'Map fields',
				review: 'Review leads'
			},
			mapping: {
				attrPlaceholder: 'Select a known attribute',
				leadAttributes: {
					officeId: 'Office Code',
					businessTitle: 'DBA Name',
					priority: 'Priority',
					businessPhoneNumber: 'Business Phone Number',
					yearsInBusiness: 'Years in Business',
					acceptsCreditCards: 'Accepts Credit Cards',
					acceptsGiftCards: 'Accepts Gifts Cards',
					giftCardProvider: 'Gift Card Provider',
					businessLegalName: 'Business Legal Name',
					businessFederalTaxId: 'Federal Tax ID',
					ccMonthlyVolume: 'CC Monthly Volume',
					ccAverageTicket: 'CC Average Ticket',
					ccHighTicket: 'CC High Ticket',
					creditCardResidualsAmount: 'CC Residuals Amount',
					earnsCreditCardResiduals: 'Earn CC Residuals',
					address: {
						state: 'State',
						city: 'City',
						street: 'Street',
						zip: 'ZIP'
					},
					legalAddress: {
						city: 'Legal Address City',
						street: 'Legal Address Street',
						zip: 'Legal Address ZIP',
						state: 'Legal Address State'
					},
					ownerContacts: {
						name: 'Owner Name',
						phone: 'Owner Phone',
						mobile: 'Owner Mobile',
						email: 'Owner Email'
					},
					primaryContacts: {
						name: 'Primary Contact',
						phone: 'Primary Phone',
						mobile: 'Primary Mobile',
						email: 'Primary Email'
					}
				}
			}
		},
		users: {
			list: {
				userFullName: 'User',
				email: 'Email',
				brand: 'ISV',
				role: 'Role',
				employeeId: 'ID',
				status: 'Status',
				lastVisit: 'Last visit',
				all_brands: 'All Brands',
				all_roles: 'All Roles'
			},
			notification_levels: {
				off: 'Off',
				critical_only: 'Critical only',
				all: 'All'
			},
			notification_descriptions: {
				off: 'You will not receive any notifications except system warnings and important reminders.',
				critical_only: 'You will receive notifications only for activity directly related to the sales workflow, e.g., being assigned a lead.',
				all: 'You will receive notifications for any activity related to your leads or user accounts.'
			},
			appointment_reminders: {
				upcoming: 'Upcoming appointment reminder (the day before)',
				past: 'Past appointment reminder (the day after)'
			},
			overridePassword: {
				headingTitle: 'Password',
				disclaimer: 'A strong password contains a mix of numbers, letters, and symbols. It is hard to guess, does not resemble a real word, and is only used for this account.',
				newPassword: 'New password',
				confirmPassword: 'Confirm password',
				currentPassword: 'Current password',
				overridePassword: 'Override password'
			},
			attributes: {
				firstName: 'First Name',
				lastName: 'Last Name',
				loginEmail: 'Email',
				primaryContacts: {
					phone: 'Phone Number'
				},
				address: 'Address',
				officeId: 'Office',
				salesRepId: 'Sales Rep ID',
				brandId: 'Brand',
				role: 'Role',
				managerId: 'Assign CSM',
				manager: 'Manager',
				timezone: 'Timezone',
				primaryOffices: 'Primary Office',
				secondaryOffices: 'Secondary Office',
				isActive: 'Active',
				isVerified: 'Verified',
				notificationLevel: 'Notification Level',
				upcomingAppointmentReminderHours: 'Upcoming appointment reminder time',
				pastAppointmentReminderHours: 'Past appointment reminder time',
				isEnterpriseExecutive: 'Enterprise Executive'
			},
			status: {
				active: 'Active',
				unauthorized: 'Unauthorized',
				deactivated: 'Deactivated'
			},
			filter: {
				search: 'Search for...'
			}
		},
		dashboard: {
			updatedLeads: 'Leads',
			soldLeads: 'Sold',
			deadLeads: 'Dead',
			recentLeads: {
				heading: 'Most recent deals',
				submitted: 'Most recent submitted deals',
				sold: 'Most recent sold deals',
				dead: 'Most recent dead deals',
				empty: 'Nothing here.'
			},
			topUsers: {
				topSubmitting: 'Most deals started by',
				topSelling: 'Most deals sold by',
				topLosing: 'Most deals lost by',
				empty: 'Nothing here.'
			},
			filter: {
				all_assignee_roles: 'All Roles',
				all_brands: 'All ISVs',
				all_users: 'All Users',
				all_promo_types: 'All Lead Sources',
				datePickerPlaceholder: 'Select date range...'
			}
		},
		campaignSubmissions: {
			status: {
				new: 'New',
				converted: 'Lead Created',
				deleted: 'Declined'
			},
			reason: {
				not_qualified: 'Not Qualified',
				duplicate: 'Duplicate',
				spam: 'Spam',
				null: 'None'
			},
			attributes: {
				id: 'ID',
				title: 'Title',
				status: 'Status',
				createdAt: 'Created',
				content: 'Content',
				leadId: 'Lead',
				source: 'Source',
				subject: 'Subject',
				reason: 'Decline Reason',
				comment: 'Comment',
				reviewer: 'Reviewed by'
			},
			declineModal: {
				title: 'Decline Submission',
				subTitle: 'Please select one of the reasons why you choose to decline this submission. You can add extra information to the open comment below.',
				commentPlaceholder: 'Add any additional comment to explain the reason of decline',
				disclaimer: '* Confirm decline of the submission? You will not be able to undo this action.'
			}
		},
		userAddition: {
			assignManager: 'Assign {name}',
			genericAssignManager: 'Assign Manager'
		},
		resetPassword: {
			newPassword: 'Password',
			confirmPassword: 'Confirm password'
		},
		changePassword: {
			currentPassword: 'Current password',
			newPassword: 'New password',
			confirmPassword: 'Confirm password'
		},
		changeEmail: {
			newEmail: 'New email',
			confirmNewEmail: 'Confirm email'
		},
		forgotPassword: {
			email: 'Email address'
		},
		modals: {
			changePassword: {
				title: 'Change password'
			},
			changeEmail: {
				title: 'Change email'
			},
			deactivateAccount: {
				title: 'Deactivate account'
			},
			deleteUser: {
				title: 'Delete user'
			},
			leadDeletion: {
				title: 'Delete lead'
			}
		},
		promoTypes: {
			title: 'Manage lead sources',
			addPromoType: 'Add lead source'
		},
		settings: {
			changePasswordHeading: 'Password',
			deactivateAccountHeading: 'Deactivate Account'
		}
	},
	messages: {
		loadingGeneric: 'Loading...',
		loadingAddress: 'Loading suggestions...',
		leadsWelcome: 'Leads',
		usersWelcome: 'Users',
		officesWelcome: 'Offices',
		loadingAuth: 'Authorizing...',
		loadingResetPassword: 'Checking some things...',
		loadingFive9: 'Importing lead from Five9...',
		justUpdated: 'now',
		notAvailable: 'n/a',
		none: 'none',
		yes: 'Yes',
		no: 'No',
		from: 'From: ',
		to: 'To: ',
		leads: {
			list: {
				assigneeRoleOption: 'Assignee: {role}'
			}
		},
		appointments: {
			disclaimer: 'Please select the merchant’s time zone'
		},
		comments: {
			noComments: 'No comments.'
		},
		settings: {
			welcome: 'Settings',
			changePassword: 'A strong password contains a mix of numbers, letters, and symbols. It is hard to guess, does not resemble a real word, and is only used for this account.',
			changeEmail: 'Enter a new email address that you would like to use with your Lead Management System account. You will no longer be able to log in with {email}.',
			deactivateAccount: 'Deactivating your LMS account means you will be unable to receive or recover any of your account information.',
			deactivateAccountWarning: 'Are you sure you want to deactivate your account?'
		},
		import: {
			instructions: {
				text: '<strong>Important!</strong><br />Here are a few tips to help you make sure your data is imported correctly:\n\n<ul><li>Only <strong>CSV</strong> (.csv) and <strong>Excel</strong> (.xlsx) are supported for import. Any other file type will fail;</li><li>If you\'re trying to import a CSV file, make sure that it\'s a valid CSV format (no unnecessary commas, no header-value mismatch, etc.);</li><li>Don\'t leave empty header names in your Excel or CSV files. Our system is unable to parse them. Instead, name them in a human-readable format.</li></ul>',
				downloadCSV: 'Download example .CSV file: ',
				downloadExcel: 'Download example .XLSX spreadsheet: ',
				exampleCSV: 'LeadImportExample.csv',
				exampleExcel: 'LeadImportExample.xlsx'
			},
			dropzone: {
				text: 'Drag file or click to upload'
			},
			mapping: {
				mapped: '(from \"{from}\")'
			}
		},
		dashboard: {
			welcome: 'Dashboard',
			filter: {
				userRoleOption: 'Role: {role}',
				resetFilter: 'Reset filter'
			}
		},
		reports: {
			welcome: 'Reports',
			filter: {
				userRoleOption: 'Role: {role}',
				resetFilter: 'Reset filter'
			},
			salesFunnel: {
				empty: 'No recorded sales history.'
			},
			leadTimeChart: {
				empty: 'No recorded sales history.',
				totalLifecycle: '{lifecycle} d.'
			},
			archivedLeads: {
				archivedAt: 'Closed at'
			}
		},
		leadFilter: {
			resetFilter: 'Reset filters',
			allLeads: 'Show archived leads',
			showingArchivedLeads: 'Showing archived leads',
			hidingArchivedLeads: 'Not showing archived leads'
		},
		leadAssignment: {
			welcome: 'Assign',
			officeWelcome: 'Assign Office',
			assignUser: 'Assign',
			assignOffice: 'Assign',
			assign: 'Assign',
			unassign: 'Unassign',
			unassignUser: 'Unassign',
			unassignOffice: 'Unassign',
			disableDistanceFilter: 'Show all assignees'
		},
		userFilter: {
			resetFilter: 'Reset filters'
		},
		userAddition: {
			permissionWarning: {
				title: 'Warning!',
				text: 'Users with this role can see top-level reporting, upload leads, assign leads, change status, comment, attach files, create tasks. This level of account access should only be given to your most trusted individuals.'
			}
		},
		userDetails: {
			deleteUserWarning: 'Are you sure you want to delete this user? Deleting a user is a permanent action and cannot be undone.'
		},
		leadDeletion: {
			warning: 'Are you sure you want to delete this lead? Deleting a lead is a permanent action and cannot be undone.'
		},
		leadInfo: {
			ownerIsNotPrimaryContact: 'Primary contact other than owner',
			legalAddressDifferentFromDbaAddress: 'Legal address different from DBA address',
			legalAddressIsSame: 'Legal address same as DBA address',
			emptyAttachments: 'No uploads.',
			uploadAudioRecords: 'Upload Call Records',
			noAudioRecords: 'No call records available.',
			changeStatus: 'Change status',
			change: {
				leadType: 'Change lead type',
				priority: 'Change priority'
			}
		},
		leadEntry: {
			attachmentsPrompt: 'Please upload any statements or other useful information and add comments with additional information not mentioned above.'
		},
		resetPassword: {
			title: 'Enter a new password',
			invalidToken: 'We\'re sorry, but the link you\'re trying to use has already expired.'
		},
		forgotPassword: {
			title: 'Forgot your password?',
			text: 'Enter the email address you used to register with Shift4 Payments and we\'ll send you instructions to reset your password.',
			resetInitSuccess: 'Thanks! You will receive an email shortly.'
		},
		promoTypes: {
			noPromoTypes: 'There are no lead sources added yet'
		},
		offices: {
			searchForOffices: 'Search by name or code...'
		},
		changeLeadStatus: {
			midChangeWarning: `To remove the MID, lead status has to be changed to any status except for 'Sold', 'Installation Assigned, Installation Scheduled, 'Installation Complete', or 'Bonus Submitted for Payment'`,
			archiveWarning: 'Note: moving the lead to this status will result in it being archived and you will no longer be able to see it on the Leads page.\n\nThe archived lead will be accessible through the Dashboard.'
		}
	},
	errors: {
		pageNotFound: '404 Page Not Found',
		loadingGeneric: 'Loading failed.',
		import: {
			mapping: {
				skipped: 'Will not be imported',
				unrecognized: '(Unrecognized column)',
				unmappedHeaders: 'Please map all lead fields before proceeding.'
			},
			HARD_DUPLICATE: 'Hard Duplicate',
			SOFT_DUPLICATE: 'Soft Duplicate',
			INVALID_DBA_NAME: 'DBA Name is missing',
			INVALID_BRAND: 'Brand is missing'
		},
		leadEntry: {
			partial_match: 'We think the lead you\'re trying to enter already exists within the Lead Management System. Would you still like to submit this lead?',
			full_match: 'The lead you\'re trying to enter already exists within the Lead Management System.',
			overrideHardDuplicate: 'The lead you\'re trying to enter already exists within the Lead Management System. Would you like to override and submit this lead regardless?'
		},
		appointmentValidation: {
			isMandatory: {
				subject: 'Appointment subject is required.',
				timezone: 'Timezone must be specified',
				comment: 'Appointment must have a comment'
			}
		},
		leadValidation: {
			sectionInvalid: 'Please fill in all required information in this section.',
			isMandatory: {
				businessTitle: 'Lead must have a business title.',
				leadType: 'Information about type of lead is required.',
				earnsCreditCardResiduals: 'Information about credit card residuals required.',
				creditCardResidualsAmount: 'We need to know how much residuals you earn on this merchant.',
				acceptsCreditCards: 'Information about credit cards required.',
				acceptsGiftCards: 'Information about gift cards required.',
				isShouldContactToDiscuss: 'We must know whether we should contact you to discuss the lead.',
				isStatementSubmitted: 'Information about lead statement required.',
				brandId: 'Please select a brand.'
			},
			isEmail: {
				ownerEmail: 'Business owner should have a valid email address.',
				primaryEmail: 'Primary contact should have a valid email address.'
			},
			isNumeric: {
				residualAmount: 'Amount of residuals should be a number.',
				yearsInBusiness: 'Number of years in business should be a number.'
			}
		},
		userValidation: {
			isMandatory: {
				firstName: 'User must have a first name.',
				brandId: 'User must be working with a Brand',
				lastName: 'User must have a last name.',
				email: 'User must have an email address.',
				address: 'Address is required.',
				role: 'User must have a role.',
				managerId: 'User must have a manager.',
				officeId: 'Office is required for Agent role users.'
			},
			isEmail: {
				email: 'User should have a valid email address.'
			},
			isUniqueEmail: {
				email: 'The email address you\'ve entered is already taken.'
			}
		},
		promoTypeValidation: {
			alreadyExists: 'This lead source already exists.',
			isMandatory: 'Please enter a lead source.'
		},
		leadInfo: {
			incompleteAddress: 'Please specify a more precise address for this lead to enable full functionality.',
			partialDuplicateNotice: `We think the lead you're trying to enter already exists within the Lead Management System.`,
			hardDuplicateNotice: 'A lead like this already exists within system.',
			hardDuplicateOverriden: 'A lead like this already exists within the system, but we\'ve saved your changes regardless.'
		},
		leadStatusChange: {
			isMandatory: {
				merchantId: 'Merchant ID is required.',
				soldReason: 'Type of sale is required.',
				failureReason: 'Please explain your reason for chaning the status of this lead to \"Dead\".',
				declineReason: 'Please explain why this lead was declined by underwriting.'
			},
			validMerchantId: 'Merchant ID must be a ten-digit numeric value.',
			uniqueMerchantId: 'The Merchant ID you\'ve entered is already registered within our system.',
			doesNotExistInTitan: 'The Merchant ID you\'ve entered does not exist within our system.'
		},
		userDetails: {
			userIsUnverified: 'Unauthorized – {name} has yet to confirm their email.'
		},
		userSettings: {
			incompleteAddress: 'Please fill out the Address field of your account to enable full assignment functionality.'
		},
		resetPassword: {
			passwordsMustMatch: 'Passwords must match.',
			isMandatory: {
				newPassword: 'You must enter a new password for your account.',
				newPasswordConfirmation: 'Please confirm your password.'
			}
		},
		forgotPassword: {
			isMandatory: {
				email: 'You must enter an email address.'
			},
			isEmail: {
				email: 'You must enter a valid email address.'
			}
		},
		overridePassword: {
			wrongCurrent: 'Your current password and the one you\'ve entered don\'t match.',
			passwordsMustMatch: 'Passwords must match.',
			isMandatory: {
				currentPassword: 'You must enter your current password.',
				newPassword: 'You must enter a new password.',
				confirmPassword: 'Please confirm your new password.'
			}
		},
		changePassword: {
			wrongCurrent: 'Your current password and the one you\'ve entered don\'t match.',
			passwordsMustMatch: 'Passwords must match.',
			isMandatory: {
				currentPassword: 'You must enter your current password.',
				newPassword: 'You must enter a new password.',
				confirmPassword: 'Please confirm your new password.'
			}
		},
		changeEmail: {
			emailsMustMatch: 'Emails must match.',
			isMandatory: {
				newEmail: 'You must enter a new email for your account.',
				confirmNewEmail: 'Please confirm your new email.'
			},
			isEmail: {
				newEmail: 'You must enter a valid email address.'
			},
			isUniqueEmail: {
				newEmail: 'The email address you\'ve entered is already taken.'
			}
		}
	},
	buttons: {
		leadInfo: {
			plain_file: 'Attach file',
			statement: 'Attach statement',
			uploadAudioRecord: 'Add record',
			uploadingAudioRecord: 'Uploading...',
			submitComment: 'Comment',
			submittingComment: 'Submitting...',
			attachToComment: 'Attach file',
			addAppointment: 'Create New Appointment',
			printLead: 'Print Lead',
			uploadPlainFile: 'Attach file',
			uploadStatement: 'Attach statement'
		},
		leadEntry: {
			submittingForm: 'Submitting...',
			partial_match: 'Submit',
			submitForm: 'Submit',
			full_match: 'Override & Submit',
			cancel: 'Cancel'
		},
		promoTypeManagement: {
			deletePromoType: 'Delete',
			addPromoType: 'Add',
			savePromoType: 'Save',
			editPromoType: 'Edit'
		},
		leadDeletion: {
			confirm: 'Delete',
			cancel: 'Cancel'
		},
		appointmentUpdating: {
			discard: 'Discard',
			save: 'Save',
			savingAppointment: 'Saving...'
		},
		addingAppointment: {
			discard: 'Discard',
			add: 'Add',
			submittingAppointment: 'Submitting...'
		},
		leadList: {
			addLead: 'Add Lead',
			printList: 'Print lead list'
		},
		userList: {
			addUser: 'Add User'
		},
		leadAddition: {
			submit: 'Add',
			submitting: 'Adding...'
		},
		userAddition: {
			submit: 'Add',
			submitting: 'Adding...'
		},
		import: {
			mapping: {
				edit: 'Edit',
				skip: 'Skip',
				save: 'Save'
			},
			nextStep: 'Next',
			finish: 'Finish',
			dropzone: {
				upload: 'Attach file'
			}
		},
		resetPassword: {
			submit: 'Reset password',
			submitting: 'Resetting...'
		},
		overridePassword: {
			initiate: 'Override password',
			submit: 'Override password',
			submitting: 'Changing...',
			cancel: 'Cancel'
		},
		changePassword: {
			initiate: 'Change password',
			submit: 'Change password',
			submitting: 'Changing...',
			cancel: 'Cancel'
		},
		changeLeadStatus: {
			initiate: 'Change status',
			submit: 'Confirm',
			submitting: 'Changing...',
			cancel: 'Cancel'
		},
		changeEmail: {
			initiate: 'Change Email',
			submit: 'Change email',
			submitting: 'Changing...',
			cancel: 'Cancel'
		},
		deactivateAccount: {
			initiate: 'Deactivate Account',
			confirm: 'Yes',
			cancel: 'No'
		},
		deleteUser: {
			initiate: 'Delete User',
			confirm: 'Yes',
			cancel: 'No'
		},
		forgotPassword: {
			submit: 'Reset password',
			submitting: 'Working on it...'
		}
	},
	lead: {
		filters: {
			'id': 'Ref. #',
			'assignee.fullName': 'assignee name',
			'assigneeSecondary.fullName': 'Secondary assignee name',
			'brand.name': 'brand',
			'brandId': 'brand',
			'merchantId': 'merchant ID',
			'promoType.name': 'lead source',
			'businessTitle': 'title',
			'businessPhoneNumber': 'business phone number',
			'businessLegalName': 'business legal name',
			'businessFederalTaxId': 'federal tax ID',
			'referralType': 'referral type',
			'ownerContacts.name': 'owner name',
			'ownerContacts.email': 'owner email',
			'ownerContacts.phone': 'owner phone',
			'primaryContacts.name': 'primary contact name',
			'primaryContacts.email': 'primary email',
			'primaryContacts.phone': 'primary phone',
			'createdBy.fullName': 'creator',

			'promoTypeId': 'lead source',
			'assigneeId': 'assignee',
			'assigneeSecondaryId': 'Secondary assignee',
			'status': 'status',
			'locations': 'city/state',
			'updatedAt': 'updated at',
			'createdAt': 'created at',
			'isArchived': 'show archived',
			'query': 'search query',
			'officeId': 'Office',
			'assignee.role': 'Role',
			'assigneeSecondary.role': 'Secondary assignee role'
		},
		attributes: {
			isEnterprise: {
				true: 'Enterprise',
				false: 'Non-enterprise'
			}
		},
		status: {
			all_status: 'All Status',
			new_lead: 'New Lead',
			viewed: 'Viewed',
			waiting_on_merchant: 'Waiting on Merchant',
			submitted: 'Lead Submitted',
			in_progress: 'In Progress',
			quote_sent: 'Quote Sent',
			application_sent: 'Application Sent',
			problematic: 'Need Assistance',
			submitted_to_underwriting: 'Submitted to Underwriting',
			declined_by_underwriting: 'Declined by Underwriting',
			dead: 'Dead',
			sold: 'Sold',
			installation_assigned: 'Installation Assigned',
			installation_scheduled: 'Installation Scheduled',
			installation_complete: 'Installation Complete',
			bonus_submitted_for_payment: 'Bonus Submitted for Payment',
			contacted: 'Contacted',
			duplicate: 'Duplicate Lead'
		},
		columnCategories: {
			'1.attributes': 'Lead Information',
			'2.assignee': 'Assignee',
			'2.assigneeSecondary': 'Secondary assignee',
			'2.creator': 'Creator',
			'3.office': 'Office',
			'3.merchant': 'Merchant Information',
			'4.contact': 'Contact Information',
			'5.opportunity': 'Opportunity Information'
		},
		columns: {
			id: 'Ref. #',
			atachments: 'Attachment',
			appointments: 'Appointment',
			ownerIsPrimaryContact: 'Owner is Primary Contact',
			assigneeId: 'Assigned to',
			assigneeSecondaryId: 'Secondary assignee',
			brandId: 'Brand',
			referenceNo: 'Reference #',
			titanFileId: 'File ID',
			titanFileIdNotAvailable: 'File ID not available',
			manager: 'CSM',
			isVerified: 'Verified',
			isActive: 'Active',
			leadSource: 'Source',
			createdAt: 'Created at',
			rank: 'Rank',
			status: 'Status',
			subStatus: 'Sub Status',
			statusUpdatedAt: 'Status Updated at',
			priority: 'Priority',
			leadType: 'Lead Type',
			merchantId: 'MID',
			businessTitle: 'DBA Name',
			opportunityType: 'Opportunity Description',
			businessPhoneNumber: 'Business Phone Number',
			incentives: 'Incentives',
			acceptsCreditCards: 'Accepts Credit Cards',
			acceptsGiftCards: 'Accepts Gift Cards',
			giftCardProvider: 'Gift Card Provider',
			officeId: 'Office',
			failureReason: 'Reasoning',
			businessLegalName: 'Legal Name',
			owner: 'Owner',
			monthlyVolume: 'Monthly Volume',
			creditCardResidualsAmount: 'Credit Card Residuals',
			earnsResiduals: 'Do you currently earn residuals on this merchant?',
			averageTicket: 'Average Ticket',
			highTicket: 'High Ticket',
			ownerDesignation: 'Owner Designation',
			ownerGender: 'Owner Gender',
			yearsInBusiness: 'Years in Business',
			noOfEmployees: 'Number of Employees',
			businessType: 'Business Type',
			estimatedCloseDate: 'Estimated Close Date',
			percentageToClose: 'Estimated opportunity % to close',
			acceptsCreditCard: 'Accepts Credit Cards',
			businessFederalTaxId: 'Federal Tax ID',
			website: 'Website',
			referralType: 'Referral Type',
			latitude: 'Latitude',
			longitude: 'Longtitude',
			contactMethod: 'Contact Method',
			phone: 'Phone',
			fax: 'Fax',
			email: 'E-Mail',
			locationType: 'Location Type',
			IUSANumber: 'infoGROUP Number',
			employeeSizeRange: 'Employee Size Range',
			salesVolumeRange: 'Sales Volume Range',
			primarySICCode: 'Primary SIC Code',
			primarySICDescription: 'Primary SIC Description',
			closedBy: 'Closed by',
			ownerContactsAttribute: 'Owner Contacts',
			primaryContactsAttribute: 'Primary Contacts',
			earnsCreditCardResiduals: 'Earns Residuals on this Merchant?',
			ccMonthlyVolume: 'Monthly Volume',
			ccAverageTicket: 'Average Ticket',
			ccHighTicket: 'High Ticket',
			promoTypeId: 'Lead Source',
			isEnterprise: 'Enterprise',
			isInstallationProgressTracked: 'Installation Progress Tracked',
			totalMIDs: 'Total MID\'s',
			externalId: 'External ID',
			updatedAt: 'Updated at',
			label: 'Label',
			ownerContacts: {
				name: 'Owner Name',
				phone: 'Owner Phone',
				email: 'Owner Email'
			},
			primaryContacts: {
				name: 'Primary Name',
				phone: 'Primary Phone',
				email: 'Primary Email'
			},
			assignee: {
				name: 'Assignee',
				nameEmail: 'Name / Email',
				role: 'Role',
				brandId: 'Brand',
				fullName: 'Full Name',
				firstName: 'First Name',
				lastName: 'Last Name',
				isActive: 'Is Active',
				isVerified: 'Is Verified',
				lastLoginAt: 'Last Login at',
				loginEmail: 'Email Address',
				officeId: 'Office Code'
			},
			enterpriseExecAssignee: {
				fullName: 'Enterprise Executive Full Name',
				loginEmail: 'Enterprise Executive Email'
			},
			assigneeSecondary: {
				name: 'Assignee',
				nameEmail: 'Name / Email',
				role: 'Role',
				brandId: 'Brand',
				fullName: 'Full Name',
				firstName: 'First Name',
				lastName: 'Last Name',
				isActive: 'Is Active',
				isVerified: 'Is Verified',
				lastLoginAt: 'Last Login at',
				loginEmail: 'Email Address',
				officeId: 'Office Code'
			},
			creator: {
				name: 'Creator',
				nameEmail: 'Name / Email',
				role: 'Role',
				brandId: 'Brand',
				fullName: 'Full Name',
				firstName: 'First Name',
				lastName: 'Last Name',
				isActive: 'Is Active',
				isVerified: 'Is Verified',
				lastLoginAt: 'Last Login at',
				loginEmail: 'Email Address',
				officeId: 'Office Code'
			},
			address: {
				fullAddress: 'DBA Address',
				zip: 'ZIP',
				cityState: 'City / State',
				city: 'City',
				country: 'Country',
				lat: 'Latitude',
				lng: 'Longitude',
				state: 'State',
				place: 'Place',
				street: 'Street'
			},
			legalAddress: {
				fullAddress: 'Legal Address',
				zip: 'Legal Address ZIP',
				city: 'Legal Address City',
				country: 'Legal Address Country',
				state: 'Legal Address State',
				place: 'Legal Address Place',
				street: 'Legal Address Street'
			},
			office: {
				name: 'Office Name',
				code: 'Office Code',
				department: {
					name: 'Sales Department'
				}
			},
			brand: {
				name: 'Brand'
			},
			promoType: {
				name: 'Lead Source'
			},
			lastComment: {
				text: 'Last Comment',
				createdBy: {
					fullName: 'Last Comment Author'
				}
			},
			lastCommentRepresentation: 'Last Comment'
		}
	},
	user: {
		labels: {
			offices: 'My Offices',
			officesDisclaimer: 'You have no offices assigned to You.'
		},
		attributes: {
			fullName: 'Name',
			firstName: 'First Name',
			lastName: 'Last Name',
			loginEmail: 'Email',
			primaryContacts: {
				phone: 'Phone Number'
			},
			address: 'Address',
			officeId: 'Office',
			offices: 'Offices',
			salesRepId: 'Sales Rep ID',
			brandId: 'Brand',
			role: 'Role',
			manager: 'Manager',
			lastVisit: 'Last visit',
			status: 'Status',
			isv: 'Brand',
			employeeId: 'ID',
			isEnterpriseExecutive: 'Enterprise Executive'
		},
		columnCategories: {
			attributes: 'Attributes'
		},
		form: {
			assignOffice: 'Assign Office',
			assignOffices: 'Assign Offices',
			assignManager: 'Assign Manager',
			secondaryOffices: 'Secondary Offices',
			enterpriseExecutive: 'Enterprise Executive'
		},
		status: {
			active: 'Active',
			unauthorized: 'Unauthorized',
			deactivated: 'Deactivated'
		},
		validation: {
			required: {
				firstName: 'User must have a first name.',
				brandId: 'User must be working with a Brand',
				lastName: 'User must have a last name.',
				email: 'User must have an email address.',
				salesRepId: 'User must have a Sales Rep ID',
				address: 'Address is required.',
				role: 'User must have a role.',
				managerId: 'User must have a manager.',
				officeId: 'Office is required for Agent role users.'
			},
			isEmail: 'User should have a valid email address.',
			isUniqueEmail: 'The email address you\'ve entered is already taken.',
			isValidSalesRepId: 'The current Sales Rep ID does not exist within our system. Please update the Sales Rep ID to be able to generate application directly to the Sales Center.'
		},
		warnings: {
			title: 'Warning!',
			permissions: 'Users with this role can see top-level reporting, upload leads, assign leads, change status, comment, attach files, create tasks. This level of account access should only be given to your most trusted individuals.'
		}
	},
	common: {
		placeholder: {
			address: 'Search by name, location, zip, or address...',
			generic: 'Select'
		},
		loaders: {
			address: 'Loading suggestions...'
		},
		data: {
			notAvailable: 'n/a'
		},
		pages: {
			headers: {
				users: 'Users',
				offices: 'Offices',
				leads: 'Leads',
				campaignSubmissions: 'Email Campaign Submissions',
				generalInformation: 'General Information',
				employeeInformation: 'Employee Information',
				permissions: 'Permissions',
				addUser: 'Add New User',
				releaseNotes: 'Release Notes History'
			},
			breadcrumbs: {
				update: 'Update',
				info: 'Info',
				create: 'Create'
			}
		},
		buttons: {
			save: 'Save',
			create: 'Create',
			addUser: 'Add User',
			changePassword: 'Change Password',
			activateUser: 'Activate User',
			deactivateUser: 'Deactivate User'
		},
		labels: {
			boolean: {
				yes: 'Yes',
				no: 'No',
				not_sure: 'Not Sure'
			},
			businessTypes: {
				null: 'None',
				hospitality_restaurant: 'Hospitality restaurant',
				hospitality_bar: 'Hospitality bar',
				retail: 'Retail',
				medical: 'Medical',
				lodging: 'Lodging',
				fuel: 'Fuel',
				moto: 'Moto',
				ecommerce: 'E-commerce',
				supermarket: 'Supermarket',
				takeout: 'Takeout'
			},
			incentives: {
				free_emv: 'Free EMV readers',
				gateway: 'Secure gateway P2PE gateway included',
				special_pos_price: 'Special price on the POS System'
			},
			priorities: {
				standard: 'Standard',
				asap: 'ASAP',
				urgent: 'Urgent'
			},
			leadTypes: {
				pos: 'POS',
				payments: 'Payments',
				pos_and_payments: 'POS & Payments',
				not_sure: 'Not Sure'
			},
			opportunityTypes: {
				null: 'None',
				software_and_payments: 'Software + Payments',
				pos_as_a_service: 'POS as a Service',
				terminal_ECR: 'Terminal/ECR',
				upgrade_to_payments: 'Upgrade to Payments',
				payments_3rd_party: '3rd Party Integration to Payments',
				gateway_only: 'Gateway Only',
				ownership_change: 'Ownership Change',
				processor_change: 'Processor Change',
				add_on_equipment: 'Add on Equipment for Existing MID',
				change_of_service: 'Change of Service for Existing MID',
				interface_swap: 'Interface Swap',
				change_of_service_not_processor: 'Change of Service (not a processor change)',
				not_sure: 'Not Sure'
			},
			roles: {
				admin: 'Admin',
				global_mg: 'Global Manager',
				isv: 'ISV',
				senior_mg: 'Senior Manager',
				group_manager: 'Group Manager',
				csm: 'CSM',
				dealer_manager: 'Dealer Manager',
				appointment_setter: 'Appontment Setter',
				qa: 'Quality Assurance',
				office_manager: 'Office Manager',
				skyforce_manager: 'SkyForce Direct',
				skytab_manager: 'SkyTab Partner',
				branch_manager: 'Branch Manager',
				dealer: 'Dealer',
				sales_rep: 'Sales Rep',
				skyforce_sales_rep: 'SkyForce Direct L1',
				skytab_sales_rep: 'SkyTab Partner L1',
				dealer_ds: 'Sales Rep(DS)',
				dealer_iso: 'Sales Rep(ISO)'
			},
			brands: {
				all_brands: 'All Brands',
				hrbortouch: 'Harbortouch',
				positouch: 'POSitouch',
				futurepos: 'Future POS',
				restrtmngr: 'Restaurant Manager',
				misc: 'Miscellaneous',
				shift4: 'Shift4',
				shift4shop: 'Shift4Shop',
				skytabpos: 'SkyTab POS'
			}
		}
	}
};

export default map;
