declare const module: any;

import { hot, setConfig } from 'react-hot-loader';
import { Switch, Route } from 'react-router-dom';
import * as React from 'react';

import AcceptAuthorizationRoute from '@lms/views/components/route/accept.auth.route.helper';
import AcceptFive9LeadRoute from '@lms/views/components/route/accept.five9.lead.route.helper';

import Application from '@lms/views/application.component';
import { ForgotPasswordPage, ResetPasswordPage } from '@lms/views/pages';

/**
 * Wrapper component for the whole application which helps
 * manage the multi-layer route architecture in our application.
 *
 * This wrapper manages the following routing scenarios:
 *
 * 1. `/accept-auth` – helper route for OAuth integration
 * 2. `/accept-lead` – helper route for Five9 integration
 * 3. `/reset-password` – password reset form
 * 4. `/forgot-password` – password reset request form
 * 3. The application itself – every other possible route
 *
 * @constructor
 * @type {React.SFC<any>}
 * @returns {JSX.Element}
 */
class ApplicationWrapper extends React.Component<any> {
	constructor(props: any) {
		super(props);
	}

	public componentDidCatch(error: any, info: any) {
		console.dir(error, info);
	}

	public render(): React.ReactNode {
		// Remove main loader
		document.querySelector('body').classList.remove('initialising');

		return (
			<div id="content">
				<Switch>
					<AcceptAuthorizationRoute exact path="/accept-auth" />
					<AcceptFive9LeadRoute path="/accept-lead" />

					<Route exact path="/reset-password" component={ResetPasswordPage} />
					<Route exact path="/forgot-password" component={ForgotPasswordPage} />

					<Route path="/" component={Application} />
				</Switch>
			</div>
		);
	}
}

setConfig({
	showReactDomPatchNotification: false
});

export default hot(module)(ApplicationWrapper);
