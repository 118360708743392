import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { IApplicationState } from '@lms/utils/state/application.state';
import { initLogin } from '@lms/redux/actions/auth.actions';
import { IUserPermission } from '@lms/utils/state/user.state';
import { userHasRequiredPermissions } from '@lms/utils/selectors/user.selectors';

interface IStateProps {
	userIsAuthenticated: boolean;
	userIsAuthorized: boolean;
}

interface IDispatchProps {
	initLogin: () => void;
}

interface IOwnProps {
	requireAuthentication?: boolean;
	requirePermissions?: IUserPermission[];

	exact?: boolean;
	path: string;
	component: React.ComponentType<any>;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

/**
 * @function
 * @param {IApplicationState} state
 * @param {IOwnProps} ownProps
 * @returns {IStateProps}
 */
function mapStateToProps(state: IApplicationState, ownProps: IOwnProps): IStateProps {
	const {
		requireAuthentication,
		requirePermissions
	} = ownProps;

	return {
		userIsAuthenticated: requireAuthentication ? state.user.isAuthenticated : true,
		userIsAuthorized: requirePermissions ? userHasRequiredPermissions(state, requirePermissions) : true
	};
}

/**
 * @function
 * @param {*} dispatch
 * @returns {IDispatchProps}
 */
function mapDispatchToProps(dispatch: any): IDispatchProps {
	return {
		initLogin: () => dispatch(initLogin())
	};
}

class RestrictedRoute extends React.Component<IProps, any> {
	public UNSAFE_componentWillMount(): void {
		if (!this.props.userIsAuthenticated || !this.props.userIsAuthorized) {
			this.props.initLogin();
		}
	}

	public render(): React.ReactNode {
		if (this.props.userIsAuthenticated && this.props.userIsAuthorized) {
			return (
				<Route
					path={this.props.path}
					exact={this.props.exact}
					component={this.props.component}
				/>
			);
		}

		return null;
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps
)(RestrictedRoute);
