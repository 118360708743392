import { delegate } from '@harbortouch/react-modules';
import GenericLoader from '@lms/views/elements/loaders/generic.loader';

export const SettingsPage = delegate({
	loader: () => import(/* webpackChunkName: "lead-info-page" */ '@lms/views/pages/account.page/settings'),
	loading: GenericLoader
});

export const CalendarPage = delegate({
	loader: () => import(/* webpackChunkName: "lead-info-page" */ '@lms/views/pages/account.page/calendar'),
	loading: GenericLoader
});
