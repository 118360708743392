import * as React from 'react';
import { observer } from 'mobx-react';
import { LeadLabelItem } from './label.item';
import Tippy from '@tippy.js/react';
import applicationMetadataStore from '@lms/features/application.metadata/store';
import { IButtonSkin, Button } from '@lms/views/elements/button';
import { ILabelModel } from '@lms/models/label.model';

const SHOW_MORE_BREAK = 5;

interface IProps {
	onSizeChange: () => void;
	onClickNewLabel: () => void;
	onClickUpdate: (label: ILabelModel) => void;
}


const LeadLabels: React.FC<IProps> = observer((props: IProps) => {
	const [showAll, setShowAll] = React.useState(false);
	const [labels] = React.useState(applicationMetadataStore.labels);

	const header = (
		<div className="labels-header">
			<span>LEAD LABELS</span>
			<i
				className={`fal fa-plus-circle`}
				onClick={props.onClickNewLabel}
			/>
		</div>
	);

	const newLabelButton = (
		<Button
			skin={IButtonSkin.WHITE}
			outlined
			size={'sm'}
			onClick={props.onClickNewLabel}
		>
			Create New Label
		</Button>
	);

	if (!labels || labels.length === 0) {
		return (
			<>
				{header}
				<div className="labels-empty">
					<p>You have no labels</p>
					{newLabelButton}
				</div>
			</>
		);
	}

	const labelItems = labels.map((label: ILabelModel, i: number) => {
		return (
			<LeadLabelItem
				leadLabel={label}
				key={label.id}
				onUpdate={() => props.onClickUpdate(label)}
				hidden={!showAll && i >= SHOW_MORE_BREAK}
			/>
		);
	});

	const handleShowMoreClick = () => {
		setShowAll(!showAll);
		props.onSizeChange();
	};

	return (
		<>
			{header}
			{labelItems}
			<div className="mobile-only label-new-button">
				{newLabelButton}
			</div>
			{
				labels.length > SHOW_MORE_BREAK &&
					<Tippy content={`Show ${showAll ? 'less' : 'more'}`} placement="right">
						<div className="labels-show-more" onClick={handleShowMoreClick}>
							<i className={`fal fa-chevron-${showAll ? 'up' : 'down'}`} />
							<span>Show {showAll ? 'less' : 'more'}...</span>
						</div>
					</Tippy>
			}
		</>
	);
});

export default LeadLabels;
