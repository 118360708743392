import { MiddlewareAPI, Dispatch } from 'redux';
import { eventBus } from 'mobx-event-bus2';

import { IAction } from '@harbortouch/react-modules';
import { IApplicationState } from '@lms/utils/state/application.state';

import {
	AUTH_ACTION_NAMES,
	IAuthenticatePayload,
	IAuthenticationPhase
} from '@lms/redux/actions/auth.actions';

import { IApplicationInitializationPhase, initializeApplication } from '@lms/redux/actions/metadata.actions';

export default
(store: MiddlewareAPI<IApplicationState>) =>
	(next: Dispatch<any>) =>
		(action: IAction<any>) => {
			next(action);

			switch (action.type) {
				case AUTH_ACTION_NAMES.AUTHENTICATE: {
					action = action as IAction<IAuthenticatePayload>;
					const { phase } = action.payload;

					switch (phase) {
						case IAuthenticationPhase.FINISH: {
							eventBus.post('user.authenticationSuccess', null);

							store.dispatch(initializeApplication({
								phase: IApplicationInitializationPhase.LOAD_RESOURCES
							}));

							break;
						}

						default: break;
					}

					break;
				}
			}

			return;
		};
