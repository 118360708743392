import * as React from 'react';

export const useOnKeyPress = (targetKey: string, handler: any, preventDefault?: boolean) => {
	React.useEffect(() => {
		const listener = (event: any) => {
			if (event.key === targetKey) {
				if (preventDefault) {
					event.preventDefault();
				}
				handler();
			}
		};

		window.addEventListener('keydown', listener);

		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [targetKey]);
};
