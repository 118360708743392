import * as _ from 'lodash';
import * as leadFailureReasonEnum from '@harbortouch/lms-enums/lib/enums/lead.failure-reason.enum';
import { IViewReadyEntity } from '@lms/utils/selectors/metadata.selectors';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

export enum ILeadFailureReason {
	CHOOSING_COMPETITORS_SOFTWARE = (leadFailureReasonEnum as any).CHOOSING_COMPETITORS_SOFTWARE,
	UNDER_CONTRACT = (leadFailureReasonEnum as any).UNDER_CONTRACT,
	WILL_NOT_SWITCH_PROCESSORS = (leadFailureReasonEnum as any).WILL_NOT_SWITCH_PROCESSORS,
	POS_PRICING = (leadFailureReasonEnum as any).POS_PRICING,
	CC_PRICING = (leadFailureReasonEnum as any).CC_PRICING,
	STAYING_WITH_CURRENT_PROVIDER = (leadFailureReasonEnum as any).STAYING_WITH_CURRENT_PROVIDER,
	MERCHANT_CLOSING_OR_CLOSED = (leadFailureReasonEnum as any).MERCHANT_CLOSING_OR_CLOSED,
	MERCHANT_SELLING_BUSINESS = (leadFailureReasonEnum as any).MERCHANT_SELLING_BUSINESS,
	UNRESPONSIVE = (leadFailureReasonEnum as any).UNRESPONSIVE,
	NO_REASON_SPECIFIED = (leadFailureReasonEnum as any).NO_REASON_SPECIFIED,
}

/**
 * Cache for lead status view projections
 *
 * @type {IViewReadyEntity[]}
 * @private
 */
let _viewReadyLeadFailureReasons: IViewReadyEntity[] | null = null;

/**
 * TODO refactor when redux is removed
 *
 * @function
 * @returns {IViewReadyEntity[]}
 */
export function getViewReadyLeadFailureReasons(): IViewReadyEntity[] {
	const failureReasons = _.values(leadFailureReasonEnum);

	if (!_viewReadyLeadFailureReasons) {
		_viewReadyLeadFailureReasons = failureReasons.map((name: string) => {
			return {
				value: name,
				label: getDictionaryValue(`labels.leads.failureReason.${name}`),
				attribute: 'failureReason'
			};
		});
	}

	return _viewReadyLeadFailureReasons;
}
