import { eventBus } from 'mobx-event-bus2';
import { action, observable, computed, runInAction } from 'mobx';
import * as attachmentTypeEnum from '@harbortouch/lms-enums/lib/enums/attachment.attachment-type.enum';

import { ILeadRecord } from '@lms/typings/records/lead.record';
import { IOfficeRecord } from '@lms/typings/records/office.record';
import { IUserRecord } from '@lms/typings/records/user.record';
import { patchLeadModelAttribute, getFailsafeLeadRecordFromJSON } from '@lms/models/lead.model/utils';
import IEventType from '@lms/enums/event.type.enum';
import {
	deleteLeadAttachment,
	postLeadStatement,
	postLeadPlainFile,
	postLeadAudioRecord,
	postLeadComment
} from '@lms/services/lead.service';

import {
	ILeadAttachmentModel,
	LeadAttachmentModel
} from '@lms/models/lead.attachment.model';

import { forcefullyExtendObservable } from '@lms/utils/model.utils';
import { ILeadsAuditEntryRecord } from '@lms/typings/records/lead-audit-entry.record';
import { ILeadCommentRecord } from '@lms/typings/records/lead-comment.record';
import * as queryBuilder from 'objection-find-query-builder';
import { EventModel } from '@lms/models/event.model';

export interface ILeadModel extends ILeadRecord {
	assignToUser: (user: Partial<IUserRecord>) => void;
	assignToSecondaryUser: (user: Partial<IUserRecord>) => void;
	assignToOffice: (office: IOfficeRecord) => void;

	releaseAssignee: () => void;
	releaseSecondaryUser: () => void;
	leadAttachments?: ILeadAttachmentModel[];
	appointments?: any[];
	reminders?: any[];

	attachStatements: (data: Blob[]) => Promise<any>;
	attachFiles: (data: Blob[]) => Promise<any>;
	attachAudioRecordings: (data: Blob[]) => Promise<any>;

	addComment: (text: string, attachments: Blob[]) => Promise<ILeadCommentRecord>;

	removeAttachment: (attachment: ILeadAttachmentModel) => void;
	addEvent: (type: IEventType, event: EventModel) => void;
	removeEvent: (eventId: number) => void;
	readonly fileAttachmentCount: number;
	readonly statementAttachmentCount: number;
}

const PLAIN_FILE_ATTACHMENT_TYPE = (attachmentTypeEnum as any).PLAIN_FILE;
const STATEMENT_ATTACHMENT_TYPE = (attachmentTypeEnum as any).STATEMENT;

function _getCountOfAttachmentsOfType(lead: ILeadModel, type: string) {
	return lead.leadAttachments
		.filter(({ attachmentType }) => attachmentType === type)
		.length;
}

export class LeadModel implements ILeadModel {
	public readonly id: number;
	public errors?: any[];
	@observable public leadAttachments?: ILeadAttachmentModel[];
	@observable public appointments?: any[];
	@observable public reminders?: any[];
	@observable public fullActionHistory?: ILeadsAuditEntryRecord[];

	@computed get fileAttachmentCount(): number {
		return _getCountOfAttachmentsOfType(this, PLAIN_FILE_ATTACHMENT_TYPE);
	}

	@computed get statementAttachmentCount(): number {
		return _getCountOfAttachmentsOfType(this, STATEMENT_ATTACHMENT_TYPE);
	}

	constructor(json: ILeadRecord) {
		if (json.appointments) {
			this.appointments = json.appointments.map((appointment) => new EventModel(appointment));
			delete json.appointments;
		}

		if (json.reminders) {
			this.reminders = json.reminders.map((event) => new EventModel(event));
			delete json.reminders;
		}

		if (json.leadAttachments) {
			this.leadAttachments = json.leadAttachments.map((attachment) => new LeadAttachmentModel(attachment));
			delete json.leadAttachments;
		}

		forcefullyExtendObservable(this, getFailsafeLeadRecordFromJSON(json));
	}

	/**
	 * @function
	 * @param {Partial<IUserRecord>} user
	 * @returns {Promise<void>}
	 */
	@action public assignToUser = (user: Partial<IUserRecord>) => {
		return patchLeadModelAttribute(this, 'assigneeId', user.id);
	};

	@action public assignToSecondaryUser = (user: Partial<IUserRecord>) => {
		return patchLeadModelAttribute(this, 'assigneeSecondaryId', user.id);
	};

	/**
	 * @function
	 * @param {IOfficeRecord} office
	 */
	@action public assignToOffice = (office: IOfficeRecord) => {
		return patchLeadModelAttribute(this, 'officeId', office.code);
	};

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public releaseAssignee = () => {
		return patchLeadModelAttribute(this, 'assigneeId', null);
	};

	@action public releaseSecondaryUser = () => {
		return patchLeadModelAttribute(this, 'assigneeSecondaryId', null);
	};

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public releaseOffice = () => {
		return patchLeadModelAttribute(this, 'officeId', null);
	};

	/**
	 * @function
	 * @param {Blob} data
	 * @returns {Promise<void>}
	 */
	@action public attachStatements = async (data: Blob[]) => {
		return this._uploadAttachments(data, postLeadStatement);
	};

	/**
	 * @function
	 * @param {Blob} data
	 * @returns {Promise<void>}
	 */
	@action public attachFiles = async (data: Blob[]) => {
		return this._uploadAttachments(data, postLeadPlainFile);
	};

	/**
	 * @function
	 * @param {Blob} data
	 * @returns {Promise<void>}
	 */
	@action public attachAudioRecordings = async (data: Blob[]) => {
		return this._uploadAttachments(data, postLeadAudioRecord);
	};

	/**
	 * @function
	 * @param {ILeadAttachmentModel} attachment
	 * @returns {Promise<void>}
	 */
	@action public removeAttachment = async (attachment: ILeadAttachmentModel) => {
		this.leadAttachments = this.leadAttachments.filter(({ id }) => id !== attachment.id);
		await deleteLeadAttachment(this.id, attachment.id);
	};

	/**
	 * @function
	 * @param {string} text
	 * @param {Blob[]} attachments
	 * @returns {Promise<ILeadCommentRecord>}
	 */
	@action public addComment = async (text: string, attachments: Blob[]) => {
		const builder = queryBuilder.builder();
		const query = builder
			.eager(['createdBy', 'attachments'])
			.build();

		const comment = await postLeadComment(this.id, text, attachments, query);
		eventBus.post('lead.commentAdded', { lead: this, comment });

		return comment;
	};

	/**
	 * @function
	 * @param {Blob[]} data
	 * @param uploadFunction
	 * @returns {Promise<void>}
	 */
	@action private _uploadAttachments = async (data: Blob[], uploadFunction: any) => {
		for (const file of data) {
			try {
				const { attachments } = await uploadFunction(this.id, file);
				const [attachment] = attachments;

				runInAction(() => {
					this.leadAttachments = [
						new LeadAttachmentModel(attachment),
						...this.leadAttachments
					];
				});
			} catch (error) {
				throw new Error(error);
			}
		}
	};

	@action public addEvent = (type: IEventType, event: EventModel) => {
		switch (type) {
			case IEventType.APPOINTMENT:
				this.appointments = [event, ...this.appointments];
				break;
			case IEventType.REMINDER:
				this.reminders = [event, ...this.reminders];
				break;
		}
	};

	@action public removeEvent = (eventId: number) => {
		this.reminders = this.reminders.filter(({ id }) => id !== eventId);
	};
}
