import * as React from 'react';
import { connect } from 'react-redux';

import { IFive9LeadRecord } from '@lms/typings/records/five9.lead.record';
import { acceptFive9Lead } from '@lms/redux/actions/five9.actions';
import { getQueryParamsFromLocation } from '@lms/utils/query.utils';

import Five9Loader from '@lms/views/elements/loaders/five9.loader';

interface IDispatchProps {
	acceptLead: (params: object) => void;
}

interface IOwnProps {
	path: string;

	location?: {
		search: string;
	};
}

type IProps = IDispatchProps & IOwnProps;

/**
 * Injects:
 *
 * 1. A method to dispatch `acceptLead` thunk to
 * post the lead that came in from Five9 to the LMS API
 *
 * @function
 * @param {*} dispatch
 * @param {IProps} ownProps
 * @returns {IDispatchProps}
 */
function mapDispatchToProps(dispatch: any): IDispatchProps {
	return {
		acceptLead: (params: object) => dispatch(acceptFive9Lead(params as IFive9LeadRecord))
	};
}

class AcceptFive9LeadRoute extends React.Component<IProps, any> {
	public UNSAFE_componentWillMount(): void {
		const queryParameters = getQueryParamsFromLocation(this.props.location);
		this.props.acceptLead(queryParameters);
	}

	public render(): React.ReactNode {
		return (
			<div>
				<Five9Loader />
			</div>
		);
	}
}

export default connect<any, IDispatchProps, IOwnProps>(
	null,
	mapDispatchToProps
)(AcceptFive9LeadRoute);
