import * as React from 'react';
import { connect } from 'react-redux';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

interface IStateProps {
	loadingMessage: string;
	errorMessage: string;
}

interface IOwnProps {
	error: boolean;
}

type IProps = IStateProps & IOwnProps;

/**
 * Injects "loading" message and "loading" error into the component
 * from the dictionary in application state
 *
 * @function
 * @param {IApplicationState} state
 * @returns {{loadingMessage: string, errorMessage: string}}
 */
function mapStateToProps() {
	return {
		loadingMessage: getDictionaryValue('messages.loadingGeneric'),
		errorMessage: getDictionaryValue('errors.loadingGeneric')
	};
}

const GenericLoader: React.SFC<IProps> = (props) => {
	if (props.error) {
		throw props.error;
	}

	return (
		<div>
			<h1>{props.loadingMessage}</h1>
		</div>
	);
};

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(GenericLoader);
