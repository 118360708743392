import * as _ from 'lodash';
import { isBlankString } from '@lms/utils/string.utils';

/**
 * @function
 * @param {string} value
 * @returns {string | null}
 */
export function sanitizeFormValue(value: string): string | null {
	if (
		typeof value === typeof(true) ||
		typeof value === typeof({})
	) {
		return value;
	}

	if (!value || isBlankString(value)) {
		return null;
	}

	return value;
}

/**
 * @function
 *
 * @export
 * @param {any} value
 * @returns {number}
 */
export function convertToCents(value: any) {
	return Math.floor(_.toNumber(value) * 100);
}

/**
 * @function
 *
 * @export
 * @param {any} value
 * @returns {number}
 */
export function convertToDollars(value: any) {
	return _.toNumber(value) / 100;
}
