// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2TEMwEy {\n  display: flex;\n  height: 100%;\n  width: 100%; }\n\n._3vex92X {\n  height: 100%;\n  flex: 0 auto;\n  overflow-y: auto;\n  min-width: 0;\n  width: 100%; }\n", "",{"version":3,"sources":["application.styles.scss"],"names":[],"mappings":"AAAA;EACC,aAAa;EACb,YAAY;EACZ,WAAW,EAAA;;AAGZ;EACC,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,WAAW,EAAA","file":"application.styles.scss","sourcesContent":[".pageWrapper {\n\tdisplay: flex;\n\theight: 100%;\n\twidth: 100%;\n}\n\n.pageBody {\n\theight: 100%;\n\tflex: 0 auto;\n\toverflow-y: auto;\n\tmin-width: 0;\n\twidth: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"pageWrapper": "_2TEMwEy",
	"pageBody": "_3vex92X"
};
module.exports = exports;
