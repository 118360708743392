import * as ReactGA from 'react-ga';
import { action, observable } from 'mobx';

import { ITransport } from '@lms/utils/analytics/transport/interfaces/transport';
import { IAnalyticsEvent } from '@lms/utils/analytics/events/analytics.event';
import { config as analyticsConfig } from '@lms/config/analytics.config';

import GA4React from "ga-4-react";


export class Ga4Transport implements ITransport {
	public readonly name: string;
	@observable public initialized: boolean;
	private ga4react: GA4React;
	constructor() {
		this.name = 'google.analytics4';
		this.initialized = false;

	}

	/**
	 * @function
	 * @returns {Promise<void>}
	 */
	@action public initialize = () => {
		this.ga4react = new GA4React(analyticsConfig.measurementId);
		this.ga4react.initialize().then(() => {
			this.initialized = true;
		});
	};

	/**
	 * @function
	 * @param {IAnalyticsEvent} event
	 */
	public push = (event: IAnalyticsEvent) => {
		if (!this.initialized) {
			console.warn(`Attempt to use uninitialized transport [${this.name}], event:`, event);
			return;
		}

		ReactGA.event(event.parameters as ReactGA.EventArgs);

		this.ga4react.event(event.parameters.action, event.parameters.label, event.parameters.category);
	};
	public pushPageView = (path: string) => {
		this.ga4react.pageview(path);
	};
}
