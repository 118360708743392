import * as apiClient from '@lms/services/clients/lms.api.client';
import { IEventRecord } from '@lms/typings/records/event.record';

export function getEvents(searchParams?: object): Promise<{
	total: number; results: IEventRecord[];
}> {
	return apiClient.get('/me/events', searchParams);
}

export function postEvent(model: IEventRecord): Promise<IEventRecord> {
	return apiClient.postJson('/events', model);
}

export function patchEvent(id: number, patch: Partial<IEventRecord>, queryParams?: object): Promise<IEventRecord> {
	return apiClient.patchJson(`/events/${id}`, patch, queryParams);
}

export function deleteEvent(id: number): Promise<any> {
	return apiClient.deleteResource(`/events/${id}`);
}
