/**
 * Generic analytics event class
 * @class
 */
import { IAnalyticsEvent, IAnalyticsEventParams } from '@lms/utils/analytics/events/analytics.event';

export class BaseAnalyticsEvent implements IAnalyticsEvent {
	public name: string;
	public parameters: IAnalyticsEventParams;

	constructor(name: string, parameters: IAnalyticsEventParams) {
		this.name = name;
		this.parameters = parameters;
	}
}
