import { v4 as uuid } from 'uuid';
import * as CryptoJS from 'crypto-js';
import base64url from 'base64url';

import { IAuthTokenRecord } from '@lms/typings/records/auth-token.record';

const STORAGE_PREFIX = 'auth';
const CHALLENGE_NAME = 'challenge';
const CODE_VERIFIER_NAME = 'codeVerifier';
const TOKEN_NAME = 'token';
const REDIRECT_ROUTE_NAME = 'redirectRoute';

const CODE_VERIFIER_KEY = `${STORAGE_PREFIX}.${CODE_VERIFIER_NAME}`;
const CHALLENGE_KEY = `${STORAGE_PREFIX}.${CHALLENGE_NAME}`;
const TOKEN_KEY = `${STORAGE_PREFIX}.${TOKEN_NAME}`;
const REDIRECT_ROUTE_KEY = `${STORAGE_PREFIX}.${REDIRECT_ROUTE_NAME}}`;

/**
 * Returns the challenge code verifier used for OAuth 2.0 auth
 * mechanism stored in the application's `localStorage`. If a
 * challenge code verifier is not found, then a new challenge
 * code verifier will be generated
 *
 * @function
 * @returns {void}
 */
export function getCodeVerifier(): string {
	let codeVerifier = localStorage.getItem(CODE_VERIFIER_KEY);

	if (!codeVerifier) {
		codeVerifier = uuid();
		localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);
	}

	return codeVerifier;
}

/**
 * Resets the challenge code verifier used for OAuth 2.0 auth
 * mechanism.
 *
 * @function
 * @returns {void}
 */
export function resetCodeVerifier(): void {
	localStorage.removeItem(CODE_VERIFIER_KEY);
}

/**
 * Resets the challenge code used for OAuth 2.0 auth
 * mechanism.
 *
 * @function
 * @returns {void}
 */
export function resetChallenge(): void {
	localStorage.removeItem(CHALLENGE_KEY);
}

/**
 * Returns the challenge code used for OAuth 2.0 auth mechanism
 * stored in the application's `localStorage`. If a challenge code
 * is not found, then a new challenge code will be generated
 *
 * @function
 * @returns {string}
 */
export function getChallenge(): string {
	let challenge = localStorage.getItem(CHALLENGE_KEY);

	if (!challenge) {
		const encryptedVerifier = CryptoJS.SHA256(getCodeVerifier());
		const encodedVerifier = CryptoJS.enc.Base64.stringify(encryptedVerifier);

		challenge = base64url.fromBase64(encodedVerifier);
		localStorage.setItem(CHALLENGE_KEY, challenge);
	}

	return challenge;
}

/**
 * Returns the authorization token for the LMS API from
 * `localStorage`. If the token doesn't exist, returns null
 *
 * @function
 * @returns {IAuthTokenModel | null}
 */
export function getAuthToken(): IAuthTokenRecord | null {
	const serializedToken = localStorage.getItem(TOKEN_KEY);
	return serializedToken ? JSON.parse(serializedToken) : undefined;
}

/**
 * Persists the authorization token to `localStorage`
 *
 * @function
 * @param {IAuthTokenModel} token
 */
export function persistAuthToken(token: IAuthTokenRecord): void {
	localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

/**
 * Persists the route to which the user will be redirected after
 * logging in through the `acceptAuthenticationCode` workflow
 *
 * @function
 * @param {string} route
 */
export function persistRedirectRoute(route: string): void {
	localStorage.setItem(REDIRECT_ROUTE_KEY, JSON.stringify(route));
}

/**
 * Returns the redirect route to which the user must be redirected
 * after completing the `acceptAuthenticationCode` workflow
 *
 * @function
 * @returns {string}
 */
export function getRedirectRoute(): string {
	return JSON.parse(localStorage.getItem(REDIRECT_ROUTE_KEY));
}
