export interface ILabelModel {
	id: number;
	name: string;
	color: string;
	userId?: number;
	createdAt?: string;
	updatedAt?: string;
}

export class LabelModel implements ILabelModel {
	public id: number;
	public name: string;
	public color: string;

	constructor(label: ILabelModel) {
		this.id = label.id;
		this.name = label.name;
		this.color = label.color;
	}
}
