import * as _ from 'lodash';
import * as React from 'react';
import * as Moment from 'moment';
import * as styles from './styles.scss';
import { observer } from 'mobx-react';
import { getViewReadyTimezoneLabel } from '@lms/features/application.metadata/timezones/utils/timezones.utils';
import { EventModel } from '@lms/models/event.model';
import { Button, IButtonSkin } from '@lms/views/elements/button';
import { navigate } from '@lms/redux/actions/navigation.actions';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

interface IProps {
	onClick: (item: EventModel) => void;
	items: EventModel[];
	onClose?: () => void;
}

export const EventItems: React.FC<IProps> = observer((props: IProps) => {
	const selectors = styles as any;

	const items = _.map(props.items, (item) => {
		const icon = item.isAppointment
			? `fa-calendar${item.isConfirmed ? '-check' : ''}`
			: `fa-tasks`;

		return (
			<div
				onClick={() => props.onClick(item)}
				className="tray-item"
				data-color={item.isAppointment ? (item.isConfirmed ? 'green' : 'orange') : ''}
				key={item.id}
			>
				<div className="tray-item-icon">
					<i className={`fal ${icon}`}></i>
				</div>
				<div className="tray-item-body">
					<div className="tray-item-text">
						{item.typeText} <strong>{item.subject}</strong> {item.isAppointment ? 'with' : 'on'} <strong>{item.lead.businessTitle}</strong>
					</div>
					<div className="tray-item-meta">
						<span>{Moment(item.date).fromNow()}</span>
						<span>{item.formattedDate} ({getViewReadyTimezoneLabel(item.timezone)})</span>
					</div>
				</div>
			</div>
		);
	});

	return (
		<>
			<div className={`${selectors.markAllContainer} dropdown-header`}>
				<i
					className="fal fa-long-arrow-left mobile-close"
					onClick={props.onClose}
				></i>
				<span>Upcoming Events</span>
				<Button
					skin={IButtonSkin.TEXT}
					size="sm"
					onClick={() => {
						getStoreProxy().dispatch(navigate(`/account/calendar`));
					}}
				>
					Open Calendar
				</Button>
			</div>
			{items}
		</>
	);
});
