import * as _ from 'lodash';
import * as inProgressReasonEnum from '@harbortouch/lms-enums/lib/enums/lead.in-progress-reason.enum';
import { IViewReadyEntity } from '@lms/utils/selectors/metadata.selectors';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

export enum ILastActionEnum {
	NONE = (inProgressReasonEnum as any).NONE,
	LEFT_MESSAGE_FOR_MERCHANT = (inProgressReasonEnum as any).LEFT_MESSAGE_FOR_MERCHANT,
	SETUP_APPOINTMENT = (inProgressReasonEnum as any).SETUP_APPOINTMENT,
	AWAITING_APPOINTMENT_CONFIRMATION = (inProgressReasonEnum as any).AWAITING_APPOINTMENT_CONFIRMATION,
	AWAITING_FEEDBACK_BEFORE_QUOTE = (inProgressReasonEnum as any).AWAITING_FEEDBACK_BEFORE_QUOTE,
	AWAITING_APPOINTMENT_RESCHEDULE = (inProgressReasonEnum as any).AWAITING_APPOINTMENT_RESCHEDULE,
	ANOTHER_APPOINTMENT_NEEDED = (inProgressReasonEnum as any).ANOTHER_APPOINTMENT_NEEDED
}

/**
 * Cache for lead status view projections
 *
 * @type {IViewReadyEntity[]}
 * @private
 */
let _viewReadyLeadLastActions: IViewReadyEntity[] | null = null;

/**
 * TODO refactor when redux is removed
 *
 * @function
 * @returns {IViewReadyEntity[]}
 */
export function getViewReadyLeadLastActions(): IViewReadyEntity[] {
	const lastActions = _.values(inProgressReasonEnum);

	if (!_viewReadyLeadLastActions) {
		_viewReadyLeadLastActions = lastActions.map((name: string) => {
			return {
				value: name,
				label: getDictionaryValue(`labels.leads.lastAction.${name}`),
				attribute: 'inProgressReason'
			};
		});
	}

	return _viewReadyLeadLastActions;
}
