import * as _ from 'lodash';
import { forcefullyExtendObservable } from '@lms/utils/model.utils';
import IEventStatus from '@lms/enums/event.status.enum';
import IEventType from '@lms/enums/event.type.enum';
import { TIME_FORMAT } from '@lms/features/lead.bookkeeping/constants/time.format.options';
import * as Moment from 'moment';
import { runInAction } from 'mobx';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { IEventRecord } from '@lms/typings/records/event.record';
import { getStoreProxy } from '@lms/redux/redux.store.proxy';

export class EventModel {
	public id: number;
	public time: string;
	public type: IEventType;
	public status: IEventStatus;
	public subject: string;
	public comment?: string;
	public date: Date;
	public timezone: string;
	public lead?: ILeadRecord;

	constructor(json: IEventRecord) {
		forcefullyExtendObservable(this, json);
		this.init();
	}

	public init() {
		this.date = new Date(this.date);
		this.time = this.getTime();
	}

	public get isAppointment() {
		return this.type === IEventType.APPOINTMENT;
	}

	public get isConfirmed() {
		return this.status === IEventStatus.CONFIRMED;
	}

	public get isComplete() {
		return this.status === IEventStatus.COMPLETED;
	}

	public get isCancelled() {
		return this.status === IEventStatus.CANCELLED;
	}

	public get isDone() {
		return this.isCancelled || this.isComplete;
	}

	public get isOverdue() {
		return this.date < new Date() && !this.isDone;
	}

	public get typeText() {
		return (this.isAppointment ? 'Appointment' : 'Reminder') as string;
	}

	public get formattedDate() {
		return this.date.toLocaleDateString('en-US', {
			...TIME_FORMAT,
			timeZone: this.timezone
		});
	}

	public get localizedDate() {
		return new Date(this.formattedDate);
	}

	public get toUserDate() {
		const userTimezone = getStoreProxy().state.user.metadata.timezone;
		return Moment(this.date).tz(userTimezone).toDate();
	}

	public get statusColor() {
		if (this.isAppointment) {
			if (this.isConfirmed || this.isComplete) {
				return 'green';
			}

			return this.isCancelled ? 'red' : 'orange';
		}

		return this.isComplete ? 'green' : 'blue';
	}

	public getTime() {
		return Moment(this.localizedDate).format('HH:mm');
	}

	public update(record: object) {
		runInAction(() => {
			_.assign(this, record);
			this.init();
		});
	}

	public get availableStatuses() {
		if (this.type === IEventType.REMINDER) {
			return [IEventStatus.SCHEDULED, IEventStatus.COMPLETED];
		}

		switch (this.status) {
			case IEventStatus.SCHEDULED:
				return [IEventStatus.SCHEDULED, IEventStatus.CONFIRMED, IEventStatus.CANCELLED];
			case IEventStatus.CONFIRMED:
				return [IEventStatus.CONFIRMED, IEventStatus.COMPLETED, IEventStatus.CANCELLED];
			default:
				return [this.status];
		}
	};
}
