import * as _ from 'lodash';

/**
 * Returns true if the passed string is empty
 *
 * @function
 * @param {string} value
 * @returns {boolean}
 */
export function isBlankString(value: string) {
	return !value || value.toString().trim().length === 0;
}

export function makeEllipsis(value: string, index: number) {
	if (value.length > index) {
		const slice = value.slice(0, index);

		return `${slice.trim()}...`;
	}

	return value;
}

export const slugify = (value: string) => {
	return _.kebabCase(value);
};
