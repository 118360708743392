import { Event, eventBus, subscribe } from 'mobx-event-bus2';

import {
	IDuplicateFoundPayload
} from '@lms/features/lead.bookkeeping/lead.info/interfaces/duplicate.found.event';
import { ILeadMatchStatus } from '@lms/enums/lead.match-status.enum';
import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';
import { getDictionaryValue } from '@lms/utils/selectors/dictionary.selectors';

import systemNotificationStore from '@lms/features/system.notifications/store';

export interface IValidationSuccessPayload {
	attrName: string;
}

export interface IValidationFailurePayload {
	attrName: string;
	validationError: string;
}

export class LeadInfoNotificationService {
	constructor() {
		eventBus.register(this);

		this.handleValidationFailure = this.handleValidationFailure.bind(this);
		this.handleValidationSuccess = this.handleValidationSuccess.bind(this);
	}

	/**
	 * @function
	 */
	public clearPassiveNotifications = () => {
		systemNotificationStore.removeNotificationsWithCorrelation(ILeadMatchStatus.FULL_MATCH as any);
		systemNotificationStore.removeNotificationsWithCorrelation(ILeadMatchStatus.PARTIAL_MATCH as any);
	};

	/**
	 * @function
	 *
	 * @private
	 * @param {Event<IValidationFailurePayload>} event
	 * @memberof LeadInfoNotificationService
	 */
	@subscribe('leadInfo.attrValidationFailure')
	private handleValidationFailure(event: Event<IValidationFailurePayload>) {
		const { attrName, validationError } = event.payload;
		const message = getDictionaryValue(validationError);

		systemNotificationStore.pushNotification(attrName, message, ISystemNotificationSkin.NEGATIVE);
	}

	/**
	 * @function
	 *
	 * @private
	 * @param {Event<IValidationSuccessPayload>} event
	 * @memberof LeadInfoNotificationService
	 */
	@subscribe('leadInfo.attrValidationSuccess')
	private handleValidationSuccess(event: Event<IValidationSuccessPayload>) {
		const { attrName } = event.payload;
		systemNotificationStore.removeNotificationsWithCorrelation(attrName);
	}

	/**
	 * @function
	 *
	 * @private
	 * @param {Event<IDuplicateFoundPayload>} event
	 * @memberof LeadInfoNotificationService
	 */
	@subscribe('leadInfo.duplicateFound')
	private handleDuplicateFound(event: Event<IDuplicateFoundPayload>) {
		const { matchType, overriden } = event.payload;

		switch (matchType) {
			case ILeadMatchStatus.FULL_MATCH: {
				let message = getDictionaryValue('errors.leadInfo.hardDuplicateOverriden');
				if (!overriden) {
					message = getDictionaryValue('errors.leadInfo.hardDuplicateNotice');
				}

				systemNotificationStore.pushNotification(matchType as any, message, ISystemNotificationSkin.NEGATIVE);
				break;
			}

			case ILeadMatchStatus.PARTIAL_MATCH: {
				const message = getDictionaryValue('errors.leadInfo.partialDuplicateNotice');
				systemNotificationStore.pushNotification(matchType as any, message, ISystemNotificationSkin.WARNING);
				break;
			}

			default: break;
		}
	}
}
