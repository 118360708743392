import { ILabelModel } from '@lms/models/label.model';
import * as apiClient from '@lms/services/clients/lms.api.client';

export function getLabels(): Promise<ILabelModel[]> {
	return apiClient.get('/labels');
}

export function postLabel(label: ILabelModel): Promise<ILabelModel> {
	return apiClient.postJson('/labels', label);
}

export function patchLabel(labelId: number, patch: Partial<ILabelModel>, queryParams?: object): Promise<ILabelModel> {
	return apiClient.patchJson(`/labels/${labelId}`, patch, queryParams);
}

export function deleteLabel(labelId: number): Promise<any> {
	return apiClient.deleteResource(`/labels/${labelId}`);
}
