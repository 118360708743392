import { subscribe, eventBus, Event } from 'mobx-event-bus2';

import { IAnalyticsProvider } from '@lms/utils/analytics/analytics.provider';
import { ILeadRecord } from '@lms/typings/records/lead.record';
import { LeadCreatedEvent } from '@lms/utils/analytics/events/leads/lead.created.event';

/* TODO: think about this */
export class LeadsTracker {
	private analytics: IAnalyticsProvider;

	constructor(analytics: IAnalyticsProvider) {
		eventBus.register(this);
		this.analytics = analytics;
	}

	@subscribe('leadEntry.newLead')
	private handleNewLead(event: Event<{ lead: ILeadRecord }>) {
		const { lead } = event.payload;
		this.analytics.pushEvent(new LeadCreatedEvent(lead));
	}
}
