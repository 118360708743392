import { ISystemNotificationSkin } from '@lms/utils/state/system.notification.state';

export interface ISystemNotificationModel {
	readonly correlationId: string;

	readonly message: string;
	readonly skin: string;
}

export class SystemNotificationModel implements ISystemNotificationModel {
	public readonly correlationId: string;

	public readonly message: string;
	public readonly skin: string;

	constructor(correlationId: string, message: string, skin: ISystemNotificationSkin) {
		this.correlationId = correlationId;

		this.message = message;
		this.skin = skin;
	}
}
